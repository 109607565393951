import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBook,
  faPlus,
  faEdit,
  faTrash,
  faFilter,
  faSearch,
  faVideo,
  faFile,
  faDownload,
  faUpload,
} from '@fortawesome/free-solid-svg-icons'

const ResourceManagement = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const [filterType, setFilterType] = useState('all')

  // Sample resources data - in a real app, this would come from your backend
  const resources = [
    {
      id: 1,
      title: 'Mathematics Fundamentals',
      type: 'document',
      subject: 'Mathematics',
      downloads: 0,
      status: 'published',
      size: '2.5 MB',
      lastUpdated: '2024-03-15',
    },
    {
      id: 2,
      title: 'Physics Video Lecture',
      type: 'video',
      subject: 'Physics',
      downloads: 0,
      status: 'draft',
      size: '150 MB',
      lastUpdated: '2024-03-14',
    },
  ]

  const getResourceIcon = (type) => {
    switch (type) {
      case 'video':
        return faVideo
      case 'document':
        return faFile
      default:
        return faBook
    }
  }

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900 mb-2">
          Resource Management
        </h1>
        <p className="text-gray-600">
          Manage educational resources and materials.
        </p>
      </div>

      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <div className="bg-blue-50 p-6 rounded-lg">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-blue-600 text-sm">Total Resources</p>
              <h3 className="text-2xl font-bold text-blue-700">
                {resources.length}
              </h3>
            </div>
            <div className="rounded-full p-3 bg-blue-100">
              <FontAwesomeIcon icon={faBook} className="text-blue-600" />
            </div>
          </div>
        </div>

        <div className="bg-green-50 p-6 rounded-lg">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-green-600 text-sm">Published</p>
              <h3 className="text-2xl font-bold text-green-700">
                {resources.filter((r) => r.status === 'published').length}
              </h3>
            </div>
            <div className="rounded-full p-3 bg-green-100">
              <FontAwesomeIcon icon={faUpload} className="text-green-600" />
            </div>
          </div>
        </div>

        <div className="bg-purple-50 p-6 rounded-lg">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-purple-600 text-sm">Total Downloads</p>
              <h3 className="text-2xl font-bold text-purple-700">
                {resources.reduce((acc, curr) => acc + curr.downloads, 0)}
              </h3>
            </div>
            <div className="rounded-full p-3 bg-purple-100">
              <FontAwesomeIcon icon={faDownload} className="text-purple-600" />
            </div>
          </div>
        </div>
      </div>

      {/* Actions Bar */}
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100 mb-6">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          <div className="flex-1 flex gap-4">
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Search resources..."
                className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <FontAwesomeIcon
                icon={faSearch}
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              />
            </div>
            <div className="relative">
              <select
                className="pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 appearance-none bg-white"
                value={filterType}
                onChange={(e) => setFilterType(e.target.value)}
              >
                <option value="all">All Types</option>
                <option value="document">Documents</option>
                <option value="video">Videos</option>
                <option value="other">Other</option>
              </select>
              <FontAwesomeIcon
                icon={faFilter}
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              />
            </div>
          </div>
          <button className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary-dark flex items-center justify-center">
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Upload Resource
          </button>
        </div>
      </div>

      {/* Resources List */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-100">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Resource
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Type
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Subject
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Size
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {resources.map((resource) => (
                <tr key={resource.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10 bg-primary-100 rounded-lg flex items-center justify-center">
                        <FontAwesomeIcon
                          icon={getResourceIcon(resource.type)}
                          className="text-primary"
                        />
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {resource.title}
                        </div>
                        <div className="text-sm text-gray-500">
                          Last updated: {resource.lastUpdated}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">
                    {resource.type}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {resource.subject}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {resource.size}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        resource.status === 'published'
                          ? 'bg-green-100 text-green-800'
                          : 'bg-yellow-100 text-yellow-800'
                      }`}
                    >
                      {resource.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <button className="text-blue-600 hover:text-blue-900 mr-3">
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button className="text-red-600 hover:text-red-900">
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ResourceManagement
