import agentPrompts from './agentPrompts' // Adjust the path as necessary

export const AGENT_TYPES = {
  STUDY_PARTNER: {
    slug: 'study-partner',
    title: 'Study Partner',
    icon: 'faGraduationCap',
  },
  VIRTUAL_AMBASSADOR: {
    slug: 'virtual-ambassador',
    title: 'Virtual Ambassador',
    icon: 'faChalkboardTeacher',
  },
  ACADEMIC_COUNSELOR: {
    slug: 'academic-counselor',
    title: 'Academic Counselor',
    icon: 'faCompass',
  },
  SCHOLARSHIP_ASSISTANT: {
    slug: 'scholarship-college-assistant',
    title: 'Scholarship & College Assistant',
    icon: 'faUniversity',
  },
}

export const getAgentBySlug = (slug) => {
  return Object.values(AGENT_TYPES).find((agent) => agent.slug === slug)
}

export const getAgentPrompt = (slug) => {
  const prompt = agentPrompts[slug]
  if (!prompt) {
    throw new Error(`No prompt configuration found for agent: ${slug}`)
  }
  return prompt
}
