import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faUnlock,
  faStar,
  faCrown,
  faFileAlt,
  faLightbulb,
} from '@fortawesome/free-solid-svg-icons'
import MobileMoneyPayment from './MobileMoneyPayment'
import { useAuth } from '../../utils/firebase/auth'
import { useRole } from '../../utils/permissions/RoleContext'
import { PERMISSIONS } from '../../utils/permissions/roles'

/**
 * SubscriptionManager Component
 * Manages subscription plans and payments for premium content access
 */
const SubscriptionManager = () => {
  const { authUser } = useAuth()
  const { hasPermission } = useRole()
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [showPayment, setShowPayment] = useState(false)
  const [, setIsProcessing] = useState(false)
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [currentSubscription, setCurrentSubscription] = useState(null)

  // Check if user has premium content access
  const hasPremiumAccess = hasPermission(PERMISSIONS.VIEW_PREMIUM_RESOURCES)

  // Simulated subscription data - in production, this would come from the backend
  useEffect(() => {
    // Mock API call to get subscription status
    if (authUser) {
      // TODO: Replace with actual API call to fetch subscription data
      const fetchSubscriptionTimeout = setTimeout(() => {
        if (hasPremiumAccess) {
          setCurrentSubscription({
            plan: 'advanced', // basic, standard, advanced
            startDate: '2023-12-01',
            endDate: '2024-12-01',
            features: [
              'Premium GCE O/A Level Solutions',
              'AI Study Planner',
              'Full Textbook Access',
              'Personalized Tutoring',
            ],
            autoRenew: true,
          })
        } else {
          setCurrentSubscription(null)
        }
      }, 1000)

      // Cleanup timeout on component unmount
      return () => clearTimeout(fetchSubscriptionTimeout)
    }
  }, [authUser, hasPremiumAccess])

  // Subscription plans formatted for Cameroon market
  const subscriptionPlans = [
    {
      id: 'basic',
      name: 'Basic Plan',
      price: 2500, // XAF
      duration: 'month',
      features: [
        'GCE Past Questions Access',
        'Basic Study Materials',
        'Limited AI Study Planner',
        'Community Forum Access',
      ],
      icon: faUnlock,
      color: 'bg-blue-500',
      popular: false,
    },
    {
      id: 'standard',
      name: 'Standard Plan',
      price: 5000, // XAF
      duration: 'month',
      features: [
        'All Basic Plan Features',
        'Complete GCE Solutions',
        'Full AI Study Planner',
        'Practice Exams with Feedback',
        'Download Resources Offline',
      ],
      icon: faStar,
      color: 'bg-green-500',
      popular: true,
    },
    {
      id: 'advanced',
      name: 'Premium Scholar',
      price: 10000, // XAF
      duration: 'month',
      features: [
        'All Standard Plan Features',
        'Full Digital Textbook Library',
        'AI Tutoring Sessions',
        'Personalized Learning Paths',
        'Mock Exam Grading with Detailed Feedback',
        'Priority Support',
      ],
      icon: faCrown,
      color: 'bg-purple-600',
      popular: false,
    },
    {
      id: 'academic-year',
      name: 'Academic Year',
      price: 45000, // XAF
      duration: 'year',
      features: [
        'All Premium Scholar Features',
        'Full Year Access (25% savings)',
        'Exam Preparation Guides',
        'Career Guidance Resources',
        'University Application Support',
      ],
      icon: faFileAlt,
      color: 'bg-red-600',
      popular: false,
    },
  ]

  // Format currency with thousand separators
  const formatCurrency = (value) => {
    return parseFloat(value).toLocaleString('fr-CM', {
      style: 'currency',
      currency: 'XAF',
      minimumFractionDigits: 0,
    })
  }

  // Handle subscription purchase
  const handleSubscriptionPurchase = (plan) => {
    setSelectedPlan(plan)
    setShowPayment(true)
  }

  // Handle successful payment
  const handlePaymentSuccess = (paymentDetails) => {
    setIsProcessing(true)

    // In a real implementation, this would call an API to activate the subscription
    // TODO: Replace with actual API call to activate subscription
    const activationTimeout = setTimeout(() => {
      setPaymentSuccess(true)
      setIsProcessing(false)
      setShowPayment(false)

      // Mock update of subscription status
      setCurrentSubscription({
        plan: selectedPlan.id,
        startDate: new Date().toISOString().split('T')[0],
        endDate: new Date(
          new Date().setMonth(
            new Date().getMonth() + (selectedPlan.duration === 'year' ? 12 : 1)
          )
        )
          .toISOString()
          .split('T')[0],
        features: selectedPlan.features,
        autoRenew: true,
      })

      // Reset after showing success message
      const successTimeout = setTimeout(() => {
        setPaymentSuccess(false)
      }, 5000)

      return () => clearTimeout(successTimeout)
    }, 2000)

    return () => clearTimeout(activationTimeout)
  }

  // Handle payment error
  const handlePaymentError = (error) => {
    console.error('Payment error:', error)
    setIsProcessing(false)
  }

  // Render current subscription
  const renderCurrentSubscription = () => {
    if (!currentSubscription) return null

    return (
      <div className="bg-gray-50 border border-gray-200 rounded-lg p-5 mb-8">
        <h3 className="font-semibold text-lg mb-3">
          Your Current Subscription
        </h3>

        <div className="flex items-start">
          <div
            className={`w-12 h-12 flex items-center justify-center text-white rounded-full ${
              currentSubscription.plan === 'basic'
                ? 'bg-blue-500'
                : currentSubscription.plan === 'standard'
                ? 'bg-green-500'
                : currentSubscription.plan === 'advanced'
                ? 'bg-purple-600'
                : 'bg-red-600'
            }`}
          >
            <FontAwesomeIcon
              icon={
                currentSubscription.plan === 'basic'
                  ? faUnlock
                  : currentSubscription.plan === 'standard'
                  ? faStar
                  : currentSubscription.plan === 'advanced'
                  ? faCrown
                  : faFileAlt
              }
            />
          </div>

          <div className="ml-4">
            <h4 className="font-semibold text-lg">
              {currentSubscription.plan === 'basic'
                ? 'Basic Plan'
                : currentSubscription.plan === 'standard'
                ? 'Standard Plan'
                : currentSubscription.plan === 'advanced'
                ? 'Premium Scholar'
                : 'Academic Year'}
            </h4>

            <p className="text-sm text-gray-600 mb-3">
              Valid from {currentSubscription.startDate} to{' '}
              {currentSubscription.endDate}
            </p>

            <div className="mb-3">
              <h5 className="font-medium mb-1">Your Benefits:</h5>
              <ul className="text-sm">
                {currentSubscription.features.map((feature, index) => (
                  <li
                    key={`feature-${index}`}
                    className="flex items-center mb-1"
                  >
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-green-500 mr-2"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>

            <div className="border-t pt-3 flex justify-between items-center">
              <div>
                <span className="text-sm mr-2">Auto-renew:</span>
                {currentSubscription.autoRenew ? (
                  <span className="text-sm text-green-600 font-medium">
                    Enabled
                  </span>
                ) : (
                  <span className="text-sm text-red-600 font-medium">
                    Disabled
                  </span>
                )}
              </div>

              <button
                className="text-sm text-blue-600 hover:text-blue-800"
                onClick={() => {
                  // TODO: Implement subscription management functionality
                  console.log('Manage subscription clicked')
                }}
              >
                Manage Subscription
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="max-w-4xl mx-auto">
      {paymentSuccess && (
        <div className="mb-6 bg-green-50 border border-green-200 rounded-lg p-4 flex items-start">
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="text-green-500 mt-0.5 mr-3"
          />
          <div>
            <h3 className="font-semibold">Subscription Activated!</h3>
            <p className="text-sm text-gray-600">
              Your subscription has been successfully activated. Enjoy your
              premium access.
            </p>
          </div>
        </div>
      )}

      {renderCurrentSubscription()}

      {!showPayment ? (
        <>
          <h2 className="text-2xl font-bold mb-6">
            KET Academy Subscription Plans
          </h2>

          <p className="mb-6 text-gray-700">
            Unlock premium educational content and AI-powered learning tools
            tailored to the Cameroonian curriculum.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
            {subscriptionPlans.map((plan) => (
              <div
                key={plan.id}
                className={`border rounded-lg overflow-hidden ${
                  plan.popular
                    ? 'border-green-500 shadow-md'
                    : 'border-gray-200'
                }`}
              >
                {plan.popular && (
                  <div className="bg-green-500 text-white text-center py-1 text-sm font-medium">
                    MOST POPULAR
                  </div>
                )}

                <div className="p-5">
                  <div
                    className={`w-12 h-12 ${plan.color} rounded-full text-white flex items-center justify-center mb-4`}
                  >
                    <FontAwesomeIcon icon={plan.icon} />
                  </div>

                  <h3 className="font-bold text-lg mb-2">{plan.name}</h3>

                  <div className="mb-4">
                    <span className="text-2xl font-bold">
                      {formatCurrency(plan.price)}
                    </span>
                    <span className="text-gray-600">/{plan.duration}</span>
                  </div>

                  <ul className="mb-5">
                    {plan.features.map((feature, index) => (
                      <li
                        key={`${plan.id}-feature-${index}`}
                        className="flex items-start mb-2"
                      >
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-green-500 mt-1 mr-2"
                        />
                        <span className="text-sm">{feature}</span>
                      </li>
                    ))}
                  </ul>

                  <button
                    onClick={() => handleSubscriptionPurchase(plan)}
                    className="w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-md transition-colors"
                  >
                    {currentSubscription ? 'Upgrade Plan' : 'Subscribe Now'}
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="bg-blue-50 border border-blue-200 rounded-lg p-5">
            <div className="flex items-start">
              <div className="text-blue-500 mr-4">
                <FontAwesomeIcon icon={faLightbulb} className="text-2xl" />
              </div>

              <div>
                <h3 className="font-semibold text-lg mb-2">
                  Why Subscribe to KET Academy?
                </h3>

                <ul className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-2">
                  <li className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-green-500 mr-2"
                    />
                    <span className="text-sm">
                      Aligned with Cameroonian GCE and Baccalaureate
                    </span>
                  </li>
                  <li className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-green-500 mr-2"
                    />
                    <span className="text-sm">
                      AI-powered learning assistance
                    </span>
                  </li>
                  <li className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-green-500 mr-2"
                    />
                    <span className="text-sm">
                      Study effectively with personalized plans
                    </span>
                  </li>
                  <li className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-green-500 mr-2"
                    />
                    <span className="text-sm">
                      Access on mobile and offline
                    </span>
                  </li>
                  <li className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-green-500 mr-2"
                    />
                    <span className="text-sm">
                      Detailed solutions to past exam questions
                    </span>
                  </li>
                  <li className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-green-500 mr-2"
                    />
                    <span className="text-sm">
                      Optimized for Cameroon's internet infrastructure
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="mb-8">
          <button
            onClick={() => setShowPayment(false)}
            className="text-blue-600 mb-4 flex items-center"
          >
            &larr; Back to Plans
          </button>

          {selectedPlan && (
            <MobileMoneyPayment
              amount={selectedPlan.price}
              itemDescription={`KET Academy ${selectedPlan.name} (${selectedPlan.duration}ly)`}
              onSuccess={handlePaymentSuccess}
              onError={handlePaymentError}
              paymentType="subscription"
            />
          )}
        </div>
      )}
    </div>
  )
}

export default SubscriptionManager
