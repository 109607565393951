import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faTimes,
  faPlus,
  faEdit,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from 'firebase/firestore'
import { firestore } from '../../../utils/firebase'
import { toast } from 'react-toastify'
import { CAMEROON_REGIONS } from '../../../utils/constants'

/**
 * School Management Component for Admin Dashboard
 */
const SchoolsManagement = () => {
  const [schools, setSchools] = useState([])
  const [isAddingSchool, setIsAddingSchool] = useState(false)
  const [editingSchool, setEditingSchool] = useState(null)
  const [newSchool, setNewSchool] = useState({
    name: '',
    region: '',
    type: '',
    status: 'pending',
  })

  // Fetch all schools
  const fetchSchools = async () => {
    try {
      const schoolsRef = collection(firestore, 'schools')
      const schoolSnapshot = await getDocs(schoolsRef)
      const schoolsList = schoolSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        type: doc.data().type || '',
        status: doc.data().status || 'pending',
      }))
      setSchools(schoolsList)
    } catch (error) {
      console.error('Error fetching schools:', error)
      toast.error('Failed to fetch schools')
      setSchools([])
    }
  }

  useEffect(() => {
    fetchSchools()
  }, [])

  // Handle school submission
  const handleSubmitSchool = async (e) => {
    e.preventDefault()
    try {
      // Validate required fields
      if (!newSchool.name?.trim() || !newSchool.region || !newSchool.type) {
        toast.error('Please fill in all required fields')
        return
      }

      const schoolData = {
        ...newSchool,
        name: newSchool.name.trim(),
        region: newSchool.region,
        type: newSchool.type,
        addedOn: new Date(),
        status: editingSchool ? newSchool.status : 'pending',
        lastUpdated: new Date(),
      }

      if (editingSchool) {
        // Update existing school
        await updateDoc(doc(firestore, 'schools', editingSchool.id), schoolData)
        toast.success('School updated successfully')
      } else {
        // Add new school
        await addDoc(collection(firestore, 'schools'), schoolData)
        toast.success('School added successfully')
      }

      // Reset form
      setNewSchool({ name: '', region: '', type: '', status: 'pending' })
      setIsAddingSchool(false)
      setEditingSchool(null)
      fetchSchools()
    } catch (error) {
      console.error('Error saving school:', error)
      toast.error('Failed to save school')
    }
  }

  // Handle school deletion
  const handleDeleteSchool = async (schoolId) => {
    if (!window.confirm('Are you sure you want to delete this school?')) {
      return
    }

    try {
      await deleteDoc(doc(firestore, 'schools', schoolId))
      toast.success('School deleted successfully')
      fetchSchools()
    } catch (error) {
      console.error('Error deleting school:', error)
      toast.error('Failed to delete school')
    }
  }

  // Handle school status update
  const handleUpdateStatus = async (schoolId, newStatus) => {
    try {
      await updateDoc(doc(firestore, 'schools', schoolId), {
        status: newStatus,
        statusUpdateDate: new Date(),
        lastUpdated: new Date(),
      })
      toast.success(`School ${newStatus} successfully`)
      fetchSchools()
    } catch (error) {
      console.error('Error updating school status:', error)
      toast.error('Failed to update school status')
    }
  }

  // Handle edit school
  const handleEditSchool = (school) => {
    setEditingSchool(school)
    setNewSchool({
      name: school.name || '',
      region: school.region || '',
      type: school.type || '',
      status: school.status || 'pending',
    })
    setIsAddingSchool(true)
  }

  return (
    <div className="schools-management">
      <h1>School Management</h1>
      <p>This is where you can manage schools registered with KET Academy.</p>

      {/* Add/Edit School Form */}
      {isAddingSchool ? (
        <div className="school-form-container">
          <h2>{editingSchool ? 'Edit School' : 'Add New School'}</h2>
          <form onSubmit={handleSubmitSchool} className="school-form">
            <div className="form-group">
              <label htmlFor="schoolName">School Name*</label>
              <input
                id="schoolName"
                type="text"
                value={newSchool.name}
                onChange={(e) =>
                  setNewSchool({ ...newSchool, name: e.target.value })
                }
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="schoolRegion">Region*</label>
              <select
                id="schoolRegion"
                value={newSchool.region}
                onChange={(e) =>
                  setNewSchool({ ...newSchool, region: e.target.value })
                }
                required
              >
                <option value="">Select Region</option>
                {CAMEROON_REGIONS.map((region) => (
                  <option key={region} value={region}>
                    {region}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="schoolType">School Type*</label>
              <select
                id="schoolType"
                value={newSchool.type}
                onChange={(e) =>
                  setNewSchool({ ...newSchool, type: e.target.value })
                }
                required
              >
                <option value="">Select Type</option>
                <option value="primary">Primary</option>
                <option value="secondary">Secondary</option>
                <option value="university">University</option>
                <option value="other">Other</option>
              </select>
            </div>

            {editingSchool && (
              <div className="form-group">
                <label htmlFor="schoolStatus">Status</label>
                <select
                  id="schoolStatus"
                  value={newSchool.status}
                  onChange={(e) =>
                    setNewSchool({ ...newSchool, status: e.target.value })
                  }
                >
                  <option value="pending">Pending</option>
                  <option value="approved">Approved</option>
                  <option value="rejected">Rejected</option>
                </select>
              </div>
            )}

            <div className="form-actions">
              <button type="submit" className="btn btn-primary">
                {editingSchool ? 'Update School' : 'Add School'}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setIsAddingSchool(false)
                  setEditingSchool(null)
                  setNewSchool({
                    name: '',
                    region: '',
                    type: '',
                    status: 'pending',
                  })
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      ) : (
        <button
          className="btn btn-primary add-school-btn"
          onClick={() => setIsAddingSchool(true)}
        >
          <FontAwesomeIcon icon={faPlus} /> Add New School
        </button>
      )}

      {/* Schools List */}
      <div className="schools-list">
        <h2>Registered Schools</h2>
        {schools.length === 0 ? (
          <p>No schools found. Schools will appear here once registered.</p>
        ) : (
          <table className="schools-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Region</th>
                <th>Type</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {schools.map((school) => (
                <tr key={school.id}>
                  <td>{school.name}</td>
                  <td>{school.region}</td>
                  <td>{school.type}</td>
                  <td>
                    <span className={`status-badge status-${school.status}`}>
                      {school.status}
                    </span>
                  </td>
                  <td className="actions">
                    <button
                      className="btn btn-sm btn-secondary"
                      onClick={() => handleEditSchool(school)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => handleDeleteSchool(school.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                    {school.status === 'pending' && (
                      <>
                        <button
                          className="btn btn-sm btn-success"
                          onClick={() =>
                            handleUpdateStatus(school.id, 'approved')
                          }
                        >
                          <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() =>
                            handleUpdateStatus(school.id, 'rejected')
                          }
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* TODO: Add pagination for schools list when the number of schools grows */}
      {/* TODO: Add filtering options by region, type, and status */}
      {/* TODO: Add search functionality for schools */}
    </div>
  )
}

export default SchoolsManagement
