import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUserGraduate,
  faBriefcase,
  faGraduationCap,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
// import { useRole } from '../../../utils/permissions/RoleContext' // Uncomment if CORE_ROLES is needed
import { canTransitionToStatus } from '../../../utils/permissions/roles'

/**
 * Component for requesting role transitions in the user profile
 */
const RoleRequestSection = ({
  currentStatus,
  pendingApproval,
  pendingRoleId,
  onRequestRole,
  onCancelRequest,
}) => {
  const [selectedRole, setSelectedRole] = useState(null)
  const [showConfirmation, setShowConfirmation] = useState(false)
  // Remove unused CORE_ROLES variable
  // If needed later, uncomment: const { CORE_ROLES } = useRole()

  // Define available role options based on current status
  const getRoleOptions = () => {
    // Map status to available target roles
    const roleOptions = {
      STUDENT: [
        {
          id: 'STUDENT_AMBASSADOR',
          label: 'Student Ambassador',
          description:
            'Represent KET Academy in your school and help other students',
          icon: faUserGraduate,
          requirements:
            'Requires good academic standing and leadership qualities',
        },
        {
          id: 'AMBASSADOR',
          label: 'Ambassador',
          description: 'Create educational content and mentor students',
          icon: faBriefcase,
          requirements: 'Requires teaching experience and subject expertise',
        },
      ],
      STUDENT_AMBASSADOR: [
        {
          id: 'AMBASSADOR',
          label: 'Ambassador',
          description: 'Create educational content and mentor students',
          icon: faBriefcase,
          requirements: 'Requires teaching experience and subject expertise',
        },
      ],
      AMBASSADOR: [
        {
          id: 'CORE',
          label: 'Core Member',
          description: 'Manage platform content and user roles',
          icon: faUserShield,
          requirements:
            'By invitation only - requires direct approval from existing Core Members',
        },
      ],
      TEACHER: [
        {
          id: 'AMBASSADOR',
          label: 'Ambassador',
          description: 'Create educational content and mentor students',
          icon: faBriefcase,
          requirements: 'Requires teaching experience and subject expertise',
        },
      ],
      COUNSELOR: [
        {
          id: 'AMBASSADOR',
          label: 'Ambassador',
          description: 'Create educational content and mentor students',
          icon: faBriefcase,
          requirements: 'Requires teaching experience and subject expertise',
        },
      ],
    }

    // Return available roles or empty array if none defined
    return roleOptions[currentStatus] || []
  }

  // Handle role selection
  const handleRoleSelect = (roleId) => {
    setSelectedRole(roleId)
    setShowConfirmation(true)
  }

  // Handle role request confirmation
  const handleConfirmRequest = () => {
    if (!selectedRole) return

    // Validate the transition
    if (!canTransitionToStatus(currentStatus, selectedRole)) {
      toast.error(
        'This role transition is not allowed from your current status'
      )
      return
    }

    onRequestRole(selectedRole)
    setShowConfirmation(false)
    setSelectedRole(null)
  }

  // Handle cancellation of confirmation
  const handleCancelConfirmation = () => {
    setShowConfirmation(false)
    setSelectedRole(null)
  }

  // Get the display name for the pending role
  const getPendingRoleLabel = (roleId) => {
    const allRoles = Object.values(getRoleOptions()).flat()
    const pendingRole = allRoles.find((role) => role.id === roleId)
    return pendingRole ? pendingRole.label : roleId
  }

  // If user has a pending request
  if (pendingApproval) {
    return (
      <div className="bg-blue-50 p-4 rounded-lg mb-6">
        <h3 className="text-lg font-semibold text-blue-800 mb-2">
          <FontAwesomeIcon icon={faGraduationCap} className="mr-2" />
          Role Request Pending
        </h3>
        <p className="text-blue-600 mb-4">
          Your request to become a{' '}
          {pendingRoleId && getPendingRoleLabel(pendingRoleId)} is currently
          under review. You'll be notified once it's approved.
        </p>
        <button
          onClick={onCancelRequest}
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
        >
          Cancel Request
        </button>
      </div>
    )
  }

  const availableRoles = getRoleOptions()

  if (availableRoles.length === 0) {
    return null // No available role transitions
  }

  return (
    <div className="border border-gray-200 rounded-lg p-6 mb-8">
      <h3 className="text-xl font-semibold mb-4">
        <FontAwesomeIcon icon={faGraduationCap} className="mr-2" />
        Request Role Upgrade
      </h3>

      {showConfirmation ? (
        <div className="bg-yellow-50 p-4 rounded-lg mb-4">
          <h4 className="font-semibold text-yellow-800 mb-2">
            Confirm Role Request
          </h4>
          <p className="text-yellow-700 mb-4">
            Are you sure you want to request the {selectedRole} role? This will
            require approval from an administrator.
          </p>
          <div className="flex gap-3">
            <button
              onClick={handleConfirmRequest}
              className="px-4 py-2 bg-yellow-600 text-white rounded hover:bg-yellow-700 transition-colors"
            >
              Confirm Request
            </button>
            <button
              onClick={handleCancelConfirmation}
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition-colors"
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <>
          <p className="text-gray-600 mb-4">
            Select a role to request an upgrade. Role changes require approval
            and additional information.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {availableRoles.map((role) => (
              <div
                key={role.id}
                className="border border-gray-200 rounded-lg p-4 hover:border-blue-400 hover:bg-blue-50 cursor-pointer transition-all"
                onClick={() => handleRoleSelect(role.id)}
              >
                <div className="flex items-center mb-2">
                  <FontAwesomeIcon
                    icon={role.icon}
                    className="text-blue-600 text-xl mr-3"
                  />
                  <h4 className="font-semibold text-lg">{role.label}</h4>
                </div>

                <p className="text-gray-700 mb-2">{role.description}</p>
                <p className="text-gray-500 text-sm">{role.requirements}</p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

// Add PropTypes validation
RoleRequestSection.propTypes = {
  currentStatus: PropTypes.string.isRequired,
  pendingApproval: PropTypes.bool,
  pendingRoleId: PropTypes.string,
  onRequestRole: PropTypes.func.isRequired,
  onCancelRequest: PropTypes.func.isRequired,
}

// Default props
RoleRequestSection.defaultProps = {
  pendingApproval: false,
  pendingRoleId: '',
}

export default RoleRequestSection
