import React, { createContext, useContext, useState, useEffect } from 'react'
import { useAuth } from '../firebase/auth'
import { hasPermission, mapStatusToRole } from './roles'

// Create the context
const RoleContext = createContext({
  userRole: null,
  userProfile: null,
  isLoading: true,
  hasRole: () => false,
  hasPermission: () => false,
})

/**
 * RoleProvider component to wrap the app and provide role-based context
 */
export const RoleProvider = ({ children }) => {
  const { authUser, userProfile, isLoading: authLoading } = useAuth()
  const [userRole, setUserRole] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  // Update the role whenever the user profile changes
  useEffect(() => {
    if (authLoading) {
      setIsLoading(true)
      return
    }

    if (!authUser || !userProfile) {
      setUserRole(null)
      setIsLoading(false)
      return
    }

    // Map user status to role
    const role = mapStatusToRole(userProfile.status)
    setUserRole(role)
    setIsLoading(false)
  }, [authUser, userProfile, authLoading])

  // Function to check if user has a specific role
  const hasRole = (roleToCheck) => {
    if (!userRole) return false

    // Direct role match
    if (userRole === roleToCheck) return true

    // Role hierarchy logic
    if (roleToCheck === 'STUDENT') {
      return [
        'STUDENT',
        'STUDENT_AMBASSADOR',
        'AMBASSADOR',
        'CORE_MEMBER',
      ].includes(userRole)
    }

    if (roleToCheck === 'STUDENT_AMBASSADOR') {
      return ['STUDENT_AMBASSADOR', 'AMBASSADOR', 'CORE_MEMBER'].includes(
        userRole
      )
    }

    if (roleToCheck === 'AMBASSADOR') {
      return ['AMBASSADOR', 'CORE_MEMBER'].includes(userRole)
    }

    if (roleToCheck === 'CORE_MEMBER') {
      return userRole === 'CORE_MEMBER'
    }

    return false
  }

  // Function to check if user has a specific permission
  const checkPermission = (permission) => {
    if (!userRole) return false
    return hasPermission(userRole, permission)
  }

  const value = {
    userRole,
    userProfile,
    isLoading,
    hasRole,
    hasPermission: checkPermission,
    // Alias for backward compatibility
    role: userRole,
  }

  return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>
}

/**
 * Hook to use the role context
 */
export const useRole = () => {
  const context = useContext(RoleContext)
  if (context === undefined) {
    throw new Error('useRole must be used within a RoleProvider')
  }
  return context
}

export default RoleContext
