import {
  collection,
  getDoc,
  setDoc,
  doc,
  arrayUnion,
  arrayRemove,
  updateDoc,
} from 'firebase/firestore'
import { firestore } from '../utils/firebase'
import { getResourceById } from './resourceService'

// Collection references
const usersCollection = collection(firestore, 'users')

/**
 * Add a resource to a user's library
 * @param {string} userId - The ID of the user
 * @param {string} resourceId - The ID of the resource to add
 * @returns {Promise<void>}
 */
export const addToLibrary = async (userId, resourceId) => {
  try {
    const userRef = doc(usersCollection, userId)

    // Update the user's saved resources array
    await updateDoc(userRef, {
      savedResources: arrayUnion(resourceId),
      lastUpdated: new Date(),
    })

    return true
  } catch (error) {
    console.error('Error adding resource to library:', error)
    throw error
  }
}

/**
 * Remove a resource from a user's library
 * @param {string} userId - The ID of the user
 * @param {string} resourceId - The ID of the resource to remove
 * @returns {Promise<void>}
 */
export const removeFromLibrary = async (userId, resourceId) => {
  try {
    const userRef = doc(usersCollection, userId)

    // Remove the resource ID from the user's saved resources array
    await updateDoc(userRef, {
      savedResources: arrayRemove(resourceId),
      lastUpdated: new Date(),
    })

    return true
  } catch (error) {
    console.error('Error removing resource from library:', error)
    throw error
  }
}

/**
 * Check if a resource is in a user's library
 * @param {string} userId - The ID of the user
 * @param {string} resourceId - The ID of the resource to check
 * @returns {Promise<boolean>} - True if the resource is in the user's library
 */
export const isResourceInLibrary = async (userId, resourceId) => {
  try {
    const userRef = doc(usersCollection, userId)
    const userDoc = await getDoc(userRef)

    if (userDoc.exists()) {
      const userData = userDoc.data()
      return (
        userData.savedResources && userData.savedResources.includes(resourceId)
      )
    }

    return false
  } catch (error) {
    console.error('Error checking if resource is in library:', error)
    throw error
  }
}

/**
 * Get all resources saved by a user
 * @param {string} userId - The ID of the user
 * @returns {Promise<Array>} - Array of resource objects
 */
export const getUserSavedResources = async (userId) => {
  try {
    const userRef = doc(usersCollection, userId)
    const userDoc = await getDoc(userRef)

    if (!userDoc.exists()) {
      return []
    }

    const userData = userDoc.data()

    // Check if user has any saved resources
    if (!userData.savedResources || userData.savedResources.length === 0) {
      return []
    }

    // Fetch each resource by ID
    const resources = await Promise.all(
      userData.savedResources.map(async (resourceId) => {
        try {
          const resource = await getResourceById(resourceId)
          return resource
        } catch (err) {
          console.error(`Error fetching resource ${resourceId}:`, err)
          return null
        }
      })
    )

    // Filter out any null resources (those that couldn't be fetched)
    return resources.filter((resource) => resource !== null)
  } catch (error) {
    console.error('Error getting user saved resources:', error)
    throw error
  }
}

/**
 * Track a resource view by a user (for analytics)
 * @param {string} userId - The ID of the user
 * @param {string} resourceId - The ID of the viewed resource
 * @returns {Promise<void>}
 */
export const trackResourceView = async (userId, resourceId) => {
  try {
    // Get or create user activity document
    const userActivityRef = doc(firestore, 'userActivity', userId)
    const userActivityDoc = await getDoc(userActivityRef)

    const now = new Date()
    const viewData = {
      resourceId,
      timestamp: now,
      date: now.toISOString().split('T')[0],
    }

    if (userActivityDoc.exists()) {
      // Update existing activity document
      await updateDoc(userActivityRef, {
        resourceViews: arrayUnion(viewData),
        lastActive: now,
      })
    } else {
      // Create new activity document
      await setDoc(userActivityRef, {
        userId,
        resourceViews: [viewData],
        lastActive: now,
        createdAt: now,
      })
    }

    return true
  } catch (error) {
    console.error('Error tracking resource view:', error)
    // Don't throw here - we don't want to interrupt the user experience for analytics
    return false
  }
}

/**
 * Get recently viewed resources for a user
 * @param {string} userId - The ID of the user
 * @param {number} limit - Maximum number of resources to return
 * @returns {Promise<Array>} - Array of resource objects with view timestamp
 */
export const getRecentlyViewedResources = async (userId, limit = 5) => {
  try {
    const userActivityRef = doc(firestore, 'userActivity', userId)
    const userActivityDoc = await getDoc(userActivityRef)

    if (!userActivityDoc.exists()) {
      return []
    }

    const activityData = userActivityDoc.data()

    if (
      !activityData.resourceViews ||
      activityData.resourceViews.length === 0
    ) {
      return []
    }

    // Sort views by timestamp (most recent first) and take only the most recent view for each resource
    const uniqueResourceViews = activityData.resourceViews
      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
      .reduce((acc, current) => {
        // If we haven't seen this resource yet, add it to our accumulator
        if (!acc.some((item) => item.resourceId === current.resourceId)) {
          acc.push(current)
        }
        return acc
      }, [])
      .slice(0, limit) // Take only the requested number of items

    // Fetch each resource
    const resources = await Promise.all(
      uniqueResourceViews.map(async (view) => {
        try {
          const resource = await getResourceById(view.resourceId)
          // Add the view timestamp to the resource
          return {
            ...resource,
            viewedAt: view.timestamp,
          }
        } catch (err) {
          console.error(
            `Error fetching viewed resource ${view.resourceId}:`,
            err
          )
          return null
        }
      })
    )

    // Filter out any null resources and return
    return resources.filter((resource) => resource !== null)
  } catch (error) {
    console.error('Error getting recently viewed resources:', error)
    throw error
  }
}
