import React, { useEffect } from 'react'
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import { Navbar } from './components'
import Donations from './pages/site/Donations'
import Library from './pages/site/Library'
import ResourceDetail from './pages/site/ResourceDetail'
import KAI from './pages/site/KAI'
import ChatPage from './pages/site/Chat'
import FeatureFlagTest from './pages/site/FeatureFlagTest'
import RouteRegistryTest from './pages/site/RouteRegistryTest'
import NavRegistryTest from './pages/site/NavRegistryTest'
import DevTools from './pages/site/DevTools'
import PermissionsExplorer from './pages/site/PermissionsExplorer'

// Importing various pages from the 'pages' directory
import {
  AboutUs,
  GalleryPage,
  HomePage,
  LoginPage,
  NotFound404Page,
  Services,
  SignUpPage,
  Team,
} from './pages'

// Import our centralized RBAC route generation
import { generateDashboardRoutes } from './utils/permissions/RbacRoutes'
import { FeatureFlagProvider } from './utils/permissions/FeatureFlags'
import { RoleProvider } from './utils/permissions/RoleContext'
import { AuthUserProvider, useAuth } from './utils/firebase/auth'

// Importing styles and utilities
import './app.scss'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  passwordlessSignin,
  setNavigationFunction,
} from './utils/firebase/auth'
import TermsOfService from './pages/site/TermsOfService'
import Privacy from './pages/site/Privacy'
import Reports from './pages/site/Reports'
import RbacTest from './pages/site/RbacTest'
import { doc, getDoc } from 'firebase/firestore'
import { firestore } from './utils/firebase'
import { toast } from 'react-toastify'
import PaymentTest from './pages/site/PaymentTest'
import CompetitionTest from './pages/site/CompetitionTest'

// Global variable to track authentication status
// eslint-disable-next-line no-unused-vars
let isAuthenticated = false

// List of public routes that don't require authentication
const publicRoutes = [
  '/',
  '/about',
  '/services',
  '/team',
  '/library',
  '/k-ai',
  '/testimonials',
  '/donations',
  '/tos',
  '/privacy-policy',
  '/gallery',
  '/reports',
  '/donate',
  '/resource',
  '/account/login',
  '/account/signup',
]

// Helper function to check if a path is public
const isPublicRoute = (path) => {
  return publicRoutes.some((route) => path.startsWith(route))
}

// Main App component
const App = () => {
  const navigate = useNavigate() // Hook to programmatically navigate
  const { authUser } = useAuth() // Use the auth hook at the top level

  useEffect(() => {
    setNavigationFunction(navigate)

    const checkAuth = async () => {
      try {
        // Try to sign in with email link (passwordless)
        const result = await passwordlessSignin()
        if (result) {
          console.log('Signed in with passwordless auth')
          isAuthenticated = true
        }
      } catch (error) {
        console.error('Error in auth check:', error)
      }
    }

    // Check if the current URL is a sign-in link
    if (window.location.href.includes('apiKey=')) {
      checkAuth()
    }
  }, [navigate])

  // Check if user profile is incomplete and redirect to profile page
  // But only for protected routes that require authentication
  useEffect(() => {
    const checkUserProfile = async () => {
      // Use the authUser from the hook at the component level
      if (authUser) {
        try {
          // Check if the current path is a public route
          const currentPath = window.location.pathname
          if (isPublicRoute(currentPath)) {
            // Don't redirect for public routes
            return
          }

          // For authentication-required routes, check profile completion
          const userDocRef = doc(firestore, 'users', authUser.uid)
          const userDoc = await getDoc(userDocRef)

          // If user has no profile document or has an incomplete profile, redirect to profile page
          if (
            !userDoc.exists() ||
            !userDoc.data().firstName ||
            !userDoc.data().lastName ||
            !userDoc.data().educationLevel
          ) {
            // Only redirect if not already on the profile page
            if (
              !window.location.pathname.includes('/students/dashboard/profile')
            ) {
              navigate('/students/dashboard/profile')

              // Show a toast notification
              toast.info('Please complete your profile to continue', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              })
            }
          }
        } catch (error) {
          console.error('Error checking user profile:', error)
        }
      }
    }

    // Run the profile check
    checkUserProfile()
  }, [navigate, authUser]) // Removed pathname dependency

  // Component to render Navbar and Outlet for nested routes
  const WithNavBar = () => {
    return (
      <>
        <Navbar /> {/* Render the Navbar component */}
        <Outlet /> {/* Render the nested route components */}
      </>
    )
  }

  // Generate all dashboard routes using our centralized system
  const dashboardRoutes = generateDashboardRoutes()

  return (
    <AuthUserProvider>
      <RoleProvider>
        <FeatureFlagProvider>
          <div className="app">
            <Routes>
              {/* Define routes for the application */}
              <Route path="/" element={<WithNavBar />}>
                <Route index element={<HomePage />} /> {/* Default route */}
                <Route path="about" element={<AboutUs />} />
                <Route path="services" element={<Services />} />
                <Route path="team" element={<Team />} />
                <Route path="library" element={<Library />} />
                <Route path="k-ai" element={<KAI />} />
                <Route path="chat/:agentSlug" element={<ChatPage />} />
                <Route path="testimonials" />
                <Route path="donations" element={<Donations />} />
                <Route path="tos" element={<TermsOfService />} />
                <Route path="privacy-policy" element={<Privacy />} />
                <Route path="gallery" element={<GalleryPage />} />
                <Route path="reports" element={<Reports />} />
                <Route path="donate" element={<Donations />} />
                <Route path="rbac-test" element={<RbacTest />} />
                <Route path="feature-flags" element={<FeatureFlagTest />} />
                <Route path="route-registry" element={<RouteRegistryTest />} />
                <Route path="nav-registry" element={<NavRegistryTest />} />
                <Route path="dev-tools" element={<DevTools />} />
                <Route
                  path="permissions-explorer"
                  element={<PermissionsExplorer />}
                />
                <Route path="testing/payment" element={<PaymentTest />} />
                <Route
                  path="testing/competition"
                  element={<CompetitionTest />}
                />
                <Route path="*" element={<NotFound404Page />} />
                <Route path="resource/:id" element={<ResourceDetail />} />
              </Route>
              <Route path="account">
                <Route index element={<NotFound404Page />} />
                <Route path="signup" element={<SignUpPage />} />
                <Route path="login" element={<LoginPage />} />
                <Route path="*" element={<NotFound404Page />} />
              </Route>

              {/* Include all dashboard routes generated from our centralized system */}
              {dashboardRoutes}

              <Route path="*" element={<NotFound404Page />} />
            </Routes>
            <ToastContainer
              position="top-center"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              style={{ width: 'min(90%, 512px)', margin: '1rem' }}
              theme="light"
            />
          </div>
        </FeatureFlagProvider>
      </RoleProvider>
    </AuthUserProvider>
  )
}

export default App
