import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faComments,
  faRobot,
  faChevronRight,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import AIChat from '../../AIChat'

/**
 * Chats Component for Student Dashboard
 */
const Chats = () => {
  const [activeTab, setActiveTab] = useState('regular') // 'regular' or 'ai'
  const [isAIChatOpen, setIsAIChatOpen] = useState(false)
  const [loadingAI, setLoadingAI] = useState(false)

  const handleOpenAIChat = () => {
    setLoadingAI(true)
    // Simulate loading the AI model
    setTimeout(() => {
      setIsAIChatOpen(true)
      setLoadingAI(false)
    }, 1500)
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h1 className="text-2xl font-bold text-blue-900 mb-2">Your Chats</h1>
      <p className="text-gray-600 mb-6">
        Connect with teachers, mentors, fellow students, or chat with K-AI.
      </p>

      {/* Tabs */}
      <div className="border-b border-gray-200 mb-6">
        <div className="flex -mb-px">
          <button
            className={`py-2 px-4 font-medium text-sm border-b-2 ${
              activeTab === 'regular'
                ? 'border-primary text-primary'
                : 'border-transparent text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setActiveTab('regular')}
          >
            <FontAwesomeIcon icon={faComments} className="mr-2" />
            Regular Chats
          </button>
          <button
            className={`py-2 px-4 font-medium text-sm border-b-2 ${
              activeTab === 'ai'
                ? 'border-primary text-primary'
                : 'border-transparent text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setActiveTab('ai')}
          >
            <FontAwesomeIcon icon={faRobot} className="mr-2" />
            K-AI Assistant
          </button>
        </div>
      </div>

      {/* Regular Chats Tab Content */}
      {activeTab === 'regular' && (
        <div className="space-y-4">
          <div className="text-center p-10 bg-gray-50 rounded-lg border border-gray-200">
            <p className="text-gray-500 mb-4">
              No active chats. Start a conversation to see it here.
            </p>
            <button className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors">
              Start New Chat
            </button>
          </div>
          <p className="text-sm text-gray-500 italic text-center">
            Coming soon: Chat with teachers and mentors
          </p>
        </div>
      )}

      {/* K-AI Tab Content */}
      {activeTab === 'ai' && (
        <div className="space-y-4">
          <div className="bg-blue-50 rounded-lg p-6 border border-blue-100">
            <div className="flex items-start">
              <div className="w-12 h-12 bg-blue-600 rounded-full flex items-center justify-center mr-4 flex-shrink-0">
                <FontAwesomeIcon
                  icon={faRobot}
                  className="text-white text-xl"
                />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-blue-900 mb-1">
                  Chat with K-AI
                </h3>
                <p className="text-gray-600 mb-4">
                  K-AI is your AI assistant that can help with your educational
                  needs. Ask questions, get explanations, or discuss concepts.
                </p>
                <button
                  onClick={handleOpenAIChat}
                  disabled={isAIChatOpen || loadingAI}
                  className={`flex items-center space-x-2 bg-primary text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors ${
                    isAIChatOpen || loadingAI
                      ? 'opacity-50 cursor-not-allowed'
                      : ''
                  }`}
                >
                  {loadingAI ? (
                    <>
                      <FontAwesomeIcon icon={faSpinner} spin />
                      <span>Loading K-AI...</span>
                    </>
                  ) : isAIChatOpen ? (
                    <span>K-AI Chat is Open</span>
                  ) : (
                    <>
                      <span>Start Chatting with K-AI</span>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>

          <div className="bg-yellow-50 rounded-lg p-4 border border-yellow-100">
            <p className="text-sm text-yellow-700">
              <strong>Note:</strong> K-AI is still in development and may not
              always provide accurate information. Please verify important
              information from other sources.
            </p>
          </div>

          <div className="rounded-lg border border-gray-200 p-4">
            <h4 className="font-medium text-gray-800 mb-2">
              K-AI can help with:
            </h4>
            <ul className="list-disc list-inside space-y-1 text-gray-600">
              <li>Explaining complex concepts</li>
              <li>Answering questions on various subjects</li>
              <li>Providing practice problems</li>
              <li>Assisting with homework (guidance only)</li>
              <li>Suggesting study resources</li>
            </ul>
          </div>
        </div>
      )}

      {/* AI Chat Component (rendered conditionally) */}
      {isAIChatOpen && (
        <AIChat isOpen={isAIChatOpen} onClose={() => setIsAIChatOpen(false)} />
      )}
    </div>
  )
}

export default Chats
