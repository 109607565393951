import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faEnvelope,
  faPhone,
  faGraduationCap,
  faIdCard,
  faEdit,
  faSave,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../../../utils/firebase/auth'
import { firestore } from '../../../utils/firebase'
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'
import { Alert } from '../../../components'
import RoleInfoSection from './RoleInfoSection'
import RoleRequestSection from './RoleRequestSection'

/**
 * Profile Component for Student Dashboard
 */
const Profile = () => {
  const { authUser } = useAuth()
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)

  // User profile data
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    studentId: '',
    institution: '',
    country: 'Cameroon', // Default to Cameroon
    dateOfBirth: '',
    educationLevel: 'High School',
    avatarUrl: '',
    // New fields for Cameroonian education system
    educationalSystem: '', // Anglophone or Francophone
    academicLevel: '', // Form 1-5, Lower Sixth, Upper Sixth (Anglophone) or 6ème-Terminale (Francophone)
    specializationStream: '', // Arts (A1-A5), Sciences (S1-S5) for Anglophone, or A,B,C,D etc. for Francophone
    examPreparation: [], // GCE O-Level, GCE A-Level, BEPC, Probatoire, Baccalauréat
  })

  const [isEditing, setIsEditing] = useState(false)

  // Fetch user data from Firestore on component mount
  useEffect(() => {
    const fetchUserData = async () => {
      if (!authUser) return

      try {
        setLoading(true)
        const userDocRef = doc(firestore, 'users', authUser.uid)
        const userDoc = await getDoc(userDocRef)

        if (userDoc.exists()) {
          const data = userDoc.data()
          setUserData({
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            email: authUser.email || '',
            phone: data.phone || '',
            studentId: data.studentId || '',
            institution: data.institution || '',
            country: data.country || 'Cameroon',
            dateOfBirth: data.dateOfBirth || '',
            educationLevel: data.educationLevel || 'High School',
            avatarUrl: data.avatarUrl || '',
            // New fields
            educationalSystem: data.educationalSystem || '',
            academicLevel: data.academicLevel || '',
            specializationStream: data.specializationStream || '',
            examPreparation: data.examPreparation || [],
          })
        } else {
          // If user document doesn't exist, set email from auth
          setUserData((prev) => ({
            ...prev,
            email: authUser.email || '',
          }))
        }
      } catch (error) {
        console.error('Error fetching user data:', error)
        Alert.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to load your profile data. Please try again later.',
        })
      } finally {
        setLoading(false)
      }
    }

    fetchUserData()
  }, [authUser])

  const handleChange = (e) => {
    const { name, value } = e.target
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!authUser) {
      Alert.fire({
        icon: 'error',
        title: 'Authentication Error',
        text: 'You must be logged in to update your profile.',
      })
      return
    }

    try {
      setSaving(true)

      const userDocRef = doc(firestore, 'users', authUser.uid)
      const userDoc = await getDoc(userDocRef)

      const profileData = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        phone: userData.phone,
        studentId: userData.studentId,
        institution: userData.institution,
        country: userData.country,
        dateOfBirth: userData.dateOfBirth,
        educationLevel: userData.educationLevel,
        avatarUrl: userData.avatarUrl,
        updatedAt: new Date(),
      }

      if (userDoc.exists()) {
        await updateDoc(userDocRef, profileData)
      } else {
        await setDoc(userDocRef, {
          ...profileData,
          email: authUser.email,
          createdAt: new Date(),
          role: 'student', // Default role
        })
      }

      Alert.fire({
        icon: 'success',
        title: 'Profile Updated',
        text: 'Your profile has been successfully updated!',
      })

      setIsEditing(false)
    } catch (error) {
      console.error('Error updating profile:', error)
      Alert.fire({
        icon: 'error',
        title: 'Update Failed',
        text: 'There was an error updating your profile. Please try again.',
      })
    } finally {
      setSaving(false)
    }
  }

  // Add educational system options
  const educationalSystemOptions = [
    { value: 'Anglophone', label: 'Anglophone (English)' },
    { value: 'Francophone', label: 'Francophone (French)' },
  ]

  // Anglophone academic levels
  const anglophoneAcademicLevels = [
    { value: 'Form 1', label: 'Form 1' },
    { value: 'Form 2', label: 'Form 2' },
    { value: 'Form 3', label: 'Form 3' },
    { value: 'Form 4', label: 'Form 4' },
    { value: 'Form 5', label: 'Form 5 (O-Level)' },
    { value: 'Lower Sixth', label: 'Lower Sixth (L6)' },
    { value: 'Upper Sixth', label: 'Upper Sixth (U6, A-Level)' },
  ]

  // Francophone academic levels
  const francophoneAcademicLevels = [
    { value: '6ème', label: '6ème' },
    { value: '5ème', label: '5ème' },
    { value: '4ème', label: '4ème' },
    { value: '3ème', label: '3ème (BEPC)' },
    { value: '2nde', label: 'Seconde (2nde)' },
    { value: '1ère', label: 'Première (1ère, Probatoire)' },
    { value: 'Terminale', label: 'Terminale (Baccalauréat)' },
  ]

  // Specialization/Stream options based on educational system and level
  const getSpecializationOptions = () => {
    // Check if user is in upper secondary (high school)
    const isAnglophoneUpperSecondary =
      userData.educationalSystem === 'Anglophone' &&
      ['Lower Sixth', 'Upper Sixth'].includes(userData.academicLevel)

    const isFrancophoneUpperSecondary =
      userData.educationalSystem === 'Francophone' &&
      ['2nde', '1ère', 'Terminale'].includes(userData.academicLevel)

    if (isAnglophoneUpperSecondary) {
      return [
        { value: 'A1', label: 'A1 (Arts - Religion, Logic, Literature)' },
        { value: 'A2', label: 'A2 (Arts - Geography, Economics)' },
        { value: 'A3', label: 'A3 (Arts - Mathematics, Economics)' },
        { value: 'A4', label: 'A4 (Arts - Geography, Literature)' },
        { value: 'A5', label: 'A5 (Arts - Geography, Biology)' },
        { value: 'S1', label: 'S1 (Science - Physics, Chemistry, Pure Maths)' },
        {
          value: 'S2',
          label: 'S2 (Science - Physics, Chemistry, Applied Maths)',
        },
        { value: 'S3', label: 'S3 (Science - Physics, Chemistry, Biology)' },
        {
          value: 'S4',
          label: 'S4 (Science - Pure Maths, Applied Maths, Economics)',
        },
        {
          value: 'S5',
          label: 'S5 (Science - Pure Maths, Applied Maths, Further Maths)',
        },
      ]
    } else if (isFrancophoneUpperSecondary) {
      return [
        { value: 'A', label: 'A (Lettres)' },
        { value: 'B', label: 'B (Sciences Économiques)' },
        { value: 'C', label: 'C (Mathématiques et Sciences Physiques)' },
        { value: 'D', label: 'D (Mathématiques et Sciences de la Nature)' },
        { value: 'E', label: 'E (Mathématiques et Techniques)' },
        { value: 'F', label: 'F (Techniques Industrielles)' },
        { value: 'G', label: 'G (Techniques de Gestion)' },
      ]
    }

    // Return empty array for lower secondary levels
    return []
  }

  // Loading state
  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6 flex justify-center items-center h-96">
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            size="3x"
            className="text-primary mb-4"
          />
          <p className="text-gray-600">Loading your profile...</p>
        </div>
      </div>
    )
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold text-blue-900 mb-2">
            Your Profile
          </h1>
          <p className="text-gray-600">
            View and update your personal information.
          </p>
        </div>
        <button
          onClick={() => setIsEditing(!isEditing)}
          className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors flex items-center space-x-2"
        >
          <FontAwesomeIcon icon={isEditing ? faSave : faEdit} />
          <span>{isEditing ? 'View Mode' : 'Edit Profile'}</span>
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Profile image section */}
        <div className="flex justify-center mb-8">
          <div className="relative">
            <div className="w-32 h-32 rounded-full overflow-hidden bg-gray-200 border-4 border-primary">
              {userData.avatarUrl ? (
                <img
                  src={userData.avatarUrl}
                  alt={`${userData.firstName} ${userData.lastName}`}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center">
                  <FontAwesomeIcon
                    icon={faUser}
                    size="4x"
                    className="text-gray-400"
                  />
                </div>
              )}
            </div>
            {isEditing && (
              <div className="mt-2 text-center">
                <input
                  type="text"
                  name="avatarUrl"
                  value={userData.avatarUrl}
                  onChange={handleChange}
                  placeholder="Avatar URL"
                  className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md text-sm"
                />
              </div>
            )}
          </div>
        </div>

        {/* Personal Information */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4">
          {/* First Name */}
          <div>
            <label className="block text-gray-700 font-medium mb-1">
              <FontAwesomeIcon icon={faUser} className="mr-2 text-primary" />
              First Name
            </label>
            {isEditing ? (
              <input
                type="text"
                name="firstName"
                value={userData.firstName}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              />
            ) : (
              <p className="py-2 px-3 bg-gray-50 rounded-md">
                {userData.firstName || 'Not set'}
              </p>
            )}
          </div>

          {/* Last Name */}
          <div>
            <label className="block text-gray-700 font-medium mb-1">
              <FontAwesomeIcon icon={faUser} className="mr-2 text-primary" />
              Last Name
            </label>
            {isEditing ? (
              <input
                type="text"
                name="lastName"
                value={userData.lastName}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              />
            ) : (
              <p className="py-2 px-3 bg-gray-50 rounded-md">
                {userData.lastName || 'Not set'}
              </p>
            )}
          </div>

          {/* Email */}
          <div>
            <label className="block text-gray-700 font-medium mb-1">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="mr-2 text-primary"
              />
              Email
            </label>
            <p className="py-2 px-3 bg-gray-50 rounded-md">{userData.email}</p>
            <p className="text-xs text-gray-500 mt-1">
              Email cannot be changed
            </p>
          </div>

          {/* Phone */}
          <div>
            <label className="block text-gray-700 font-medium mb-1">
              <FontAwesomeIcon icon={faPhone} className="mr-2 text-primary" />
              Phone
            </label>
            {isEditing ? (
              <input
                type="tel"
                name="phone"
                value={userData.phone}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            ) : (
              <p className="py-2 px-3 bg-gray-50 rounded-md">
                {userData.phone || 'Not set'}
              </p>
            )}
          </div>

          {/* Date of Birth */}
          <div>
            <label className="block text-gray-700 font-medium mb-1">
              <FontAwesomeIcon icon={faIdCard} className="mr-2 text-primary" />
              Date of Birth
            </label>
            {isEditing ? (
              <input
                type="date"
                name="dateOfBirth"
                value={userData.dateOfBirth}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            ) : (
              <p className="py-2 px-3 bg-gray-50 rounded-md">
                {userData.dateOfBirth || 'Not set'}
              </p>
            )}
          </div>

          {/* Country */}
          <div>
            <label className="block text-gray-700 font-medium mb-1">
              <FontAwesomeIcon
                icon={faGraduationCap}
                className="mr-2 text-primary"
              />
              Country
            </label>
            {isEditing ? (
              <input
                type="text"
                name="country"
                value={userData.country}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            ) : (
              <p className="py-2 px-3 bg-gray-50 rounded-md">
                {userData.country || 'Not set'}
              </p>
            )}
          </div>

          {/* Education Level */}
          <div>
            <label className="block text-gray-700 font-medium mb-1">
              <FontAwesomeIcon
                icon={faGraduationCap}
                className="mr-2 text-primary"
              />
              Education Level
            </label>
            {isEditing ? (
              <select
                name="educationLevel"
                value={userData.educationLevel}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              >
                <option value="High School">High School</option>
                <option value="Form 1-5">Form 1-5</option>
                <option value="Lower Sixth">Lower Sixth</option>
                <option value="Upper Sixth">Upper Sixth</option>
                <option value="Undergraduate">Undergraduate</option>
                <option value="Graduate">Graduate</option>
                <option value="Postgraduate">Postgraduate</option>
              </select>
            ) : (
              <p className="py-2 px-3 bg-gray-50 rounded-md">
                {userData.educationLevel || 'Not set'}
              </p>
            )}
          </div>

          {/* Institution */}
          <div>
            <label className="block text-gray-700 font-medium mb-1">
              <FontAwesomeIcon
                icon={faGraduationCap}
                className="mr-2 text-primary"
              />
              Institution
            </label>
            {isEditing ? (
              <input
                type="text"
                name="institution"
                value={userData.institution}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            ) : (
              <p className="py-2 px-3 bg-gray-50 rounded-md">
                {userData.institution || 'Not set'}
              </p>
            )}
          </div>

          {/* Student ID */}
          <div>
            <label className="block text-gray-700 font-medium mb-1">
              <FontAwesomeIcon icon={faIdCard} className="mr-2 text-primary" />
              Student ID (optional)
            </label>
            {isEditing ? (
              <input
                type="text"
                name="studentId"
                value={userData.studentId}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            ) : (
              <p className="py-2 px-3 bg-gray-50 rounded-md">
                {userData.studentId || 'Not set'}
              </p>
            )}
          </div>
        </div>

        {/* Bio */}
        <div>
          <label className="block text-gray-700 font-medium mb-1">Bio</label>
          {isEditing ? (
            <textarea
              name="bio"
              value={userData.bio}
              onChange={handleChange}
              rows="4"
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="Tell us about yourself..."
            ></textarea>
          ) : (
            <p className="py-2 px-3 bg-gray-50 rounded-md min-h-[100px] whitespace-pre-wrap">
              {userData.bio || 'No bio provided'}
            </p>
          )}
        </div>

        {/* Education Information Section */}
        <div className="mb-8 p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <FontAwesomeIcon
              icon={faGraduationCap}
              className="mr-2 text-primary"
            />
            Education Information
          </h2>

          {isEditing ? (
            <div className="space-y-4">
              {/* Educational System */}
              <div className="form-group">
                <label
                  htmlFor="educationalSystem"
                  className="mb-1 block font-medium"
                >
                  Educational System*
                </label>
                <select
                  id="educationalSystem"
                  name="educationalSystem"
                  value={userData.educationalSystem}
                  onChange={handleChange}
                  className="form-select w-full rounded border px-4 py-2"
                  required
                >
                  <option value="">Select your educational system</option>
                  {educationalSystemOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              {/* Academic Level */}
              <div className="form-group">
                <label
                  htmlFor="academicLevel"
                  className="mb-1 block font-medium"
                >
                  Academic Level*
                </label>
                <select
                  id="academicLevel"
                  name="academicLevel"
                  value={userData.academicLevel}
                  onChange={handleChange}
                  className="form-select w-full rounded border px-4 py-2"
                  required
                >
                  <option value="">Select your academic level</option>
                  {userData.educationalSystem === 'Anglophone'
                    ? anglophoneAcademicLevels.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))
                    : userData.educationalSystem === 'Francophone'
                    ? francophoneAcademicLevels.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))
                    : null}
                </select>
              </div>

              {/* Specialization/Stream - only for upper secondary */}
              {(userData.educationalSystem === 'Anglophone' &&
                ['Lower Sixth', 'Upper Sixth'].includes(
                  userData.academicLevel
                )) ||
              (userData.educationalSystem === 'Francophone' &&
                ['2nde', '1ère', 'Terminale'].includes(
                  userData.academicLevel
                )) ? (
                <div className="form-group">
                  <label
                    htmlFor="specializationStream"
                    className="mb-1 block font-medium"
                  >
                    Specialization/Stream
                  </label>
                  <select
                    id="specializationStream"
                    name="specializationStream"
                    value={userData.specializationStream}
                    onChange={handleChange}
                    className="form-select w-full rounded border px-4 py-2"
                  >
                    <option value="">Select your specialization</option>
                    {getSpecializationOptions().map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}

              {/* Exam Preparation */}
              <div className="form-group">
                <label className="mb-1 block font-medium">
                  Exam Preparation
                </label>
                <div className="space-y-2">
                  {userData.educationalSystem === 'Anglophone' ? (
                    <>
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="GCE-OLevel"
                          name="examPreparation"
                          value="GCE O-Level"
                          checked={userData.examPreparation.includes(
                            'GCE O-Level'
                          )}
                          onChange={(e) => {
                            const value = e.target.value
                            setUserData((prev) => ({
                              ...prev,
                              examPreparation: e.target.checked
                                ? [...prev.examPreparation, value]
                                : prev.examPreparation.filter(
                                    (item) => item !== value
                                  ),
                            }))
                          }}
                          className="mr-2"
                        />
                        <label htmlFor="GCE-OLevel">GCE O-Level</label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="GCE-ALevel"
                          name="examPreparation"
                          value="GCE A-Level"
                          checked={userData.examPreparation.includes(
                            'GCE A-Level'
                          )}
                          onChange={(e) => {
                            const value = e.target.value
                            setUserData((prev) => ({
                              ...prev,
                              examPreparation: e.target.checked
                                ? [...prev.examPreparation, value]
                                : prev.examPreparation.filter(
                                    (item) => item !== value
                                  ),
                            }))
                          }}
                          className="mr-2"
                        />
                        <label htmlFor="GCE-ALevel">GCE A-Level</label>
                      </div>
                    </>
                  ) : userData.educationalSystem === 'Francophone' ? (
                    <>
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="BEPC"
                          name="examPreparation"
                          value="BEPC"
                          checked={userData.examPreparation.includes('BEPC')}
                          onChange={(e) => {
                            const value = e.target.value
                            setUserData((prev) => ({
                              ...prev,
                              examPreparation: e.target.checked
                                ? [...prev.examPreparation, value]
                                : prev.examPreparation.filter(
                                    (item) => item !== value
                                  ),
                            }))
                          }}
                          className="mr-2"
                        />
                        <label htmlFor="BEPC">BEPC</label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="Probatoire"
                          name="examPreparation"
                          value="Probatoire"
                          checked={userData.examPreparation.includes(
                            'Probatoire'
                          )}
                          onChange={(e) => {
                            const value = e.target.value
                            setUserData((prev) => ({
                              ...prev,
                              examPreparation: e.target.checked
                                ? [...prev.examPreparation, value]
                                : prev.examPreparation.filter(
                                    (item) => item !== value
                                  ),
                            }))
                          }}
                          className="mr-2"
                        />
                        <label htmlFor="Probatoire">Probatoire</label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="Baccalaureat"
                          name="examPreparation"
                          value="Baccalauréat"
                          checked={userData.examPreparation.includes(
                            'Baccalauréat'
                          )}
                          onChange={(e) => {
                            const value = e.target.value
                            setUserData((prev) => ({
                              ...prev,
                              examPreparation: e.target.checked
                                ? [...prev.examPreparation, value]
                                : prev.examPreparation.filter(
                                    (item) => item !== value
                                  ),
                            }))
                          }}
                          className="mr-2"
                        />
                        <label htmlFor="Baccalaureat">Baccalauréat</label>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <div className="space-y-3">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-gray-500">Educational System</p>
                  <p className="font-medium">
                    {userData.educationalSystem || 'Not specified'}
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Academic Level</p>
                  <p className="font-medium">
                    {userData.academicLevel || 'Not specified'}
                  </p>
                </div>
              </div>

              {userData.specializationStream && (
                <div>
                  <p className="text-sm text-gray-500">Specialization/Stream</p>
                  <p className="font-medium">{userData.specializationStream}</p>
                </div>
              )}

              <div>
                <p className="text-sm text-gray-500">Exam Preparation</p>
                {userData.examPreparation &&
                userData.examPreparation.length > 0 ? (
                  <div className="flex flex-wrap gap-2 mt-1">
                    {userData.examPreparation.map((exam) => (
                      <span
                        key={exam}
                        className="px-2 py-1 bg-primary-100 text-primary-700 rounded text-xs font-medium"
                      >
                        {exam}
                      </span>
                    ))}
                  </div>
                ) : (
                  <p className="font-medium">None selected</p>
                )}
              </div>
            </div>
          )}
        </div>

        {isEditing && (
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-green-600 text-white px-6 py-2 rounded-lg hover:bg-green-700 transition-colors flex items-center space-x-2 disabled:opacity-50"
              disabled={saving}
            >
              {saving ? (
                <>
                  <FontAwesomeIcon icon={faSpinner} spin />
                  <span>Saving...</span>
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faSave} />
                  <span>Save Profile</span>
                </>
              )}
            </button>
          </div>
        )}
      </form>

      {/* Role Info & Request sections */}
      <div className="mt-8">
        <RoleInfoSection />
        <RoleRequestSection />
      </div>
    </div>
  )
}

export default Profile
