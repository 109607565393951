/**
 * About the app
 */

export const rootURL = window.location.origin || 'https://ketacademy.web.app'
export const actionCodeSettings = {
  url: `${rootURL}/dashboard`,
  handleCodeInApp: true,
}

//User permission

const permissions = {
  AMBASSADOR: 'Ambassador', // Ambassador & Facilitators
  ADMINISTRATOR: 'HOD and/or Ex-Co Member', //Head of Department
  STUDENT: 'Student',
  CORE: 'Core Member', //Core Members
}

const roles = ['SOCIAL_MEDIA_MANAGER', 'GRAPHIC_DESIGNER']

// Educational system specializations/concentrations
const concentrations = {
  Anglophone: {
    Arts: ['A1', 'A2', 'A3', 'A4', 'A5'],
    Sciences: ['S1', 'S2', 'S3', 'S4', 'S5'],
  },
  Francophone: {
    A: 'Letters and Philosophy',
    B: 'Economics and Social Science',
    C: 'Mathematics and Physical Science',
    D: 'Mathematics and Life/Earth Science',
    E: 'Mathematics and Technology',
    TI: 'Information Technology',
  },
}

// Cameroonian educational subsystems
const subSystems = ['Anglophone', 'Francophone']

// Academic levels by subsystem
const levels = {
  Anglophone: {
    'Lower Secondary': ['Form 1', 'Form 2', 'Form 3', 'Form 4', 'Form 5'],
    'Upper Secondary': ['Lower Sixth', 'Upper Sixth'],
  },
  Francophone: {
    'Lower Secondary': ['6ème', '5ème', '4ème', '3ème'],
    'Upper Secondary': ['Seconde', 'Première', 'Terminale'],
  },
}

// Cameroonian examination types
const examTypes = {
  Anglophone: ['GCE O-Level', 'GCE A-Level'],
  Francophone: ['BEPC', 'Probatoire', 'Baccalauréat'],
}

// Cameroon regions
const CAMEROON_REGIONS = [
  'Adamawa',
  'Centre',
  'East',
  'Far North',
  'Littoral',
  'North',
  'Northwest',
  'South',
  'Southwest',
  'West',
]

// Subjects by educational system
const subjects = {
  // Anglophone system subjects
  Anglophone: {
    // O-level subjects
    'O-Level': {
      MATH: 'Mathematics',
      ENG: 'English Language',
      FRE: 'French',
      BIO: 'Biology',
      CHEM: 'Chemistry',
      PHY: 'Physics',
      HIST: 'History',
      GEO: 'Geography',
      LIT: 'Literature in English',
      ECON: 'Economics',
      COMM: 'Commerce',
      COMP: 'Computer Science',
      FMA: 'Further Mathematics',
      REL: 'Religious Studies',
      CIV: 'Citizenship Education',
    },
    // A-level subjects
    'A-Level': {
      MATH: 'Mathematics',
      FMA: 'Further Mathematics',
      CHEM: 'Chemistry',
      PHY: 'Physics',
      BIO: 'Biology',
      ECON: 'Economics',
      HIST: 'History',
      GEO: 'Geography',
      LIT: 'Literature in English',
      FRE: 'French',
      COMP: 'Computer Science',
      PHIL: 'Philosophy',
    },
  },
  // Francophone system subjects
  Francophone: {
    MATH: 'Mathématiques',
    PHY: 'Physique',
    CHEM: 'Chimie',
    BIO: 'Biologie',
    FRE: 'Français',
    ENG: 'Anglais',
    HIST: 'Histoire',
    GEO: 'Géographie',
    PHIL: 'Philosophie',
    ECO: 'Sciences Économiques',
    LITT: 'Littérature',
    COMP: 'Informatique',
    SVT: 'Sciences de la Vie et de la Terre',
    EPS: 'Éducation Physique et Sportive',
  },
}

/* 
=================================
* Resources
==================================
*/
const resourceCategories = {
  PDF: 'Pdf',
  YOUTUBE: 'Youtube',
  DOC: 'Document',
  PPT: 'Presentation',
  QUIZ: 'Quiz',
  PRACTICE: 'Practice Exercise',
  EXAM: 'Past Examination',
}

// Resource types - educational content categorization
const RESOURCE_TYPES = {
  READINGS: 'readings',
  VIDEOS: 'videos',
  DOCUMENTS: 'docs',
  ASSIGNMENTS: 'assignments',
  EXAM_PREP: 'exam_prep',
}

// Language options for resources
const LANGUAGES = ['English', 'French', 'Bilingual']

export const DUMMY_PROFILE_IMAGE =
  'https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=KET+Academy'

// Define the post-login redirect path
export const POST_LOGIN_REDIRECT = '/dashboard/profile'

export {
  resourceCategories,
  RESOURCE_TYPES,
  concentrations,
  levels,
  permissions,
  roles,
  subSystems,
  subjects,
  examTypes,
  CAMEROON_REGIONS,
  LANGUAGES,
}
