import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  query,
  where,
  orderBy,
  serverTimestamp,
  increment,
  arrayUnion,
  Timestamp,
} from 'firebase/firestore'
import { firestore } from '../utils/firebase'

// Collection references
const progressCollection = collection(firestore, 'userProgress')
const userActivityCollection = collection(firestore, 'userActivity')

/**
 * Record a resource completion for a user
 * @param {string} userId - The ID of the user
 * @param {string} resourceId - The ID of the completed resource
 * @param {number} completionPercentage - Percentage of the resource completed (0-100)
 * @param {number} timeSpentMinutes - Time spent in minutes
 * @returns {Promise<boolean>} - True if successful
 */
export const recordResourceProgress = async (
  userId,
  resourceId,
  completionPercentage = 100,
  timeSpentMinutes = 0
) => {
  try {
    const progressId = `${userId}_${resourceId}`
    const progressRef = doc(progressCollection, progressId)
    const progressDoc = await getDoc(progressRef)

    const now = new Date()
    const timestamp = Timestamp.fromDate(now)

    if (progressDoc.exists()) {
      // Update existing progress
      const currentData = progressDoc.data()
      const newCompletionPercentage = Math.max(
        currentData.completionPercentage,
        completionPercentage
      )
      const isNewlyCompleted =
        currentData.completionPercentage < 100 && completionPercentage >= 100

      await updateDoc(progressRef, {
        completionPercentage: newCompletionPercentage,
        timeSpentMinutes: increment(timeSpentMinutes),
        lastUpdated: timestamp,
        ...(isNewlyCompleted ? { completedAt: timestamp } : {}),
      })

      // If newly completed, update the user's stats
      if (isNewlyCompleted) {
        await updateUserStats(userId, {
          completedResources: increment(1),
          totalTimeSpentMinutes: increment(
            currentData.timeSpentMinutes + timeSpentMinutes
          ),
          totalPoints: increment(10), // Award points for completion
        })
      }
    } else {
      // Create new progress entry
      const isCompleted = completionPercentage >= 100
      await setDoc(progressRef, {
        userId,
        resourceId,
        completionPercentage,
        timeSpentMinutes,
        startedAt: timestamp,
        lastUpdated: timestamp,
        ...(isCompleted ? { completedAt: timestamp } : {}),
      })

      // If completed on first record, update user stats
      if (isCompleted) {
        await updateUserStats(userId, {
          completedResources: increment(1),
          totalTimeSpentMinutes: increment(timeSpentMinutes),
          totalPoints: increment(10), // Award points for completion
        })
      }
    }

    // Record activity
    await recordActivity(userId, 'resource_progress', {
      resourceId,
      completionPercentage,
      timeSpentMinutes,
    })

    return true
  } catch (error) {
    console.error('Error recording resource progress:', error)
    throw error
  }
}

/**
 * Get a user's progress for a specific resource
 * @param {string} userId - The ID of the user
 * @param {string} resourceId - The ID of the resource
 * @returns {Promise<Object|null>} - Progress data or null if not found
 */
export const getResourceProgress = async (userId, resourceId) => {
  try {
    const progressId = `${userId}_${resourceId}`
    const progressRef = doc(progressCollection, progressId)
    const progressDoc = await getDoc(progressRef)

    if (progressDoc.exists()) {
      return progressDoc.data()
    }

    return null
  } catch (error) {
    console.error('Error getting resource progress:', error)
    throw error
  }
}

/**
 * Get all progress records for a user
 * @param {string} userId - The ID of the user
 * @returns {Promise<Array>} - Array of progress records
 */
export const getUserProgress = async (userId) => {
  try {
    const q = query(
      progressCollection,
      where('userId', '==', userId),
      orderBy('lastUpdated', 'desc')
    )
    const querySnapshot = await getDocs(q)

    return querySnapshot.docs.map((doc) => doc.data())
  } catch (error) {
    console.error('Error getting user progress:', error)
    throw error
  }
}

/**
 * Update a user's aggregate statistics
 * @param {string} userId - The ID of the user
 * @param {Object} stats - Statistics to update
 * @returns {Promise<void>}
 */
export const updateUserStats = async (userId, stats) => {
  try {
    const userStatsRef = doc(firestore, 'userStats', userId)
    const userStatsDoc = await getDoc(userStatsRef)

    if (userStatsDoc.exists()) {
      // Update existing stats
      await updateDoc(userStatsRef, {
        ...stats,
        lastUpdated: serverTimestamp(),
      })
    } else {
      // Create new stats document
      await setDoc(userStatsRef, {
        userId,
        completedResources: stats.completedResources || 0,
        totalTimeSpentMinutes: stats.totalTimeSpentMinutes || 0,
        totalPoints: stats.totalPoints || 0,
        createdAt: serverTimestamp(),
        lastUpdated: serverTimestamp(),
      })
    }
  } catch (error) {
    console.error('Error updating user stats:', error)
    throw error
  }
}

/**
 * Get a user's aggregate statistics
 * @param {string} userId - The ID of the user
 * @returns {Promise<Object>} - User statistics
 */
export const getUserStats = async (userId) => {
  try {
    const userStatsRef = doc(firestore, 'userStats', userId)
    const userStatsDoc = await getDoc(userStatsRef)

    if (userStatsDoc.exists()) {
      return userStatsDoc.data()
    }

    // Return default stats if none exist
    return {
      userId,
      completedResources: 0,
      totalTimeSpentMinutes: 0,
      totalPoints: 0,
    }
  } catch (error) {
    console.error('Error getting user stats:', error)
    throw error
  }
}

/**
 * Record a user activity
 * @param {string} userId - The ID of the user
 * @param {string} activityType - Type of activity
 * @param {Object} data - Activity data
 * @returns {Promise<void>}
 */
export const recordActivity = async (userId, activityType, data = {}) => {
  try {
    const userActivityRef = doc(userActivityCollection, userId)
    const userActivityDoc = await getDoc(userActivityRef)

    const activityData = {
      type: activityType,
      timestamp: serverTimestamp(),
      data,
    }

    if (userActivityDoc.exists()) {
      // Update existing activity document
      await updateDoc(userActivityRef, {
        activities: arrayUnion(activityData),
        lastActive: serverTimestamp(),
      })
    } else {
      // Create new activity document
      await setDoc(userActivityRef, {
        userId,
        activities: [activityData],
        lastActive: serverTimestamp(),
        createdAt: serverTimestamp(),
      })
    }
  } catch (error) {
    console.error('Error recording user activity:', error)
    // Don't throw here - we don't want to interrupt the user experience for analytics
  }
}
