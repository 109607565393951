import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarAlt,
  faUsers,
  faLocationDot,
  faChalkboardTeacher,
} from '@fortawesome/free-solid-svg-icons'

const EventsDashboard = () => {
  // Sample events data - in a real app, this would come from your backend
  const upcomingEvents = []

  return (
    <div className="events-dashboard p-6 max-w-7xl mx-auto">
      {/* Breadcrumb */}
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900 mb-2">
          Events Dashboard
        </h1>
        <p className="text-gray-600">
          Create and manage educational events, workshops, and information
          sessions.
        </p>
      </div>

      {/* Quick Actions */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
          <div className="flex items-center mb-4">
            <div className="rounded-full p-3 bg-blue-50 text-blue-600 mr-4">
              <FontAwesomeIcon icon={faCalendarAlt} />
            </div>
            <h3 className="text-lg font-semibold">Create Event</h3>
          </div>
          <p className="text-gray-600 mb-4">
            Schedule a new educational event or workshop.
          </p>
          <button className="text-blue-600 hover:text-blue-700 font-medium">
            Create New Event →
          </button>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
          <div className="flex items-center mb-4">
            <div className="rounded-full p-3 bg-green-50 text-green-600 mr-4">
              <FontAwesomeIcon icon={faUsers} />
            </div>
            <h3 className="text-lg font-semibold">Manage Registrations</h3>
          </div>
          <p className="text-gray-600 mb-4">
            View and manage event registrations and attendance.
          </p>
          <button className="text-green-600 hover:text-green-700 font-medium">
            View Registrations →
          </button>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
          <div className="flex items-center mb-4">
            <div className="rounded-full p-3 bg-purple-50 text-purple-600 mr-4">
              <FontAwesomeIcon icon={faLocationDot} />
            </div>
            <h3 className="text-lg font-semibold">Venues</h3>
          </div>
          <p className="text-gray-600 mb-4">
            Manage event venues and location details.
          </p>
          <button className="text-purple-600 hover:text-purple-700 font-medium">
            Manage Venues →
          </button>
        </div>
      </div>

      {/* Upcoming Events */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-100">
        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center">
              <div className="rounded-full p-3 bg-orange-50 text-orange-600 mr-4">
                <FontAwesomeIcon icon={faChalkboardTeacher} />
              </div>
              <h2 className="text-lg font-semibold">Your Events</h2>
            </div>
            <div className="flex gap-2">
              <button className="px-4 py-2 text-sm bg-gray-50 text-gray-600 rounded hover:bg-gray-100">
                Filter
              </button>
              <button className="px-4 py-2 text-sm bg-primary text-white rounded hover:bg-primary-dark">
                Create Event
              </button>
            </div>
          </div>

          {upcomingEvents.length === 0 ? (
            <div className="text-center py-12 text-gray-500">
              <div className="mb-4">
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  className="text-4xl text-gray-300"
                />
              </div>
              <p className="mb-2">No upcoming events</p>
              <p className="text-sm">Create your first event to get started</p>
            </div>
          ) : (
            <div className="divide-y divide-gray-100">
              {upcomingEvents.map((event) => (
                <div key={event.id} className="py-4">
                  {/* Event item content would go here */}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default EventsDashboard
