import { CORE_ROLES } from './roles'

/**
 * Centralized registry of routes with their access requirements
 * This makes it easy to manage which roles have access to which routes
 * Each entry specifies the roles that can access the route and the component to render
 */
const routeRegistry = {
  // Student routes (accessible by all roles)
  dashboard: {
    path: '/dashboard',
    roles: [
      CORE_ROLES.STUDENT,
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    redirectTo: '/dashboard/home',
    title: 'Student Dashboard',
  },
  'dashboard/home': {
    path: '/dashboard/home',
    roles: [
      CORE_ROLES.STUDENT,
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    title: 'Student Dashboard',
  },
  'dashboard/profile': {
    path: '/dashboard/profile',
    roles: [
      CORE_ROLES.STUDENT,
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    title: 'Profile',
  },
  'dashboard/resources': {
    path: '/dashboard/resources',
    roles: [
      CORE_ROLES.STUDENT,
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    title: 'Resources',
  },
  'dashboard/library': {
    path: '/dashboard/library',
    roles: [
      CORE_ROLES.STUDENT,
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    title: 'Library',
  },
  'dashboard/chats': {
    path: '/dashboard/chats',
    roles: [
      CORE_ROLES.STUDENT,
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    title: 'Chats',
  },
  'dashboard/subscriptions': {
    path: '/dashboard/subscriptions',
    roles: [
      CORE_ROLES.STUDENT,
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    title: 'Premium Subscriptions',
  },
  'dashboard/competitions': {
    path: '/dashboard/competitions',
    roles: [
      CORE_ROLES.STUDENT,
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    title: 'Academic Competitions',
  },

  // Student Ambassador routes
  'ambassador/dashboard': {
    path: '/ambassador/dashboard',
    roles: [
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    redirectTo: '/ambassador/dashboard/home',
    title: 'Ambassador Dashboard',
  },
  'ambassador/dashboard/home': {
    path: '/ambassador/dashboard/home',
    roles: [
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    title: 'Ambassador Dashboard',
  },
  'ambassador/dashboard/resources': {
    path: '/ambassador/dashboard/resources',
    roles: [
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    title: 'Resources',
  },
  'ambassador/dashboard/library': {
    path: '/ambassador/dashboard/library',
    roles: [
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    title: 'Library',
  },
  'ambassador/dashboard/events': {
    path: '/ambassador/dashboard/events',
    roles: [
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    title: 'Events',
  },
  'ambassador/dashboard/events/create': {
    path: '/ambassador/dashboard/events/create',
    roles: [
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    title: 'Create Event',
  },
  'ambassador/dashboard/mentoring': {
    path: '/ambassador/dashboard/mentoring',
    roles: [
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    title: 'Mentoring',
  },
  'ambassador/dashboard/analytics': {
    path: '/ambassador/dashboard/analytics',
    roles: [
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    title: 'Analytics',
  },
  'ambassador/dashboard/promotions': {
    path: '/ambassador/dashboard/promotions',
    roles: [
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    title: 'Promotions',
  },
  'ambassador/dashboard/students': {
    path: '/ambassador/dashboard/students',
    roles: [
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    title: 'Student Group',
  },
  'ambassador/dashboard/chats': {
    path: '/ambassador/dashboard/chats',
    roles: [
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    title: 'Chats',
  },
  'ambassador/dashboard/profile': {
    path: '/ambassador/dashboard/profile',
    roles: [
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    title: 'Profile',
  },

  // Admin routes (admin and core members only)
  'admin/dashboard': {
    path: '/admin/dashboard',
    roles: [CORE_ROLES.AMBASSADOR, CORE_ROLES.CORE_MEMBER],
    redirectTo: '/admin/dashboard/home',
    title: 'Admin Dashboard',
  },
  'admin/dashboard/profile': {
    path: '/admin/dashboard/profile',
    roles: [CORE_ROLES.AMBASSADOR, CORE_ROLES.CORE_MEMBER],
    title: 'Profile',
  },
  'admin/dashboard/resources': {
    path: '/admin/dashboard/resources',
    roles: [CORE_ROLES.AMBASSADOR, CORE_ROLES.CORE_MEMBER],
    title: 'Resources',
  },
  'admin/dashboard/courses': {
    path: '/admin/dashboard/courses',
    roles: [CORE_ROLES.AMBASSADOR, CORE_ROLES.CORE_MEMBER],
    title: 'Courses',
  },
  'admin/dashboard/approvals': {
    path: '/admin/dashboard/approvals',
    roles: [CORE_ROLES.AMBASSADOR, CORE_ROLES.CORE_MEMBER],
    title: 'Status Approvals',
  },
  'admin/dashboard/schools': {
    path: '/admin/dashboard/schools',
    roles: [CORE_ROLES.AMBASSADOR, CORE_ROLES.CORE_MEMBER],
    title: 'Schools',
  },
  'admin/dashboard/schedule': {
    path: '/admin/dashboard/schedule',
    roles: [CORE_ROLES.AMBASSADOR, CORE_ROLES.CORE_MEMBER],
    title: 'Schedule',
  },
  'admin/dashboard/academy': {
    path: '/admin/dashboard/academy',
    roles: [CORE_ROLES.AMBASSADOR, CORE_ROLES.CORE_MEMBER],
    title: 'Academy',
  },
  'admin/dashboard/students': {
    path: '/admin/dashboard/students',
    roles: [CORE_ROLES.AMBASSADOR, CORE_ROLES.CORE_MEMBER],
    title: 'Students',
  },
  'admin/dashboard/instructors': {
    path: '/admin/dashboard/instructors',
    roles: [CORE_ROLES.AMBASSADOR, CORE_ROLES.CORE_MEMBER],
    title: 'Ambassadors',
  },

  // Core member only routes
  'admin/dashboard/system': {
    path: '/admin/dashboard/system',
    roles: [CORE_ROLES.CORE_MEMBER],
    title: 'System Settings',
  },

  // Testing pages (accessible by all roles)
  'testing/payment': {
    path: '/testing/payment',
    roles: [
      CORE_ROLES.STUDENT,
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    title: 'Payment System Testing',
  },
  'testing/competition': {
    path: '/testing/competition',
    roles: [
      CORE_ROLES.STUDENT,
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    title: 'Competition Registration Testing',
  },
}

export default routeRegistry
