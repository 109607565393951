import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, Outlet, Link } from 'react-router-dom'
import { useRole } from '../../utils/permissions/RoleContext'
import { getNavigationForRole } from '../../utils/permissions/NavigationRegistry'
import { useAuth } from '../../utils/firebase/auth'
import { signOut } from 'firebase/auth'
import { auth } from '../../utils/firebase'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSignOutAlt,
  faUser,
  faBars,
  faTimes,
  faHome,
  faBook,
  faCalendarAlt,
  faFolder,
  faGraduationCap,
  faChartLine,
  faCog,
} from '@fortawesome/free-solid-svg-icons'

// Map of icon names to FontAwesome icons
const iconMap = {
  dashboard: faHome,
  home: faHome,
  book: faBook,
  calendar: faCalendarAlt,
  library: faFolder,
  user: faUser,
  chart: faChartLine,
  settings: faCog,
  graduation: faGraduationCap,
  default: faHome,
}

/**
 * UnifiedDashboard Component
 * A dashboard that adapts based on user role, providing appropriate navigation
 * and content for students, ambassadors, or admin users.
 */
const UnifiedDashboard = () => {
  const { role, hasRole } = useRole()
  const { authUser, userProfile } = useAuth()
  const navigate = useNavigate()
  const { dashboardType, section } = useParams()
  const [navigation, setNavigation] = useState([])
  const [loading, setLoading] = useState(true)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  useEffect(() => {
    if (role) {
      const roleNavigation = getNavigationForRole(role)
      setNavigation(roleNavigation)
      setLoading(false)
    }
  }, [role])

  useEffect(() => {
    if (!loading) {
      if (dashboardType === 'student' && !hasRole('STUDENT')) {
        navigate('/dashboard')
      } else if (
        dashboardType === 'ambassador' &&
        !hasRole('STUDENT_AMBASSADOR')
      ) {
        navigate('/dashboard')
      } else if (dashboardType === 'admin' && !hasRole('CORE_MEMBER')) {
        navigate('/dashboard')
      }
    }
  }, [dashboardType, hasRole, loading, navigate])

  const handleLogout = async () => {
    try {
      await signOut(auth)
      toast.success('Logged out successfully')
      navigate('/')
    } catch (error) {
      toast.error('Error logging out')
    }
  }

  const getIconForItem = (item) => {
    if (item.faIcon) return item.faIcon
    if (item.icon && iconMap[item.icon]) return iconMap[item.icon]
    return iconMap.default
  }

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen)
  }

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
        <div className="w-12 h-12 border-4 border-primary border-t-transparent rounded-full animate-spin mb-4"></div>
        <p className="text-gray-600">Loading dashboard...</p>
      </div>
    )
  }

  return (
    <div className="flex min-h-screen bg-gray-50">
      {/* Mobile Menu Toggle */}
      <button
        className="fixed bottom-6 right-6 z-50 flex items-center justify-center w-14 h-14 rounded-full bg-primary text-white shadow-lg md:hidden hover:bg-primary-dark transition-all active:scale-95"
        onClick={toggleMobileMenu}
        aria-label={mobileMenuOpen ? 'Close menu' : 'Open menu'}
      >
        <FontAwesomeIcon
          icon={mobileMenuOpen ? faTimes : faBars}
          className="text-xl"
        />
      </button>

      {/* Dashboard Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 w-72 bg-gradient-to-b from-primary-dark to-primary text-white flex flex-col shadow-lg transition-transform duration-300 ease-in-out z-40 overflow-y-auto md:translate-x-0 ${
          mobileMenuOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <div className="p-6 border-b border-white/10 flex justify-center">
          <Link to="/" className="text-white">
            <h2 className="text-2xl font-bold">KET Academy</h2>
          </Link>
        </div>

        <div className="flex items-center p-5 border-b border-white/10">
          <div className="w-10 h-10 bg-white/20 rounded-full flex items-center justify-center mr-3">
            <FontAwesomeIcon icon={faUser} className="text-lg" />
          </div>
          <div className="flex-1 min-w-0">
            <p className="font-semibold text-sm truncate">
              {userProfile?.fullName ||
                authUser?.displayName ||
                authUser?.email ||
                'User'}
            </p>
            <p className="text-sm text-white/80">{role || 'Student'}</p>
          </div>
        </div>

        <nav className="flex-1 py-4">
          <ul className="space-y-0.5">
            {navigation.map((item) => (
              <li key={item.id || item.text}>
                <Link
                  to={item.path || item.link}
                  className={`flex items-center px-6 py-3 text-white/80 hover:bg-white/10 transition-colors ${
                    section === item.key
                      ? 'bg-white/15 font-semibold relative before:absolute before:left-0 before:top-0 before:h-full before:w-1 before:bg-accent'
                      : ''
                  }`}
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <FontAwesomeIcon
                    icon={getIconForItem(item)}
                    className="w-5 h-5 mr-3"
                  />
                  <span>{item.label || item.text}</span>
                </Link>
                {item.subItems && (
                  <ul className="ml-10 text-sm">
                    {item.subItems.map((subItem) => (
                      <li key={subItem.id || subItem.text}>
                        <Link
                          to={subItem.path || subItem.link}
                          className="block px-4 py-2 text-white/80 hover:bg-white/10 transition-colors"
                          onClick={() => setMobileMenuOpen(false)}
                        >
                          {subItem.label || subItem.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>

        <div className="p-6 border-t border-white/10">
          <button
            onClick={handleLogout}
            className="w-full flex items-center justify-center px-4 py-2 text-white border border-white/20 rounded hover:bg-white/10 transition-colors"
          >
            <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
            <span>Logout</span>
          </button>
        </div>
      </div>

      {/* Dashboard Content Area */}
      <div className="flex-1 ml-0 md:ml-72">
        {/* Dashboard Header */}
        <header className="sticky top-0 z-30 bg-white shadow-sm">
          <div className="px-6 py-4 flex flex-col md:flex-row md:items-center md:justify-between">
            <h1 className="text-2xl font-semibold text-primary-dark">
              {dashboardType
                ? `${
                    dashboardType.charAt(0).toUpperCase() +
                    dashboardType.slice(1)
                  } Dashboard`
                : 'Dashboard'}
            </h1>
            <div className="mt-4 md:mt-0">
              <button
                onClick={handleLogout}
                className="w-full md:w-auto flex items-center justify-center px-4 py-2 bg-primary-light text-white rounded hover:bg-primary-dark transition-colors"
              >
                <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                <span>Logout</span>
              </button>
            </div>
          </div>
        </header>

        {/* Dashboard Main Content */}
        <main className="p-6 bg-gray-50 min-h-[calc(100vh-theme(spacing.32))]">
          <Outlet />
        </main>

        {/* Dashboard Footer */}
        <footer className="bg-white border-t border-gray-200 px-6 py-4">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between text-sm text-gray-500">
            <p>
              &copy; {new Date().getFullYear()} KET Academy. All rights
              reserved.
            </p>
            <div className="mt-4 md:mt-0 flex gap-6 md:gap-6">
              <Link
                to="/privacy-policy"
                className="hover:text-primary transition-colors"
              >
                Privacy Policy
              </Link>
              <Link to="/tos" className="hover:text-primary transition-colors">
                Terms of Service
              </Link>
              <Link
                to="/contact"
                className="hover:text-primary transition-colors"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default UnifiedDashboard
