import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarAlt,
  faPlus,
  faEdit,
  faTrash,
  faFilter,
  faSearch,
  faClock,
  faUsers,
  faGraduationCap,
  faChalkboardTeacher,
} from '@fortawesome/free-solid-svg-icons'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'

const ScheduleManagement = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const [filterType, setFilterType] = useState('all')
  const [selectedDate, setSelectedDate] = useState(new Date())

  // Sample schedule data - in a real app, this would come from your backend
  const schedules = [
    {
      id: 1,
      title: 'Mathematics Class',
      instructor: 'Dr. Jane Smith',
      type: 'class',
      date: '2024-03-15',
      time: '09:00 AM',
      duration: '1 hour',
      participants: 0,
      status: 'scheduled',
    },
    {
      id: 2,
      title: 'Physics Workshop',
      instructor: 'Prof. Michael Brown',
      type: 'workshop',
      date: '2024-03-16',
      time: '02:00 PM',
      duration: '2 hours',
      participants: 0,
      status: 'scheduled',
    },
  ]

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900 mb-2">
          Schedule Management
        </h1>
        <p className="text-gray-600">Manage classes, workshops, and events.</p>
      </div>

      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
        <div className="bg-blue-50 p-6 rounded-lg">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-blue-600 text-sm">Total Events</p>
              <h3 className="text-2xl font-bold text-blue-700">
                {schedules.length}
              </h3>
            </div>
            <div className="rounded-full p-3 bg-blue-100">
              <FontAwesomeIcon icon={faCalendarAlt} className="text-blue-600" />
            </div>
          </div>
        </div>

        <div className="bg-green-50 p-6 rounded-lg">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-green-600 text-sm">Classes</p>
              <h3 className="text-2xl font-bold text-green-700">
                {schedules.filter((s) => s.type === 'class').length}
              </h3>
            </div>
            <div className="rounded-full p-3 bg-green-100">
              <FontAwesomeIcon
                icon={faChalkboardTeacher}
                className="text-green-600"
              />
            </div>
          </div>
        </div>

        <div className="bg-purple-50 p-6 rounded-lg">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-purple-600 text-sm">Workshops</p>
              <h3 className="text-2xl font-bold text-purple-700">
                {schedules.filter((s) => s.type === 'workshop').length}
              </h3>
            </div>
            <div className="rounded-full p-3 bg-purple-100">
              <FontAwesomeIcon
                icon={faGraduationCap}
                className="text-purple-600"
              />
            </div>
          </div>
        </div>

        <div className="bg-orange-50 p-6 rounded-lg">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-orange-600 text-sm">Total Participants</p>
              <h3 className="text-2xl font-bold text-orange-700">
                {schedules.reduce((acc, curr) => acc + curr.participants, 0)}
              </h3>
            </div>
            <div className="rounded-full p-3 bg-orange-100">
              <FontAwesomeIcon icon={faUsers} className="text-orange-600" />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Calendar View */}
        <div className="lg:col-span-1">
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
            <h2 className="text-lg font-semibold mb-4">Calendar</h2>
            <Calendar
              onChange={setSelectedDate}
              value={selectedDate}
              className="w-full border-0"
            />
          </div>
        </div>

        {/* Schedule List */}
        <div className="lg:col-span-2">
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
            <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-6">
              <div className="flex-1 flex gap-4">
                <div className="relative flex-1">
                  <input
                    type="text"
                    placeholder="Search schedules..."
                    className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                  />
                </div>
                <div className="relative">
                  <select
                    className="pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 appearance-none bg-white"
                    value={filterType}
                    onChange={(e) => setFilterType(e.target.value)}
                  >
                    <option value="all">All Types</option>
                    <option value="class">Classes</option>
                    <option value="workshop">Workshops</option>
                    <option value="event">Events</option>
                  </select>
                  <FontAwesomeIcon
                    icon={faFilter}
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                  />
                </div>
              </div>
              <button className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary-dark flex items-center justify-center">
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Add Schedule
              </button>
            </div>

            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Event
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date & Time
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Duration
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {schedules.map((schedule) => (
                    <tr key={schedule.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10 bg-primary-100 rounded-lg flex items-center justify-center">
                            <FontAwesomeIcon
                              icon={
                                schedule.type === 'class'
                                  ? faChalkboardTeacher
                                  : faGraduationCap
                              }
                              className="text-primary"
                            />
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {schedule.title}
                            </div>
                            <div className="text-sm text-gray-500">
                              {schedule.instructor}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="flex items-center">
                          <FontAwesomeIcon
                            icon={faCalendarAlt}
                            className="mr-2"
                          />
                          {schedule.date} at {schedule.time}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="flex items-center">
                          <FontAwesomeIcon icon={faClock} className="mr-2" />
                          {schedule.duration}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span
                          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            schedule.status === 'scheduled'
                              ? 'bg-green-100 text-green-800'
                              : 'bg-yellow-100 text-yellow-800'
                          }`}
                        >
                          {schedule.status}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <button className="text-blue-600 hover:text-blue-900 mr-3">
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                        <button className="text-red-600 hover:text-red-900">
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScheduleManagement
