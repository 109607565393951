import { PERMISSIONS } from './roles'
import { useRole } from './RoleContext'
import React, { createContext, useContext, useMemo } from 'react'

/**
 * Registry of feature flags with their required permissions
 * Each flag can require one or more permissions
 */
const featureRegistry = {
  // Content creation features
  CONTENT_CREATION: {
    requiredPermissions: [PERMISSIONS.CREATE_RESOURCES],
    description: 'Ability to create new educational content',
    isActive: true,
  },
  CONTENT_APPROVAL: {
    requiredPermissions: [PERMISSIONS.APPROVE_CONTENT],
    description: 'Ability to approve content created by others',
    isActive: true,
  },

  // Analytics features
  PERSONAL_ANALYTICS: {
    requiredPermissions: [PERMISSIONS.VIEW_PERSONAL_ANALYTICS],
    description: 'View personal learning analytics',
    isActive: true,
  },
  REGIONAL_ANALYTICS: {
    requiredPermissions: [PERMISSIONS.VIEW_REGIONAL_ANALYTICS],
    description: 'View analytics for a region',
    isActive: true,
  },
  GLOBAL_ANALYTICS: {
    requiredPermissions: [PERMISSIONS.VIEW_GLOBAL_ANALYTICS],
    description: 'View platform-wide analytics',
    isActive: true,
  },

  // User management features
  USER_MANAGEMENT: {
    requiredPermissions: [PERMISSIONS.MANAGE_USERS],
    description: 'Create, edit, and delete user accounts',
    isActive: true,
  },
  USER_PROFILE_VIEW: {
    requiredPermissions: [PERMISSIONS.VIEW_USER_PROFILES],
    description: 'View detailed user profiles',
    isActive: true,
  },

  // Ambassador features
  EVENT_CREATION: {
    requiredPermissions: [PERMISSIONS.CREATE_EVENTS],
    description: 'Create and schedule events',
    isActive: true,
  },
  MENTORING: {
    requiredPermissions: [PERMISSIONS.MENTOR_STUDENTS],
    description: 'Access mentoring tools',
    isActive: true,
  },
  PROMOTE_AMBASSADORS: {
    requiredPermissions: [PERMISSIONS.MANAGE_ROLES],
    description: 'Promote users to ambassador roles',
    isActive: true,
  },

  // Admin features
  SYSTEM_SETTINGS: {
    requiredPermissions: [PERMISSIONS.MANAGE_SYSTEM],
    description: 'Access system configuration',
    isActive: true,
  },
  SCHOOL_MANAGEMENT: {
    requiredPermissions: [PERMISSIONS.MANAGE_SCHOOLS],
    description: 'Manage school partnerships',
    isActive: true,
  },

  // Beta features (can be toggled on/off globally)
  ADVANCED_REPORTING: {
    requiredPermissions: [PERMISSIONS.VIEW_REPORTS],
    description: 'Access advanced reporting tools',
    isActive: false, // Disabled feature example
  },
  AI_TOOLS: {
    requiredPermissions: [PERMISSIONS.USE_AI_TOOLS],
    description: 'Access AI-powered educational tools',
    isActive: true,
  },

  // AI Features (Premium)
  AI_PERSONALIZED_TUTORING: {
    requiredPermissions: [PERMISSIONS.USE_AI_TOOLS],
    description: 'Access to AI-powered personalized tutoring assistant',
    isActive: true,
    isPremium: true,
  },
  AI_STUDY_PLANNER: {
    requiredPermissions: [PERMISSIONS.USE_AI_TOOLS],
    description: 'AI-generated personalized study plans and schedules',
    isActive: true,
    isPremium: true,
  },
  AI_EXAM_PREPARATION: {
    requiredPermissions: [PERMISSIONS.USE_AI_TOOLS],
    description:
      'AI-powered exam preparation with personalized practice questions',
    isActive: true,
    isPremium: true,
  },
  AI_FEEDBACK_SYSTEM: {
    requiredPermissions: [PERMISSIONS.USE_AI_TOOLS],
    description: 'Automated assessment feedback using AI',
    isActive: true,
    isPremium: true,
  },

  // Payment and Subscription Features
  PAYMENT_INTEGRATION: {
    requiredPermissions: [PERMISSIONS.USE_AI_TOOLS], // Using existing permission
    description: 'Access to payment methods including MTN and Orange Money',
    isActive: true,
  },
  PREMIUM_CONTENT_ACCESS: {
    requiredPermissions: [PERMISSIONS.VIEW_PREMIUM_RESOURCES],
    description: 'Access to premium educational content',
    isActive: true,
    isPremium: true,
  },
  COMPETITION_REGISTRATION: {
    requiredPermissions: [PERMISSIONS.ENROLL_COURSES], // Using existing permission
    description: 'Register for academic competitions and events',
    isActive: true,
  },
}

// Create context for feature flags
const FeatureFlagContext = createContext(null)

/**
 * Provider component for feature flags
 */
export const FeatureFlagProvider = ({ children }) => {
  const { hasPermission } = useRole()

  // Compute available features based on user permissions
  const availableFeatures = useMemo(() => {
    const features = {}

    Object.entries(featureRegistry).forEach(([featureKey, config]) => {
      // Check if feature is globally active
      if (!config.isActive) {
        features[featureKey] = false
        return
      }

      // Check if user has all required permissions
      const hasAllPermissions = Array.isArray(config.requiredPermissions)
        ? config.requiredPermissions.every((permission) =>
            hasPermission(permission)
          )
        : hasPermission(config.requiredPermissions)

      features[featureKey] = hasAllPermissions
    })

    return features
  }, [hasPermission])

  return (
    <FeatureFlagContext.Provider value={availableFeatures}>
      {children}
    </FeatureFlagContext.Provider>
  )
}

/**
 * Hook to check if features are available
 * @returns {Object} - Object with hasFeature method
 */
export const useFeatureFlags = () => {
  const features = useContext(FeatureFlagContext)

  if (features === null) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagProvider')
  }

  return {
    /**
     * Check if a feature is available to the current user
     * @param {string} featureKey - The feature key to check
     * @returns {boolean} - Whether the feature is available
     */
    hasFeature: (featureKey) => {
      return !!features[featureKey]
    },

    /**
     * Get all available features
     * @returns {Object} - Object with feature keys as properties and booleans as values
     */
    allFeatures: features,
  }
}

/**
 * Component that renders children only if a feature is available
 * @param {Object} props - Component props
 * @param {string} props.feature - The feature key to check
 * @param {React.ReactNode} props.children - Children to render if feature is available
 * @param {React.ReactNode} props.fallback - Optional fallback component to render if feature is not available
 */
export const FeatureFlag = ({ feature, children, fallback = null }) => {
  const { hasFeature } = useFeatureFlags()

  return hasFeature(feature) ? children : fallback
}

/**
 * Higher-order component that wraps a component with feature flag checking
 * @param {string} feature - The feature key to check
 * @param {React.Component} Component - Component to render if feature is available
 * @param {React.Component} FallbackComponent - Optional fallback component to render if feature is not available
 */
export const withFeatureFlag = (
  feature,
  Component,
  FallbackComponent = null
) => {
  return (props) => (
    <FeatureFlag
      feature={feature}
      fallback={FallbackComponent ? <FallbackComponent {...props} /> : null}
    >
      <Component {...props} />
    </FeatureFlag>
  )
}

export default featureRegistry
