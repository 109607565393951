import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMobileAlt,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'

/**
 * Mobile Money Payment Component for Cameroon
 * Supports MTN Mobile Money and Orange Money payment methods
 *
 * TODO:
 * - Implement actual API integration with MTN and Orange payment gateways
 * - Add proper error handling and retry mechanisms
 * - Implement proper security measures for payment processing
 * - Add comprehensive form validation
 */
const MobileMoneyPayment = ({
  amount,
  currency = 'XAF',
  onSuccess,
  onError,
  itemDescription,
  paymentType = 'subscription', // 'subscription', 'competition', 'purchase'
}) => {
  const [paymentMethod, setPaymentMethod] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const [error, setError] = useState('')
  const [otpCode, setOtpCode] = useState('')
  const [step, setStep] = useState(1) // 1: method selection, 2: phone entry, 3: OTP verification
  const [transactionId, setTransactionId] = useState('')

  // Format currency with thousand separators
  const formatCurrency = (value) => {
    return parseFloat(value).toLocaleString('fr-CM', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
    })
  }

  // Validate Cameroonian phone number
  const validatePhoneNumber = (number) => {
    // Basic validation for Cameroon - MTN: 67xxxxxxx, 65xxxxxxx, Orange: 69xxxxxxx, 65xxxxxxx
    const mtnRegex = /^(67|65)[0-9]{7}$/
    const orangeRegex = /^(69|65)[0-9]{7}$/

    if (paymentMethod === 'mtn' && !mtnRegex.test(number)) {
      return 'Please enter a valid MTN phone number (e.g., 67XXXXXXX)'
    }

    if (paymentMethod === 'orange' && !orangeRegex.test(number)) {
      return 'Please enter a valid Orange phone number (e.g., 69XXXXXXX)'
    }

    return ''
  }

  // Handle payment initiation
  const initiatePayment = async () => {
    // Validate phone number
    const phoneError = validatePhoneNumber(phoneNumber)
    if (phoneError) {
      setError(phoneError)
      return
    }

    setIsProcessing(true)
    setError('')

    try {
      // Mock API call - in production, this would be a real API call to the payment provider
      // TODO: Replace with actual API integration
      // For MTN: https://developers.mtn.com/products/collection/overview
      // For Orange: https://developer.orange.com/apis/om-webpay

      // Simulate API response
      setTimeout(() => {
        const mockTransactionId = `KETPAY-${Math.floor(
          Math.random() * 10000000
        )}`
        setTransactionId(mockTransactionId)
        setIsProcessing(false)
        setStep(3) // Move to OTP step
      }, 2000)
    } catch (err) {
      setIsProcessing(false)
      setError('Payment initiation failed. Please try again.')
      if (onError) {
        onError(err)
      }
      console.error('Payment initiation error:', err)
    }
  }

  // Handle OTP verification
  const verifyOTP = async () => {
    if (!otpCode || otpCode.length < 4) {
      setError('Please enter a valid OTP code')
      return
    }

    setIsProcessing(true)
    setError('')

    try {
      // Mock API call for OTP verification
      // TODO: Replace with actual OTP verification API
      setTimeout(() => {
        if (otpCode === '1234') {
          // Success case (for demo purposes, "1234" is our test OTP)
          setIsProcessing(false)

          // Call the success callback with transaction details
          if (onSuccess) {
            onSuccess({
              transactionId,
              paymentMethod,
              amount,
              currency,
              timestamp: new Date().toISOString(),
            })
          }
        } else {
          // Failed verification case
          setIsProcessing(false)
          setError('Invalid OTP code. Please check the code and try again.')
          if (onError) {
            onError(new Error('Invalid OTP code'))
          }
        }
      }, 2000)
    } catch (err) {
      setIsProcessing(false)
      setError('OTP verification failed. Please try again.')
      if (onError) {
        onError(err)
      }
      console.error('OTP verification error:', err)
    }
  }

  // Generate USSD instructions based on selected payment method
  const getUssdInstructions = () => {
    if (paymentMethod === 'mtn') {
      return '*126#'
    } else if (paymentMethod === 'orange') {
      return '*150#'
    }
    return ''
  }

  // Reset the form
  const resetForm = () => {
    setPaymentMethod('')
    setPhoneNumber('')
    setOtpCode('')
    setError('')
    setStep(1)
    setTransactionId('')
  }

  // Render different steps of the payment process
  const renderStep = () => {
    switch (step) {
      case 1: // Payment method selection
        return (
          <div className="space-y-4">
            <h3 className="font-semibold text-lg mb-4">
              Select Payment Method
            </h3>

            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <button
                type="button"
                onClick={() => {
                  setPaymentMethod('mtn')
                  setStep(2)
                }}
                className={`p-4 border rounded-lg flex items-center space-x-3 ${
                  paymentMethod === 'mtn'
                    ? 'border-yellow-500 bg-yellow-50'
                    : 'border-gray-200'
                }`}
                aria-label="Pay with MTN Mobile Money"
              >
                <div className="w-12 h-12 flex items-center justify-center bg-yellow-500 text-white rounded-full">
                  <FontAwesomeIcon icon={faMobileAlt} />
                </div>
                <div>
                  <p className="font-semibold">MTN Mobile Money</p>
                  <p className="text-sm text-gray-500">
                    Pay with your MTN account
                  </p>
                </div>
              </button>

              <button
                type="button"
                onClick={() => {
                  setPaymentMethod('orange')
                  setStep(2)
                }}
                className={`p-4 border rounded-lg flex items-center space-x-3 ${
                  paymentMethod === 'orange'
                    ? 'border-orange-500 bg-orange-50'
                    : 'border-gray-200'
                }`}
                aria-label="Pay with Orange Money"
              >
                <div className="w-12 h-12 flex items-center justify-center bg-orange-500 text-white rounded-full">
                  <FontAwesomeIcon icon={faMobileAlt} />
                </div>
                <div>
                  <p className="font-semibold">Orange Money</p>
                  <p className="text-sm text-gray-500">
                    Pay with your Orange account
                  </p>
                </div>
              </button>
            </div>
          </div>
        )

      case 2: // Phone number entry
        return (
          <div className="space-y-4">
            <div className="flex items-center mb-4">
              <button
                type="button"
                onClick={() => setStep(1)}
                className="text-blue-600 mr-2"
                aria-label="Go back to payment method selection"
              >
                &larr; Back
              </button>
              <h3 className="font-semibold text-lg">Enter Mobile Number</h3>
            </div>

            <div
              className={`p-4 rounded-lg ${
                paymentMethod === 'mtn'
                  ? 'bg-yellow-50 border border-yellow-200'
                  : 'bg-orange-50 border border-orange-200'
              }`}
            >
              <p className="text-sm font-medium mb-2">
                {paymentMethod === 'mtn' ? 'MTN Mobile Money' : 'Orange Money'}
              </p>
              <p className="text-sm text-gray-700 mb-2">
                You will pay{' '}
                <span className="font-bold">{formatCurrency(amount)}</span> for{' '}
                {itemDescription || 'your purchase'}
              </p>
            </div>

            <div className="space-y-4">
              <div>
                <label
                  htmlFor="phoneNumber"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Phone Number
                </label>
                <div className="flex">
                  <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                    +237
                  </span>
                  <input
                    type="tel"
                    id="phoneNumber"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder={
                      paymentMethod === 'mtn'
                        ? '67XXXXXXX or 65XXXXXXX'
                        : '69XXXXXXX or 65XXXXXXX'
                    }
                    className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    aria-describedby={error ? 'phone-error' : undefined}
                  />
                </div>
                {error && (
                  <p id="phone-error" className="mt-1 text-sm text-red-600">
                    {error}
                  </p>
                )}
              </div>

              <button
                type="button"
                onClick={initiatePayment}
                disabled={isProcessing || !phoneNumber}
                className={`w-full py-2 px-4 rounded-md text-white font-medium ${
                  isProcessing || !phoneNumber
                    ? 'bg-gray-400 cursor-not-allowed'
                    : paymentMethod === 'mtn'
                    ? 'bg-yellow-500 hover:bg-yellow-600'
                    : 'bg-orange-500 hover:bg-orange-600'
                }`}
                aria-busy={isProcessing}
              >
                {isProcessing ? (
                  <span className="flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="animate-spin mr-2"
                      aria-hidden="true"
                    />
                    Processing...
                  </span>
                ) : (
                  'Continue'
                )}
              </button>
            </div>
          </div>
        )

      case 3: // OTP verification
        return (
          <div className="space-y-4">
            <div className="mb-4">
              <h3 className="font-semibold text-lg">Verify Payment</h3>
              <p className="text-sm text-gray-600">
                Please check your phone for a payment prompt or enter the USSD
                code {getUssdInstructions()}, then enter the OTP code you
                receive.
              </p>
            </div>

            <div
              className={`p-4 rounded-lg ${
                paymentMethod === 'mtn'
                  ? 'bg-yellow-50 border border-yellow-200'
                  : 'bg-orange-50 border border-orange-200'
              }`}
            >
              <p className="text-sm font-medium">
                Transaction ID: {transactionId}
              </p>
              <p className="text-sm">
                Amount:{' '}
                <span className="font-bold">{formatCurrency(amount)}</span>
              </p>
              <p className="text-sm">Phone: +237 {phoneNumber}</p>
            </div>

            <div className="space-y-4">
              <div>
                <label
                  htmlFor="otpCode"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  OTP Code
                </label>
                <input
                  type="text"
                  id="otpCode"
                  value={otpCode}
                  onChange={(e) => setOtpCode(e.target.value)}
                  placeholder="Enter OTP code"
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  aria-describedby={error ? 'otp-error' : 'otp-help'}
                />
                <p id="otp-help" className="mt-1 text-xs text-gray-500">
                  For demo purposes, enter "1234" as the OTP code
                </p>
                {error && (
                  <p id="otp-error" className="mt-1 text-sm text-red-600">
                    {error}
                  </p>
                )}
              </div>

              <div className="flex space-x-3">
                <button
                  type="button"
                  onClick={resetForm}
                  className="flex-1 py-2 px-4 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  aria-label="Cancel payment"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={verifyOTP}
                  disabled={isProcessing || !otpCode}
                  className={`flex-1 py-2 px-4 rounded-md text-white font-medium ${
                    isProcessing || !otpCode
                      ? 'bg-gray-400 cursor-not-allowed'
                      : paymentMethod === 'mtn'
                      ? 'bg-yellow-500 hover:bg-yellow-600'
                      : 'bg-orange-500 hover:bg-orange-600'
                  }`}
                  aria-busy={isProcessing}
                >
                  {isProcessing ? (
                    <span className="flex items-center justify-center">
                      <FontAwesomeIcon
                        icon={faSpinner}
                        className="animate-spin mr-2"
                        aria-hidden="true"
                      />
                      Verifying...
                    </span>
                  ) : (
                    'Complete Payment'
                  )}
                </button>
              </div>
            </div>
          </div>
        )

      default:
        return null
    }
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-5">
      <div className="mb-4 pb-4 border-b">
        <h2 className="text-xl font-bold">Payment</h2>
        <p className="text-gray-600">
          {paymentType === 'subscription'
            ? 'Subscribe to Premium Content'
            : paymentType === 'competition'
            ? 'Register for Competition'
            : 'Purchase Item'}
        </p>
      </div>

      {renderStep()}
    </div>
  )
}

// Add PropTypes for type checking
MobileMoneyPayment.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  currency: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  itemDescription: PropTypes.string,
  paymentType: PropTypes.oneOf(['subscription', 'competition', 'purchase']),
}

export default MobileMoneyPayment
