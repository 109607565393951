import React from 'react'
import { Link } from 'react-router-dom'
import { useRole } from '../../../utils/permissions/RoleContext'
import { hasPermission } from '../../../utils/permissions/roles'

/**
 * Developer Tools Dashboard - A central place to access all RBAC and feature testing tools
 */
const DevTools = () => {
  const { userRole, userPermissions, userProfile } = useRole()

  // Define tools with their paths, descriptions, and required permissions
  const devTools = [
    {
      id: 'rbac-test',
      name: 'RBAC Test Page',
      path: '/rbac-test',
      description:
        'Test the RBAC system by checking route access for different user roles',
      icon: '🔒',
      requiresPermission: null, // Available to all authenticated users
    },
    {
      id: 'route-registry',
      name: 'Route Registry Viewer',
      path: '/route-registry',
      description:
        'View all routes in the centralized route registry and test access based on your current role',
      icon: '🛣️',
      requiresPermission: null, // Available to all authenticated users
    },
    {
      id: 'nav-registry',
      name: 'Navigation Registry Viewer',
      path: '/nav-registry',
      description:
        'View and test navigation items for different dashboards based on your current role',
      icon: '🧭',
      requiresPermission: null, // Available to all authenticated users
    },
    {
      id: 'feature-flags',
      name: 'Feature Flag Tester',
      path: '/feature-flags',
      description:
        'Test feature flags and see which features are available based on your role and permissions',
      icon: '🚩',
      requiresPermission: null, // Available to all authenticated users
    },
    {
      id: 'permissions-explorer',
      name: 'Permissions Explorer',
      path: '/permissions-explorer',
      description:
        'Explore all available permissions and their associated roles',
      icon: '🔑',
      requiresPermission: 'MANAGE_ROLES', // Only available to users with MANAGE_ROLES permission
    },
    {
      title: 'Payment System Test',
      description: 'Test Mobile Money Payment system with different user roles',
      path: '/testing/payment',
      highlight: true,
    },
    {
      title: 'Competition Registration Test',
      description: 'Test competition registration with different user roles',
      path: '/testing/competition',
      highlight: true,
    },
  ]

  // Filter tools based on user permissions
  const availableTools = devTools.filter(
    (tool) =>
      tool.requiresPermission === null ||
      (userPermissions &&
        hasPermission(userPermissions, tool.requiresPermission))
  )

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8">
        KET Academy Developer Tools
      </h1>

      {/* Current User Info */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4">Current User Information</h2>
        <p>
          <strong>Role:</strong> {userRole || 'Not set'}
        </p>
        <p>
          <strong>Status:</strong> {userProfile?.status || 'Not set'}
        </p>
        <p className="mt-4">
          <strong>Available Permissions:</strong>
        </p>
        {userPermissions && userPermissions.length > 0 ? (
          <div className="mt-2 flex flex-wrap gap-2">
            {userPermissions.map((permission) => (
              <span
                key={permission}
                className="inline-flex rounded-full bg-blue-100 px-3 py-1 text-xs font-semibold text-blue-800"
              >
                {permission}
              </span>
            ))}
          </div>
        ) : (
          <p className="text-gray-500 italic">No permissions available</p>
        )}
      </div>

      {/* Tools Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        {availableTools.map((tool) => (
          <div
            key={tool.id}
            className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
          >
            <div className="p-6">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-semibold">{tool.name}</h2>
                <span className="text-2xl">{tool.icon}</span>
              </div>
              <p className="text-gray-600 mb-6">{tool.description}</p>
              <Link
                to={tool.path}
                className="inline-flex items-center justify-center w-full rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
              >
                Access Tool
              </Link>
            </div>
          </div>
        ))}
      </div>

      {/* Additional Info */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-xl font-semibold mb-4">Developer Documentation</h2>
        <p className="mb-4">
          These tools are designed to help developers understand and test the
          RBAC system and feature flags implemented in the KET Academy platform.
        </p>
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg
                className="h-5 w-5 text-yellow-400"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                Note: Some tools may be restricted based on your user role and
                permissions. If you're developing features that require specific
                permissions, contact a system administrator for the appropriate
                role elevation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DevTools
