import React, { useState, useEffect } from 'react'
// TODO: Clean up these commented imports or remove them if not needed
//import BreadCrumb from '../../BreadCrumb'
//import './styles.css'
//import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faGraduationCap,
  faBook,
  faAward,
  faCheckCircle,
  faClock,
  faExternalLinkAlt,
  faVideo,
  faFile,
  faBookOpen,
  faSpinner,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import BreadCrumb from '../../BreadCrumb'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../utils/firebase/auth'
import {
  getRecentlyViewedResources,
  getUserSavedResources,
} from '../../../services/libraryService'
import { getAllResources } from '../../../services/resourceService'
import ResourceModel from '../../../models/Resource'

/**
 * Home Component for Student Dashboard
 * Displays summary of student activity and key information
 */
const Home = () => {
  const { authUser } = useAuth()
  const [recentlyViewed, setRecentlyViewed] = useState([])
  const [savedResources, setSavedResources] = useState([])
  const [recommendations, setRecommendations] = useState([])
  const [loading, setLoading] = useState({
    recent: true,
    saved: true,
    recommendations: true,
  })
  // TODO: Implement user profile functionality
  // Removing unused state variable
  // const [userProfile, setUserProfile] = useState(null)

  // Load user data
  useEffect(() => {
    const fetchUserData = async () => {
      if (!authUser) return

      try {
        // Fetch recently viewed resources
        setLoading((prev) => ({ ...prev, recent: true }))
        const recentRes = await getRecentlyViewedResources(authUser.uid, 4)
        setRecentlyViewed(recentRes)
        setLoading((prev) => ({ ...prev, recent: false }))

        // Fetch saved resources count
        setLoading((prev) => ({ ...prev, saved: true }))
        const saved = await getUserSavedResources(authUser.uid)
        setSavedResources(saved)
        setLoading((prev) => ({ ...prev, saved: false }))

        // Fetch recommendations based on profile
        // This is a placeholder implementation - in a real app, you'd have a recommendation algorithm
        setLoading((prev) => ({ ...prev, recommendations: true }))
        const filters = {
          limit: 4,
          // You could add filters based on user profile here
        }
        const allResources = await getAllResources(filters)
        setRecommendations(allResources.resources || [])
        setLoading((prev) => ({ ...prev, recommendations: false }))
      } catch (error) {
        console.error('Error fetching dashboard data:', error)
        setLoading({
          recent: false,
          saved: false,
          recommendations: false,
        })
      }
    }

    fetchUserData()
  }, [authUser]) // Added authUser as a dependency

  // Sample stats data - would come from API in a real implementation
  const stats = [
    {
      id: 1,
      title: 'Saved Resources',
      value: savedResources.length,
      icon: faBook,
      bgColor: 'bg-blue-100',
      textColor: 'text-blue-800',
    },
    {
      id: 2,
      title: 'Completed Resources',
      value: '0',
      icon: faCheckCircle,
      bgColor: 'bg-green-100',
      textColor: 'text-green-700',
    },
    {
      id: 3,
      title: 'Hours Spent',
      value: '0h',
      icon: faClock,
      bgColor: 'bg-yellow-100',
      textColor: 'text-yellow-700',
    },
    {
      id: 4,
      title: 'Total Points',
      value: '0',
      icon: faAward,
      bgColor: 'bg-purple-100',
      textColor: 'text-purple-700',
    },
  ]

  // Helper function to get the appropriate icon for a resource type
  const getResourceIcon = (resourceType) => {
    switch (resourceType) {
      case ResourceModel.RESOURCE_TYPES.READINGS:
        return faBook
      case ResourceModel.RESOURCE_TYPES.VIDEOS:
        return faVideo
      case ResourceModel.RESOURCE_TYPES.DOCUMENTS:
        return faFile
      case ResourceModel.RESOURCE_TYPES.EXAM_PREP:
        return faBookOpen
      default:
        return faFile
    }
  }

  // Format date for display (e.g., "2 days ago")
  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const now = new Date()
    const diffTime = Math.abs(now - date)
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))

    if (diffDays === 0) {
      const diffHours = Math.floor(diffTime / (1000 * 60 * 60))
      if (diffHours === 0) {
        const diffMinutes = Math.floor(diffTime / (1000 * 60))
        return `${diffMinutes} minute${diffMinutes !== 1 ? 's' : ''} ago`
      }
      return `${diffHours} hour${diffHours !== 1 ? 's' : ''} ago`
    } else if (diffDays === 1) {
      return 'Yesterday'
    } else if (diffDays < 7) {
      return `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`
    } else {
      return date.toLocaleDateString()
    }
  }

  // Get key updates - placeholder for now
  const updates = [
    {
      id: 1,
      title: 'Welcome to KET Academy!',
      description:
        'Start exploring educational resources tailored to your needs.',
      date: new Date(),
      link: '/dashboard/resources',
      icon: faGraduationCap,
      color: 'text-primary-500',
    },
    {
      id: 2,
      title: 'Complete Your Profile',
      description:
        'Fill in your educational details to get personalized recommendations.',
      date: new Date(Date.now() - 86400000), // 1 day ago
      link: '/dashboard/profile',
      icon: faUser,
      color: 'text-blue-500',
    },
  ]

  return (
    <div className="dashboard-home-container p-4 md:p-6">
      <BreadCrumb
        title="Student Dashboard"
        slugs={[
          { display: 'Home', to: '/dashboard', active: false },
          { display: 'Dashboard', to: '/dashboard/home', active: true },
        ]}
      />

      {/* Stats Overview */}
      <div className="stats-cards grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-3 md:gap-6 mb-6 md:mb-8">
        {stats.map((stat) => (
          <div
            key={stat.id}
            className={`stat-card p-4 md:p-6 rounded-lg shadow-sm ${stat.bgColor} ${stat.textColor}`}
          >
            <div className="flex justify-between items-center">
              <div>
                <h3 className="font-bold text-lg md:text-2xl">{stat.value}</h3>
                <p className="text-xs md:text-sm mt-1">{stat.title}</p>
              </div>
              <div className="rounded-full p-2 md:p-3 bg-white bg-opacity-60">
                <FontAwesomeIcon
                  icon={stat.icon}
                  className="text-md md:text-xl"
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 md:gap-6">
        {/* Left column - Recently Viewed */}
        <div className="col-span-1 lg:col-span-2">
          <div className="bg-white rounded-lg shadow-sm p-4 md:p-6 mb-4 md:mb-6">
            <div className="flex justify-between items-center mb-3 md:mb-4">
              <h2 className="font-bold text-base md:text-lg">
                Recently Viewed
              </h2>
              <Link
                to="/dashboard/resources"
                className="text-primary hover:underline text-xs md:text-sm flex items-center"
              >
                View All{' '}
                <FontAwesomeIcon icon={faChevronRight} className="ml-1" />
              </Link>
            </div>

            {loading.recent ? (
              <div className="flex justify-center items-center py-6 md:py-8">
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  className="text-primary text-md md:text-xl mr-2"
                />
                <span className="text-sm md:text-base">
                  Loading recent resources...
                </span>
              </div>
            ) : recentlyViewed.length > 0 ? (
              <div className="space-y-3 md:space-y-4">
                {recentlyViewed.map((resource) => (
                  <div
                    key={resource.id}
                    className="flex items-start p-3 md:p-4 border border-gray-100 rounded-lg hover:bg-gray-50 transition-colors"
                  >
                    <div className="p-2 md:p-3 rounded-lg bg-primary-100 text-primary mr-3 md:mr-4">
                      <FontAwesomeIcon
                        icon={getResourceIcon(resource.type)}
                        className="text-md md:text-xl"
                      />
                    </div>
                    <div className="flex-1 min-w-0">
                      <h3 className="font-medium text-base md:text-lg truncate">
                        {resource.title}
                      </h3>
                      <p className="text-xs md:text-sm text-gray-500 mb-1">
                        {resource.subject || 'General'}
                      </p>
                      <div className="flex flex-wrap gap-1 md:gap-2 mb-2">
                        {resource.edSystem && (
                          <span className="px-1.5 py-0.5 md:px-2 md:py-1 bg-blue-100 text-blue-800 rounded text-xs">
                            {resource.edSystem}
                          </span>
                        )}
                        {resource.academicLevel && (
                          <span className="px-1.5 py-0.5 md:px-2 md:py-1 bg-green-100 text-green-800 rounded text-xs">
                            {resource.academicLevel}
                          </span>
                        )}
                      </div>
                      <div className="flex flex-col xs:flex-row justify-between xs:items-center gap-1 md:gap-0">
                        <span className="text-xxs md:text-xs text-gray-500">
                          Viewed: {formatDate(resource.viewedAt)}
                        </span>
                        <Link
                          to={`/resource/${resource.id}`}
                          className="text-primary hover:underline text-xs md:text-sm"
                        >
                          View Resource
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center py-6 md:py-8 bg-gray-50 rounded-lg">
                <FontAwesomeIcon
                  icon={faBook}
                  className="text-3xl md:text-4xl text-gray-400 mb-2 md:mb-3"
                />
                <h3 className="font-semibold mb-1 md:mb-2 text-base md:text-lg">
                  No Recent Activity
                </h3>
                <p className="text-gray-600 mb-3 md:mb-4 text-sm md:text-base">
                  You haven't viewed any resources yet. Start exploring our
                  educational content!
                </p>
                <Link
                  to="/dashboard/resources"
                  className="inline-block px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors"
                >
                  Browse Resources
                </Link>
              </div>
            )}
          </div>

          {/* Recommendations section */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="font-bold text-lg">Recommended for You</h2>
              <Link
                to="/dashboard/resources"
                className="text-primary hover:underline text-sm flex items-center"
              >
                View All{' '}
                <FontAwesomeIcon icon={faChevronRight} className="ml-1" />
              </Link>
            </div>

            {loading.recommendations ? (
              <div className="flex justify-center items-center py-8">
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  className="text-primary text-xl mr-2"
                />
                <span>Loading recommendations...</span>
              </div>
            ) : recommendations.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {recommendations.slice(0, 4).map((resource) => (
                  <div
                    key={resource.id}
                    className="border border-gray-100 rounded-lg hover:shadow-md transition-shadow p-4"
                  >
                    <div className="flex items-center mb-3">
                      <div className="p-2 rounded-md bg-primary-100 text-primary mr-3">
                        <FontAwesomeIcon
                          icon={getResourceIcon(resource.type)}
                          className="text-lg"
                        />
                      </div>
                      <h4 className="font-medium text-base truncate">
                        {resource.title}
                      </h4>
                    </div>
                    <p className="text-sm text-gray-600 mb-3 line-clamp-2">
                      {resource.description || 'No description available.'}
                    </p>
                    <div className="flex justify-between items-center">
                      <div className="flex gap-1">
                        {resource.edSystem && (
                          <span className="px-2 py-1 bg-blue-100 text-blue-800 rounded text-xs">
                            {resource.edSystem}
                          </span>
                        )}
                      </div>
                      <Link
                        to={`/resource/${resource.id}`}
                        className="text-primary hover:underline text-xs flex items-center"
                      >
                        View{' '}
                        <FontAwesomeIcon
                          icon={faExternalLinkAlt}
                          className="ml-1 text-xs"
                        />
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center py-8 bg-gray-50 rounded-lg">
                <h3 className="font-semibold mb-2">No Recommendations Yet</h3>
                <p className="text-gray-600">
                  Complete your profile to get personalized recommendations
                  based on your educational system and level.
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Right column */}
        <div className="col-span-1">
          {/* Calendar widget */}
          <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
            <h2 className="font-bold text-lg mb-4">Calendar</h2>
            <Calendar className="w-full border-0" />
          </div>

          {/* Updates and Notifications */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            <h2 className="font-bold text-lg mb-4">Updates & Notifications</h2>
            <div className="space-y-4">
              {updates.map((update) => (
                <div
                  key={update.id}
                  className="p-4 border border-gray-100 rounded-lg hover:bg-gray-50 transition-colors"
                >
                  <div className="flex items-start">
                    <div
                      className={`p-2 rounded-full ${update.color} bg-opacity-10 mr-3`}
                    >
                      <FontAwesomeIcon
                        icon={update.icon}
                        className={update.color}
                      />
                    </div>
                    <div className="flex-1">
                      <h4 className="font-medium text-sm">{update.title}</h4>
                      <p className="text-xs text-gray-500 mt-1 mb-2">
                        {update.description}
                      </p>
                      <div className="flex justify-between items-center">
                        <span className="text-xs text-gray-400">
                          {formatDate(update.date)}
                        </span>
                        {update.link && (
                          <Link
                            to={update.link}
                            className="text-primary hover:underline text-xs"
                          >
                            Details
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
