import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
  orderBy,
  serverTimestamp,
  limit,
  startAfter,
} from 'firebase/firestore'
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage'
import { db, storage } from '../utils/firebase/config'
import { hasPermission, PERMISSIONS } from '../utils/permissions/roles'
import { getAuth } from 'firebase/auth'

// Reference to the resources collection in Firestore
const resourcesRef = collection(db, 'resources')

/**
 * Get all resources with optional filtering and pagination
 * @param {Object} filters - Object containing filter conditions
 * @param {number} [pageSize=10] - Number of resources to fetch per page
 * @param {Object} [lastDoc=null] - Last document from previous batch for pagination
 * @returns {Promise<Object>} - Promise resolving to { resources, lastDoc, totalCount }
 */
export const getAllResources = async (
  filters = {},
  pageSize = 10,
  lastDoc = null
) => {
  try {
    let resourceQuery = query(resourcesRef)

    // Apply filters if provided
    if (filters.type) {
      resourceQuery = query(resourceQuery, where('type', '==', filters.type))
    }

    if (filters.courseId) {
      resourceQuery = query(
        resourceQuery,
        where('courseId', '==', filters.courseId)
      )
    }

    if (filters.subject) {
      resourceQuery = query(
        resourceQuery,
        where('subject', '==', filters.subject)
      )
    }

    // Apply Cameroonian educational system filters
    if (filters.edSystem) {
      resourceQuery = query(
        resourceQuery,
        where('edSystem', '==', filters.edSystem)
      )
    }

    if (filters.secondaryType) {
      resourceQuery = query(
        resourceQuery,
        where('secondaryType', '==', filters.secondaryType)
      )
    }

    if (filters.academicLevel) {
      resourceQuery = query(
        resourceQuery,
        where('academicLevel', '==', filters.academicLevel)
      )
    }

    if (filters.examPreparation) {
      resourceQuery = query(
        resourceQuery,
        where('examPreparation', '==', filters.examPreparation)
      )
    }

    if (filters.language) {
      resourceQuery = query(
        resourceQuery,
        where('language', '==', filters.language)
      )
    }

    // Apply sorting
    resourceQuery = query(resourceQuery, orderBy('createdAt', 'desc'))

    // Get total count (for pagination info)
    const countQuery = resourceQuery
    const countSnapshot = await getDocs(countQuery)
    const totalCount = countSnapshot.size

    // Apply pagination
    if (pageSize > 0) {
      resourceQuery = query(resourceQuery, limit(pageSize))
    }

    // Apply cursor-based pagination if lastDoc is provided
    if (lastDoc) {
      resourceQuery = query(resourceQuery, startAfter(lastDoc))
    }

    // Execute the query
    const snapshot = await getDocs(resourceQuery)

    // Get the last document for next pagination
    const lastVisible =
      snapshot.docs.length > 0 ? snapshot.docs[snapshot.docs.length - 1] : null

    // Return resources and pagination info
    return {
      resources: snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })),
      lastDoc: lastVisible,
      totalCount,
    }
  } catch (error) {
    console.error('Error getting resources:', error)
    throw error
  }
}

/**
 * Get a single resource by its ID
 * @param {string} resourceId - ID of the resource to retrieve
 * @returns {Promise<Object>} - Promise resolving to resource object
 */
export const getResourceById = async (resourceId) => {
  try {
    const docRef = doc(db, 'resources', resourceId)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      return {
        id: docSnap.id,
        ...docSnap.data(),
      }
    } else {
      return null
    }
  } catch (error) {
    console.error('Error getting resource:', error)
    throw error
  }
}

/**
 * Search resources by title or description
 * @param {string} searchQuery - Text to search for
 * @returns {Promise<Array>} - Promise resolving to array of resource objects
 */
export const searchResources = async (searchQuery) => {
  try {
    // Basic implementation using client-side filtering
    // For production, consider using Algolia or Firebase Extensions for search
    const snapshot = await getDocs(resourcesRef)
    const allResources = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))

    // Filter resources that contain the search query in title or description
    return allResources.filter(
      (resource) =>
        resource.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        resource.description.toLowerCase().includes(searchQuery.toLowerCase())
    )
  } catch (error) {
    console.error('Error searching resources:', error)
    throw error
  }
}

/**
 * Upload a resource file or register a YouTube URL as a resource
 * @param {File|string} fileOrUrl - The file to upload or YouTube URL
 * @param {Object} metadata - Metadata for the resource
 * @param {Object} userProfile - User profile information
 * @returns {Promise<Object>} - Promise resolving to the created resource
 */
export const uploadResource = async (fileOrUrl, metadata, userProfile) => {
  try {
    // Check if user has permission to create resources
    if (
      !userProfile ||
      !hasPermission(userProfile.status, PERMISSIONS.CREATE_RESOURCES)
    ) {
      throw new Error('You do not have permission to upload resources')
    }

    // Get current user ID
    const auth = getAuth()
    const userId = auth.currentUser?.uid

    if (!userId) {
      throw new Error('You must be logged in to upload resources')
    }

    // Check if this is a YouTube URL
    const isYoutubeUrl =
      typeof fileOrUrl === 'string' &&
      (fileOrUrl.includes('youtube.com') || fileOrUrl.includes('youtu.be'))

    let resourceData = {
      ...metadata,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      uploadedBy: userId,
    }

    if (isYoutubeUrl) {
      // Handle YouTube URL resource
      resourceData = {
        ...resourceData,
        fileUrl: fileOrUrl,
        fileType: 'youtube',
        fileName: 'YouTube Video',
      }
    } else {
      // Handle file upload
      const file = fileOrUrl

      // Generate a unique file path in storage
      const fileExt = file.name.split('.').pop().toLowerCase()
      const filePath = `resources/${Date.now()}-${Math.random()
        .toString(36)
        .substring(2, 15)}.${fileExt}`
      const storageRef = ref(storage, filePath)

      // Upload the file to Firebase Storage
      const uploadResult = await uploadBytes(storageRef, file)

      // Get the download URL
      const downloadUrl = await getDownloadURL(uploadResult.ref)

      // Add file-specific properties
      resourceData = {
        ...resourceData,
        fileUrl: downloadUrl,
        filePath: filePath,
        fileType: fileExt,
        fileSize: file.size,
        fileName: file.name,
      }
    }

    // Set resource type based on file type if not specified
    if (!resourceData.type) {
      if (
        isYoutubeUrl ||
        resourceData.fileType === 'mp4' ||
        resourceData.fileType === 'mov' ||
        resourceData.fileType === 'avi'
      ) {
        resourceData.type = 'videos'
      } else if (resourceData.fileType === 'pdf') {
        resourceData.type = 'readings'
      } else {
        resourceData.type = 'docs'
      }
    }

    const docRef = await addDoc(resourcesRef, resourceData)

    return {
      id: docRef.id,
      ...resourceData,
    }
  } catch (error) {
    console.error('Error uploading resource:', error)
    throw error
  }
}

/**
 * Update a resource's metadata
 * @param {string} resourceId - ID of the resource to update
 * @param {Object} updates - Object containing fields to update
 * @returns {Promise<void>}
 */
export const updateResource = async (resourceId, updates) => {
  try {
    const docRef = doc(db, 'resources', resourceId)
    await updateDoc(docRef, {
      ...updates,
      updatedAt: serverTimestamp(),
    })
  } catch (error) {
    console.error('Error updating resource:', error)
    throw error
  }
}

/**
 * Delete a resource and its file from storage
 * @param {string} resourceId - ID of the resource to delete
 * @returns {Promise<void>}
 */
export const deleteResource = async (resourceId) => {
  try {
    // Get the resource to find its file path
    const resource = await getResourceById(resourceId)

    if (resource && resource.filePath) {
      // Delete file from storage
      const storageRef = ref(storage, resource.filePath)
      await deleteObject(storageRef)
    }

    // Delete the document from Firestore
    const docRef = doc(db, 'resources', resourceId)
    await deleteDoc(docRef)
  } catch (error) {
    console.error('Error deleting resource:', error)
    throw error
  }
}

/**
 * Share a resource with specific users or make it public
 * @param {string} resourceId - ID of the resource to share
 * @param {Array<string>} userIds - Array of user IDs to share with (empty for public)
 * @param {boolean} isPublic - Whether the resource should be public
 * @returns {Promise<void>}
 */
export const shareResource = async (
  resourceId,
  userIds = [],
  isPublic = false
) => {
  try {
    const docRef = doc(db, 'resources', resourceId)
    await updateDoc(docRef, {
      sharedWith: userIds,
      isPublic: isPublic,
      updatedAt: serverTimestamp(),
    })
  } catch (error) {
    console.error('Error sharing resource:', error)
    throw error
  }
}
