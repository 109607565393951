import {
  faArrowUp,
  faChevronRight,
  faEnvelope,
  faPaperPlane,
  faPhone,
  faCommentDots,
  faRobot,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
//import logo from '../../assets/images/academy/logo.png'
//import logo1 from '../../assets/images/academy/KET_LOGO_BLACK.png'
//import logo2 from '../../assets/images/academy/KET_LOGO_FULL_COLOR.png'
import logo3 from '../../assets/images/academy/KET_LOGO_WHITE.png'
import './styles.scss'

import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faWhatsapp,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import AIChat from '../AIChat'

const Footer = () => {
  const [toTop, setToTop] = useState(false)
  const [isChatOpen, setIsChatOpen] = useState(false)

  useEffect(() => {
    const onScroll = (e) => setToTop(window.scrollY > 200)
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <footer className="footer__home">
      <div className="footer-top">
        <div className="container">
          <div>
            <div className="footer-contact">
              <Link to="/">
                <div className="logo mb-4">
                  <img src={logo3} alt="KET Academy logo" />
                </div>
              </Link>
              <p>
                OD Chappelle d'Obili
                <br />
                Yaounde, CMR8687
                <br />
                Cameroon
              </p>
              <br />
              <p className="my-2">
                <FontAwesomeIcon icon={faPhone} className="w-5 h-5 mr-1" /> +237
                679563542
              </p>
              <p className="text-ellipsis leading-none">
                <FontAwesomeIcon icon={faEnvelope} className="w-5 h-5 mr-1" />{' '}
                ketacademy1@gmail.com
              </p>
            </div>

            <div className="footer-links">
              <h4 className="padding" style={{ textAlign: 'left' }}>
                Useful Links
              </h4>
              <ul>
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link to="/tos">Terms of Service</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div className="footer-links">
              <h4 className="padding" style={{ textAlign: 'left' }}>
                Interact
              </h4>
              <ul>
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link to="/gallery">Gallery</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link
                    to="https://forms.gle/CTDykKTHRbW2srCn9"
                    target="_blank"
                  >
                    Feedback
                  </Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link to="https://forms.gle/qHakFzhD2PmugHyw7">
                    Share your story
                  </Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link to="/reports">Our Yearly Report</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link to="/team">Our team</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link to="/donate">Donate</Link>
                </li>
              </ul>
            </div>

            <div className="footer-newsletter" style={{ textAlign: 'center' }}>
              <h4 className="padding" style={{ textAlign: 'center' }}>
                Join Our Newsletter
              </h4>
              <p
                className="small-text text-light"
                style={{ fontSize: 'small' }}
              >
                For exclusive content, academic tips, and early access to new
                courses. Perfect for students, parents, and educators seeking
                insights and opportunities.
              </p>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  alert('Thank you.')
                }}
                style={{ display: 'inline-block' }}
              >
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  style={{ textAlign: 'center' }}
                />
                <button type="submit">
                  <FontAwesomeIcon icon={faPaperPlane} />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="footer-bottom">
        <div className="container">
          <div className="copyright">
            &copy;
            <strong>
              <span>KET Academy</span>,
            </strong>
            {new Date().getFullYear()}. All Rights Reserved
          </div>

          <div className="social-links">
            <Link
              to="https://chat.whatsapp.com/B5PiHxXsDZeI5LfgZiJaFn"
              target="_blank"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
            </Link>

            <Link to="https://www.youtube.com/c/KETAcademy" target="_blank">
              <FontAwesomeIcon icon={faYoutube} />
            </Link>

            <Link
              to="https://whatsapp.com/channel/0029VaEWjjk3GJOv9piD000z"
              target="_blank"
            >
              <FontAwesomeIcon icon={faCommentDots} />
            </Link>

            <Link
              to="https://www.facebook.com/KET-AcademyOrientation-107170228312584/"
              target="_blank"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </Link>

            <Link
              to="https://www.linkedin.com/company/77005269"
              target="_blank"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </Link>

            <Link
              to="https://www.facebook.com/groups/2008168369341398/"
              target="_blank"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </Link>

            <Link
              to="https://www.instagram.com/ket.foundation/"
              target="_blank"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </Link>

            <Link to="https://www.twitter.com/AcademyKet" target="_blank">
              <FontAwesomeIcon icon={faTwitter} />
            </Link>
          </div>
        </div>
      </div>
      <Link
        to="#"
        className={`back-to-top ${!toTop ? 'hide' : ''}`}
        onClick={() => window.scroll({ top: 0, behavior: 'smooth' })}
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </Link>
      <Link
        onClick={(e) => {
          e.preventDefault()
          setIsChatOpen(!isChatOpen)
        }}
        to="#"
        className="fixed bottom-20 right-4 bg-white text-primary hover:bg-primary hover:text-white w-12 h-12 rounded-full flex items-center justify-center shadow-lg hover:shadow-xl transition-all duration-300 z-50 border-2 border-primary group"
        title="KET Academy AI Assistant"
      >
        <FontAwesomeIcon
          icon={faRobot}
          className="text-xl transform group-hover:scale-110 transition-transform duration-300"
        />
      </Link>
      <AIChat isOpen={isChatOpen} onClose={() => setIsChatOpen(false)} />
      <div className="report-issue-button">
        <a
          href="mailto:ketacademy1@gmail.com?subject=Report%20Issue"
          title="Report an Issue"
        >
          Report Issue
        </a>
      </div>
    </footer>
  )
}

export default Footer
