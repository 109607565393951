import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUsers,
  faBook,
  faCog,
  faChartLine,
  faGraduationCap,
  faSchool,
} from '@fortawesome/free-solid-svg-icons'

/**
 * Admin Dashboard Home Component
 */
const AdminDashboardHome = () => {
  // Sample stats data - in a real app, this would come from your backend
  const stats = [
    {
      id: 'total-users',
      title: 'Total Users',
      value: '0',
      icon: faUsers,
      bgColor: 'bg-blue-50',
      textColor: 'text-blue-600',
    },
    {
      id: 'total-students',
      title: 'Active Students',
      value: '0',
      icon: faGraduationCap,
      bgColor: 'bg-green-50',
      textColor: 'text-green-600',
    },
    {
      id: 'total-schools',
      title: 'Partner Schools',
      value: '0',
      icon: faSchool,
      bgColor: 'bg-purple-50',
      textColor: 'text-purple-600',
    },
    {
      id: 'total-resources',
      title: 'Total Resources',
      value: '0',
      icon: faBook,
      bgColor: 'bg-orange-50',
      textColor: 'text-orange-600',
    },
  ]

  return (
    <div className="admin-home p-6 max-w-7xl mx-auto">
      {/* Breadcrumb */}
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900 mb-2">
          Admin Dashboard
        </h1>
        <p className="text-gray-600">
          Welcome to the Admin Dashboard. This is where you can manage the KET
          Academy platform.
        </p>
      </div>

      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {stats.map((stat) => (
          <div
            key={stat.id}
            className={`${stat.bgColor} ${stat.textColor} p-6 rounded-lg shadow-sm`}
          >
            <div className="flex justify-between items-center">
              <div>
                <h3 className="font-bold text-2xl">{stat.value}</h3>
                <p className="text-sm mt-1">{stat.title}</p>
              </div>
              <div className="rounded-full p-3 bg-white bg-opacity-60">
                <FontAwesomeIcon icon={stat.icon} className="text-xl" />
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Quick Actions */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
          <div className="flex items-center mb-4">
            <div className="rounded-full p-3 bg-blue-50 text-blue-600 mr-4">
              <FontAwesomeIcon icon={faUsers} />
            </div>
            <h3 className="text-lg font-semibold">User Management</h3>
          </div>
          <p className="text-gray-600 mb-4">
            Manage user accounts, roles, and permissions across the platform.
          </p>
          <button className="text-blue-600 hover:text-blue-700 font-medium">
            Manage Users →
          </button>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
          <div className="flex items-center mb-4">
            <div className="rounded-full p-3 bg-green-50 text-green-600 mr-4">
              <FontAwesomeIcon icon={faBook} />
            </div>
            <h3 className="text-lg font-semibold">Content Management</h3>
          </div>
          <p className="text-gray-600 mb-4">
            Create and manage educational content, resources, and courses.
          </p>
          <button className="text-green-600 hover:text-green-700 font-medium">
            Manage Content →
          </button>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
          <div className="flex items-center mb-4">
            <div className="rounded-full p-3 bg-purple-50 text-purple-600 mr-4">
              <FontAwesomeIcon icon={faCog} />
            </div>
            <h3 className="text-lg font-semibold">System Settings</h3>
          </div>
          <p className="text-gray-600 mb-4">
            Configure platform settings, integrations, and system preferences.
          </p>
          <button className="text-purple-600 hover:text-purple-700 font-medium">
            Configure Settings →
          </button>
        </div>
      </div>

      {/* Analytics Overview */}
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100 mb-8">
        <div className="flex items-center mb-6">
          <div className="rounded-full p-3 bg-orange-50 text-orange-600 mr-4">
            <FontAwesomeIcon icon={faChartLine} />
          </div>
          <h3 className="text-lg font-semibold">Platform Analytics</h3>
        </div>
        <div className="text-center py-12 text-gray-500">
          <p>Analytics dashboard coming soon</p>
        </div>
      </div>
    </div>
  )
}

/**
 * Approvals Dashboard Component
 */
const ApprovalsDashboard = () => {
  // Sample pending approvals data - in a real app, this would come from your backend
  const pendingApprovals = []

  return (
    <div className="approvals-dashboard p-6 max-w-7xl mx-auto">
      {/* Breadcrumb */}
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900 mb-2">
          Approvals Dashboard
        </h1>
        <p className="text-gray-600">
          Review and approve content, user registrations, and other items that
          require approval.
        </p>
      </div>

      {/* Approvals List */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-100">
        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-lg font-semibold">Pending Approvals</h2>
            <div className="flex gap-2">
              <button className="px-4 py-2 text-sm bg-gray-50 text-gray-600 rounded hover:bg-gray-100">
                Filter
              </button>
              <button className="px-4 py-2 text-sm bg-primary text-white rounded hover:bg-primary-dark">
                Refresh
              </button>
            </div>
          </div>

          {pendingApprovals.length === 0 ? (
            <div className="text-center py-12 text-gray-500">
              <div className="mb-4">
                <FontAwesomeIcon
                  icon={faUsers}
                  className="text-4xl text-gray-300"
                />
              </div>
              <p className="mb-2">No pending approvals at this time</p>
              <p className="text-sm">
                New requests will appear here when they arrive
              </p>
            </div>
          ) : (
            <div className="divide-y divide-gray-100">
              {pendingApprovals.map((approval) => (
                <div key={approval.id} className="py-4">
                  {/* Approval item content would go here */}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export { AdminDashboardHome, ApprovalsDashboard }
