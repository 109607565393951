import React from 'react'
import { Link } from 'react-router-dom'
import routeRegistry from '../../../utils/permissions/RouteRegistry'
import { useRole } from '../../../utils/permissions/RoleContext'
import { CORE_ROLES } from '../../../utils/permissions/roles'

/**
 * Component to display and test all routes in the route registry
 */
const RouteRegistryTest = () => {
  const { userRole, userProfile } = useRole()

  // Group routes by section
  const studentRoutes = Object.entries(routeRegistry).filter(([key]) =>
    key.startsWith('dashboard/')
  )
  const ambassadorRoutes = Object.entries(routeRegistry).filter(([key]) =>
    key.startsWith('ambassador/')
  )
  const adminRoutes = Object.entries(routeRegistry).filter(([key]) =>
    key.startsWith('admin/')
  )

  // Check if user has access to a route
  const hasAccess = (route) => {
    return route.roles.includes(userRole)
  }

  // Get role badge color
  const getRoleBadgeColor = (role) => {
    switch (role) {
      case CORE_ROLES.STUDENT:
        return 'bg-blue-100 text-blue-800'
      case CORE_ROLES.STUDENT_AMBASSADOR:
        return 'bg-green-100 text-green-800'
      case CORE_ROLES.AMBASSADOR:
        return 'bg-purple-100 text-purple-800'
      case CORE_ROLES.CORE_MEMBER:
        return 'bg-red-100 text-red-800'
      default:
        return 'bg-gray-100 text-gray-800'
    }
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8">
        Route Registry Test Page
      </h1>

      {/* Current User Info */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4">Current User Information</h2>
        <p>
          <strong>Role:</strong> {userRole || 'Not set'}
        </p>
        <p>
          <strong>Status:</strong> {userProfile?.status || 'Not set'}
        </p>
      </div>

      {/* Student Dashboard Routes */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4">Student Dashboard Routes</h2>
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Route
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Path
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Title
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Required Roles
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Access
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {studentRoutes.map(([key, route]) => (
                <tr
                  key={key}
                  className={hasAccess(route) ? 'bg-green-50' : 'bg-red-50'}
                >
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {key}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {route.path}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {route.title}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    <div className="flex flex-wrap gap-1">
                      {route.roles.map((role) => (
                        <span
                          key={role}
                          className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${getRoleBadgeColor(
                            role
                          )}`}
                        >
                          {role}
                        </span>
                      ))}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm">
                    {hasAccess(route) ? (
                      <Link
                        to={route.path}
                        className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800 hover:bg-green-200"
                      >
                        Accessible (Click to Visit)
                      </Link>
                    ) : (
                      <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                        No Access
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Ambassador Dashboard Routes */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4">
          Student Ambassador Dashboard Routes
        </h2>
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Route
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Path
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Title
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Required Roles
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Access
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {ambassadorRoutes.map(([key, route]) => (
                <tr
                  key={key}
                  className={hasAccess(route) ? 'bg-green-50' : 'bg-red-50'}
                >
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {key}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {route.path}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {route.title}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    <div className="flex flex-wrap gap-1">
                      {route.roles.map((role) => (
                        <span
                          key={role}
                          className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${getRoleBadgeColor(
                            role
                          )}`}
                        >
                          {role}
                        </span>
                      ))}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm">
                    {hasAccess(route) ? (
                      <Link
                        to={route.path}
                        className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800 hover:bg-green-200"
                      >
                        Accessible (Click to Visit)
                      </Link>
                    ) : (
                      <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                        No Access
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Admin Dashboard Routes */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4">Admin Dashboard Routes</h2>
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Route
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Path
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Title
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Required Roles
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Access
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {adminRoutes.map(([key, route]) => (
                <tr
                  key={key}
                  className={hasAccess(route) ? 'bg-green-50' : 'bg-red-50'}
                >
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {key}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {route.path}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {route.title}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    <div className="flex flex-wrap gap-1">
                      {route.roles.map((role) => (
                        <span
                          key={role}
                          className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${getRoleBadgeColor(
                            role
                          )}`}
                        >
                          {role}
                        </span>
                      ))}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm">
                    {hasAccess(route) ? (
                      <Link
                        to={route.path}
                        className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800 hover:bg-green-200"
                      >
                        Accessible (Click to Visit)
                      </Link>
                    ) : (
                      <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                        No Access
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Navigation Links */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-xl font-semibold mb-4">Other Testing Pages</h2>
        <div className="flex flex-wrap gap-4">
          <Link
            to="/rbac-test"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
          >
            RBAC Test Page
          </Link>
          <Link
            to="/feature-flags"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
          >
            Feature Flags Test
          </Link>
        </div>
      </div>
    </div>
  )
}

export default RouteRegistryTest
