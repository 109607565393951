import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBook,
  faVideo,
  faFile,
  faSearch,
  faBookOpen,
  faCloudDownloadAlt,
  faTrash,
  faExternalLinkAlt,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import {
  getUserSavedResources,
  removeFromLibrary,
} from '../../../services/libraryService'
import { useAuth } from '../../../utils/firebase/auth'
import { toast } from 'react-toastify'
import ResourceModel from '../../../models/Resource'
import { Link } from 'react-router-dom'

/**
 * Library Component for Student Dashboard
 * Displays learning resources saved by the student
 */
const Library = () => {
  const { authUser } = useAuth()
  const [savedResources, setSavedResources] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')
  const [activeFilter, setActiveFilter] = useState('all')
  const [educationalSystemFilter, setEducationalSystemFilter] = useState('')
  const [academicLevelFilter, setAcademicLevelFilter] = useState('')
  const [filteredResources, setFilteredResources] = useState([])

  useEffect(() => {
    const fetchSavedResources = async () => {
      if (!authUser) return

      try {
        setLoading(true)
        const resources = await getUserSavedResources(authUser.uid)
        setSavedResources(resources)
        setFilteredResources(resources)
      } catch (error) {
        console.error('Error fetching saved resources:', error)
        toast.error('Failed to load your saved resources')
      } finally {
        setLoading(false)
      }
    }

    fetchSavedResources()
  }, [authUser])

  useEffect(() => {
    // Apply filters whenever filter criteria change
    let filtered = [...savedResources]

    // Filter by type
    if (activeFilter !== 'all') {
      filtered = filtered.filter((resource) => resource.type === activeFilter)
    }

    // Filter by educational system if selected
    if (educationalSystemFilter) {
      filtered = filtered.filter(
        (resource) =>
          resource.edSystem === educationalSystemFilter ||
          resource.edSystem === 'Both'
      )
    }

    // Filter by academic level if selected
    if (academicLevelFilter) {
      filtered = filtered.filter(
        (resource) => resource.academicLevel === academicLevelFilter
      )
    }

    // Apply search query
    if (searchQuery.trim()) {
      const query = searchQuery.toLowerCase()
      filtered = filtered.filter(
        (resource) =>
          resource.title.toLowerCase().includes(query) ||
          resource.description.toLowerCase().includes(query) ||
          resource.subject.toLowerCase().includes(query)
      )
    }

    setFilteredResources(filtered)
  }, [
    savedResources,
    activeFilter,
    searchQuery,
    educationalSystemFilter,
    academicLevelFilter,
  ])

  const handleRemoveFromLibrary = async (resourceId) => {
    try {
      await removeFromLibrary(authUser.uid, resourceId)
      setSavedResources((prev) =>
        prev.filter((resource) => resource.id !== resourceId)
      )
      toast.success('Resource removed from your library')
    } catch (error) {
      console.error('Error removing resource from library:', error)
      toast.error('Failed to remove resource from your library')
    }
  }

  // Get unique educational systems from the resources
  const educationalSystems = [
    { value: '', label: 'All Systems' },
    { value: 'Anglophone', label: 'Anglophone (English)' },
    { value: 'Francophone', label: 'Francophone (French)' },
    { value: 'Both', label: 'Both Systems' },
  ]

  // Get unique academic levels from the resources
  const getAcademicLevels = () => {
    const levels = new Set(
      savedResources.map((resource) => resource.academicLevel).filter(Boolean)
    )

    const options = [{ value: '', label: 'All Levels' }]

    // Add the user's levels from their resources
    Array.from(levels).forEach((level) => {
      options.push({ value: level, label: level })
    })

    return options
  }

  const getResourceIcon = (type) => {
    switch (type) {
      case ResourceModel.RESOURCE_TYPES.READINGS:
        return faBook
      case ResourceModel.RESOURCE_TYPES.VIDEOS:
        return faVideo
      default:
        return faFile
    }
  }

  return (
    <div className="library-page p-6">
      <div className="mb-6">
        <h1 className="text-2xl font-bold mb-2">My Library</h1>
        <p className="text-gray-600">
          Browse your saved learning resources and materials.
        </p>
      </div>

      {/* Search and Filters */}
      <div className="mb-6">
        <div className="flex flex-col md:flex-row gap-4 mb-4">
          {/* Search box */}
          <div className="flex-1">
            <div className="relative">
              <input
                type="text"
                placeholder="Search your saved resources..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full p-3 pl-10 border rounded-lg"
              />
              <FontAwesomeIcon
                icon={faSearch}
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              />
            </div>
          </div>

          {/* Educational system filter */}
          <div className="w-full md:w-48">
            <select
              value={educationalSystemFilter}
              onChange={(e) => setEducationalSystemFilter(e.target.value)}
              className="w-full p-3 border rounded-lg appearance-none bg-white"
            >
              {educationalSystems.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          {/* Academic level filter */}
          <div className="w-full md:w-48">
            <select
              value={academicLevelFilter}
              onChange={(e) => setAcademicLevelFilter(e.target.value)}
              className="w-full p-3 border rounded-lg appearance-none bg-white"
            >
              {getAcademicLevels().map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Resource type filter pills */}
        <div className="flex flex-wrap gap-2">
          <button
            className={`py-2 px-4 rounded-full ${
              activeFilter === 'all'
                ? 'bg-primary text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
            onClick={() => setActiveFilter('all')}
          >
            All Resources
          </button>
          <button
            className={`py-2 px-4 rounded-full flex items-center ${
              activeFilter === ResourceModel.RESOURCE_TYPES.READINGS
                ? 'bg-primary text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
            onClick={() =>
              setActiveFilter(ResourceModel.RESOURCE_TYPES.READINGS)
            }
          >
            <FontAwesomeIcon icon={faBook} className="mr-2" />
            Readings
          </button>
          <button
            className={`py-2 px-4 rounded-full flex items-center ${
              activeFilter === ResourceModel.RESOURCE_TYPES.VIDEOS
                ? 'bg-primary text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
            onClick={() => setActiveFilter(ResourceModel.RESOURCE_TYPES.VIDEOS)}
          >
            <FontAwesomeIcon icon={faVideo} className="mr-2" />
            Videos
          </button>
          <button
            className={`py-2 px-4 rounded-full flex items-center ${
              activeFilter === ResourceModel.RESOURCE_TYPES.DOCUMENTS
                ? 'bg-primary text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
            onClick={() =>
              setActiveFilter(ResourceModel.RESOURCE_TYPES.DOCUMENTS)
            }
          >
            <FontAwesomeIcon icon={faFile} className="mr-2" />
            Documents
          </button>
          <button
            className={`py-2 px-4 rounded-full flex items-center ${
              activeFilter === ResourceModel.RESOURCE_TYPES.EXAM_PREP
                ? 'bg-primary text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
            onClick={() =>
              setActiveFilter(ResourceModel.RESOURCE_TYPES.EXAM_PREP)
            }
          >
            <FontAwesomeIcon icon={faBookOpen} className="mr-2" />
            Exam Prep
          </button>
        </div>
      </div>

      {/* Library Content */}
      <div className="library-content">
        {loading ? (
          <div className="flex justify-center items-center py-12">
            <FontAwesomeIcon
              icon={faSpinner}
              spin
              className="text-primary text-2xl mr-2"
            />
            <span>Loading your resources...</span>
          </div>
        ) : filteredResources.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredResources.map((resource) => (
              <div
                key={resource.id}
                className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
              >
                <div className="p-4 border-b">
                  <div className="flex items-start">
                    <div className="p-2 bg-primary-100 text-primary rounded-lg mr-3">
                      <FontAwesomeIcon
                        icon={getResourceIcon(resource.type)}
                        className="text-xl"
                      />
                    </div>
                    <div className="flex-1">
                      <h3 className="font-semibold text-lg mb-1 truncate">
                        {resource.title}
                      </h3>
                      <p className="text-gray-500 text-sm mb-2 truncate">
                        {resource.subject || 'General'}
                      </p>
                      <div className="flex flex-wrap gap-2">
                        {resource.edSystem && (
                          <span className="px-2 py-1 bg-blue-100 text-blue-800 rounded text-xs">
                            {resource.edSystem}
                          </span>
                        )}
                        {resource.academicLevel && (
                          <span className="px-2 py-1 bg-green-100 text-green-800 rounded text-xs">
                            {resource.academicLevel}
                          </span>
                        )}
                        {resource.examPreparation &&
                          resource.examPreparation.length > 0 && (
                            <span className="px-2 py-1 bg-purple-100 text-purple-800 rounded text-xs">
                              {resource.examPreparation[0]}
                              {resource.examPreparation.length > 1 && '+'}
                            </span>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-3 bg-gray-50 flex justify-between items-center">
                  <Link
                    to={`/resource/${resource.id}`}
                    className="text-primary hover:underline text-sm flex items-center"
                  >
                    <FontAwesomeIcon
                      icon={faExternalLinkAlt}
                      className="mr-1"
                    />
                    View Details
                  </Link>
                  <div className="flex gap-2">
                    {resource.fileUrl && (
                      <a
                        href={resource.fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="p-2 text-blue-600 hover:bg-blue-50 rounded"
                        title="Download"
                      >
                        <FontAwesomeIcon icon={faCloudDownloadAlt} />
                      </a>
                    )}
                    <button
                      onClick={() => handleRemoveFromLibrary(resource.id)}
                      className="p-2 text-red-600 hover:bg-red-50 rounded"
                      title="Remove from Library"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-12 bg-gray-50 rounded-lg">
            <FontAwesomeIcon
              icon={faBookOpen}
              className="text-4xl text-gray-400 mb-3"
            />
            <h3 className="text-xl font-semibold mb-2">
              Your Library is Empty
            </h3>
            <p className="text-gray-600 mb-4">
              Save resources from the Resources section to access them quickly
              here.
            </p>
            <Link
              to="/dashboard/resources"
              className="inline-block px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors"
            >
              Browse Resources
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default Library
