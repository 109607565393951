import React, { useState, useEffect } from 'react'
import { useAuth } from '../../../utils/firebase/auth'
import { useRole } from '../../../utils/permissions/RoleContext'
import RbacTestUtil from '../../../utils/testing/RbacTestUtil'
import { CORE_ROLES } from '../../../utils/permissions/roles'
import { doc, getDoc } from 'firebase/firestore'
import { firestore } from '../../../utils/firebase'

/**
 * A test page for the Role-Based Access Control system
 * This component allows testing different user roles and their access to protected routes
 */
const RbacTestPage = () => {
  const { authUser } = useAuth()
  const { userRole, userProfile } = useRole()

  const [isRunningTest, setIsRunningTest] = useState(false)
  const [testResults, setTestResults] = useState(null)
  const [testMode, setTestMode] = useState('manual') // 'manual' or 'comprehensive'
  const [selectedRole, setSelectedRole] = useState(CORE_ROLES.STUDENT)
  const [selectedRoute, setSelectedRoute] = useState('/dashboard/home')
  const [expectedOutcome, setExpectedOutcome] = useState('allow')
  const [manualTestResult, setManualTestResult] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [debugInfo, setDebugInfo] = useState(null)

  // Fetch user profile directly from Firestore to ensure we have the latest data
  useEffect(() => {
    const fetchUserProfile = async () => {
      if (!authUser) return

      try {
        const userProfileRef = doc(firestore, 'userProfiles', authUser.uid)
        const userProfileDoc = await getDoc(userProfileRef)

        if (userProfileDoc.exists()) {
          setDebugInfo({
            profileExists: true,
            profileData: userProfileDoc.data(),
            timestamp: new Date().toISOString(),
          })
        } else {
          setDebugInfo({
            profileExists: false,
            message: 'User profile document does not exist',
            timestamp: new Date().toISOString(),
          })
        }
      } catch (error) {
        setDebugInfo({
          error: error.message,
          timestamp: new Date().toISOString(),
        })
      }
    }

    fetchUserProfile()
  }, [authUser])

  // Function to run a manual test for a specific role and route
  const runManualTest = async () => {
    if (!authUser) {
      alert('Please sign in first to run tests')
      return
    }

    setIsRunningTest(true)
    setManualTestResult(null)
    setErrorMessage(null)

    try {
      // Initialize test for current user
      const initialized = await RbacTestUtil.initializeTest(authUser.uid)
      if (!initialized) {
        setManualTestResult({
          success: false,
          message: 'Failed to initialize test',
        })
        setIsRunningTest(false)
        return
      }

      // Set user role for testing
      const roleSet = await RbacTestUtil.setUserRole(authUser.uid, selectedRole)
      if (!roleSet) {
        setManualTestResult({
          success: false,
          message: `Failed to set role to ${selectedRole}`,
        })
        setIsRunningTest(false)
        return
      }

      // Run the test
      alert(
        `The page will refresh to update your role. After refresh, we'll test access to ${selectedRoute}. Click OK to continue.`
      )

      // Store test parameters in sessionStorage to retrieve after page reload
      sessionStorage.setItem(
        'rbacTest',
        JSON.stringify({
          mode: 'manual',
          role: selectedRole,
          route: selectedRoute,
          expectedOutcome,
          testInProgress: true,
          timestamp: new Date().toISOString(),
        })
      )

      // Reload page to update role context
      window.location.reload()
    } catch (error) {
      setErrorMessage(error.message)
      setManualTestResult({
        success: false,
        message: `Error: ${error.message}`,
      })
      setIsRunningTest(false)
    }
  }

  // Function to run comprehensive tests for all roles and routes
  const runComprehensiveTest = async () => {
    if (!authUser) {
      alert('Please sign in first to run tests')
      return
    }

    setIsRunningTest(true)
    setTestResults(null)
    setErrorMessage(null)

    try {
      // Clear any existing comprehensive test state
      sessionStorage.removeItem('rbacComprehensiveTest')

      // First make sure we can initialize the test
      const initialized = await RbacTestUtil.initializeTest(authUser.uid)
      if (!initialized) {
        setTestResults({
          summary: {
            success: false,
            message:
              'Failed to initialize test. Check Firebase permissions and user profile.',
          },
          results: {},
        })
        setIsRunningTest(false)
        return
      }

      alert(
        'Comprehensive test will run through all role and route combinations. This will cause several page reloads. Click OK to continue.'
      )

      // Start the comprehensive test (which uses our new approach)
      const results = await RbacTestUtil.runComprehensiveTest(authUser.uid)
      setTestResults(results)
    } catch (error) {
      setErrorMessage(error.message)
      setTestResults({
        summary: {
          success: false,
          message: `Error: ${error.message}`,
        },
        results: {},
      })
    } finally {
      setIsRunningTest(false)
    }
  }

  // Check if we need to continue a test after page reload
  useEffect(() => {
    const checkPendingTests = async () => {
      try {
        // First check if we have a navigation test in progress
        const navigationResult = await RbacTestUtil.checkNavigationResult()
        if (navigationResult) {
          // Get the comprehensive test state if it exists
          const comprehensiveTestData = sessionStorage.getItem(
            'rbacComprehensiveTest'
          )

          if (comprehensiveTestData) {
            // We're in the middle of a comprehensive test
            const testState = JSON.parse(comprehensiveTestData)

            // Add the navigation result to the test results
            if (testState.roleInTest && testState.routeInTest) {
              const resultKey = `${testState.roleInTest}_${testState.routeInTest}`
              testState.results[resultKey] = navigationResult

              // Clear the current test info
              delete testState.roleInTest
              delete testState.routeInTest
              delete testState.expectedOutcome

              // Save updated state
              sessionStorage.setItem(
                'rbacComprehensiveTest',
                JSON.stringify(testState)
              )

              // Continue the comprehensive test
              const results = await RbacTestUtil.runComprehensiveTest(
                authUser.uid
              )
              setTestResults(results)
              return
            }
          } else {
            // This was a manual test navigation
            setManualTestResult(navigationResult)
            // Restore original role for manual test
            await RbacTestUtil.restoreOriginalRole(authUser.uid)
            return
          }
        }

        // Check for comprehensive test in progress
        const comprehensiveTestData = sessionStorage.getItem(
          'rbacComprehensiveTest'
        )
        if (comprehensiveTestData) {
          // Continue the comprehensive test
          const results = await RbacTestUtil.runComprehensiveTest(authUser.uid)
          setTestResults(results)
          return
        }

        // Otherwise check for regular test continuation
        const testData = sessionStorage.getItem('rbacTest')
        if (!testData) return

        const parsedData = JSON.parse(testData)
        if (!parsedData.testInProgress) return

        // Clear the test data to avoid infinite loop
        sessionStorage.removeItem('rbacTest')

        if (parsedData.mode === 'manual') {
          // For manual test, navigation now happens in two steps:
          // 1. First we set the role and reload the page (already done)
          // 2. Then we perform the navigation test (do that now)
          const result = await RbacTestUtil.testRouteAccess(
            parsedData.route,
            parsedData.role,
            parsedData.expectedOutcome
          )

          if (result.pending) {
            // The navigation has been scheduled, we'll get the results on the next reload
            return
          }

          // If we have an immediate result, restore role and show result
          await RbacTestUtil.restoreOriginalRole(authUser.uid)
          setManualTestResult(result)
        } else if (parsedData.mode === 'comprehensive') {
          // Start comprehensive test (which will handle multiple navigations)
          const results = await RbacTestUtil.runComprehensiveTest(authUser.uid)
          setTestResults(results)
        }
      } catch (error) {
        setErrorMessage(`Error during test continuation: ${error.message}`)
      }
    }

    if (authUser) {
      checkPendingTests()
    }
  }, [authUser])

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8">
        Role-Based Access Control Test
      </h1>

      {/* Current User Info */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4">Current User Information</h2>
        {authUser ? (
          <div>
            <p>
              <strong>User:</strong> {authUser.displayName || authUser.email}
            </p>
            <p>
              <strong>UID:</strong> {authUser.uid}
            </p>
            <p>
              <strong>Current Role:</strong> {userRole || 'Loading...'}
            </p>
            <p>
              <strong>Status:</strong> {userProfile?.status || 'Loading...'}
            </p>
          </div>
        ) : (
          <p>Please sign in to run tests</p>
        )}

        {/* Debug Information */}
        {debugInfo && (
          <div className="mt-4 p-3 bg-gray-100 rounded text-xs font-mono">
            <details>
              <summary className="cursor-pointer font-semibold">
                Debug Information
              </summary>
              <pre className="mt-2 overflow-auto">
                {JSON.stringify(debugInfo, null, 2)}
              </pre>
            </details>
          </div>
        )}
      </div>

      {/* Error Messages */}
      {errorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          <h3 className="font-semibold">Error</h3>
          <p>{errorMessage}</p>
        </div>
      )}

      {/* Test Mode Selection */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4">Test Mode</h2>
        <div className="flex space-x-4 mb-4">
          <button
            className={`px-4 py-2 rounded ${
              testMode === 'manual'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => setTestMode('manual')}
          >
            Manual Test
          </button>
          <button
            className={`px-4 py-2 rounded ${
              testMode === 'comprehensive'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => setTestMode('comprehensive')}
          >
            Comprehensive Test
          </button>
        </div>
      </div>

      {/* Manual Test Parameters */}
      {testMode === 'manual' && (
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="text-xl font-semibold mb-4">Manual Test Parameters</h2>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Test Role:</label>
            <select
              className="w-full px-3 py-2 border rounded"
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
            >
              <option value={CORE_ROLES.STUDENT}>Student</option>
              <option value={CORE_ROLES.STUDENT_AMBASSADOR}>
                Student Ambassador
              </option>
              <option value={CORE_ROLES.AMBASSADOR}>Ambassador</option>
              <option value={CORE_ROLES.CORE_MEMBER}>Core Member</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Test Route:</label>
            <select
              className="w-full px-3 py-2 border rounded"
              value={selectedRoute}
              onChange={(e) => setSelectedRoute(e.target.value)}
            >
              <option value="/dashboard/home">Student Dashboard</option>
              <option value="/ambassador/dashboard/home">
                Student Ambassador Dashboard
              </option>
              <option value="/ambassador/dashboard/analytics">
                Ambassador Analytics
              </option>
              <option value="/admin/dashboard">Admin Dashboard</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 mb-2">
              Expected Outcome:
            </label>
            <select
              className="w-full px-3 py-2 border rounded"
              value={expectedOutcome}
              onChange={(e) => setExpectedOutcome(e.target.value)}
            >
              <option value="allow">Allow Access</option>
              <option value="deny">Deny Access (Redirect)</option>
            </select>
          </div>

          <button
            className="w-full bg-blue-600 text-white font-medium py-2 px-4 rounded hover:bg-blue-700 disabled:bg-blue-300"
            onClick={runManualTest}
            disabled={isRunningTest || !authUser}
          >
            {isRunningTest ? 'Running Test...' : 'Run Manual Test'}
          </button>

          {/* Manual Test Results */}
          {manualTestResult && (
            <div
              className={`mt-4 p-4 rounded ${
                manualTestResult.success ? 'bg-green-100' : 'bg-red-100'
              }`}
            >
              <p
                className={
                  manualTestResult.success ? 'text-green-800' : 'text-red-800'
                }
              >
                {manualTestResult.message}
              </p>

              {/* Additional Test Result Details */}
              {(manualTestResult.startLocation ||
                manualTestResult.endLocation) && (
                <div className="mt-2 text-sm">
                  <p>
                    <strong>Start Location:</strong>{' '}
                    {manualTestResult.startLocation}
                  </p>
                  <p>
                    <strong>End Location:</strong>{' '}
                    {manualTestResult.endLocation}
                  </p>
                </div>
              )}

              {/* If there's an error object, show it */}
              {manualTestResult.error && (
                <details className="mt-2">
                  <summary className="cursor-pointer text-sm font-semibold">
                    Error Details
                  </summary>
                  <pre className="mt-1 p-2 bg-red-50 text-xs overflow-auto">
                    {manualTestResult.error}
                  </pre>
                </details>
              )}
            </div>
          )}
        </div>
      )}

      {/* Comprehensive Test Button */}
      {testMode === 'comprehensive' && (
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="text-xl font-semibold mb-4">Comprehensive Test</h2>
          <p className="mb-4">
            This will test all combinations of roles and routes to verify the
            complete role-based access control system. The test may take some
            time to complete as it requires multiple role changes and page
            reloads.
          </p>

          <button
            className="w-full bg-blue-600 text-white font-medium py-2 px-4 rounded hover:bg-blue-700 disabled:bg-blue-300"
            onClick={runComprehensiveTest}
            disabled={isRunningTest || !authUser}
          >
            {isRunningTest ? 'Running Tests...' : 'Run Comprehensive Test'}
          </button>
        </div>
      )}

      {/* Comprehensive Test Results */}
      {testResults && (
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold mb-4">Test Results</h2>

          <div
            className={`p-4 rounded mb-6 ${
              testResults.summary.success ? 'bg-green-100' : 'bg-red-100'
            }`}
          >
            <p
              className={
                testResults.summary.success ? 'text-green-800' : 'text-red-800'
              }
            >
              <strong>Summary:</strong> {testResults.summary.message}
            </p>

            {testResults.summary.passed !== undefined && (
              <p className="mt-1 text-sm">
                Passed {testResults.summary.passed} of{' '}
                {testResults.summary.total} tests
              </p>
            )}
          </div>

          {/* Only display results if there are any */}
          {testResults.results &&
            Object.keys(testResults.results).length > 0 && (
              <div className="divide-y divide-gray-200">
                {Object.entries(testResults.results).map(([key, result]) => (
                  <div key={key} className="py-3">
                    <div className="flex items-center">
                      <span
                        className={`inline-block w-6 h-6 rounded-full mr-3 ${
                          result.success ? 'bg-green-500' : 'bg-red-500'
                        }`}
                      ></span>
                      <p
                        className={
                          result.success ? 'text-green-800' : 'text-red-800'
                        }
                      >
                        {result.message}
                      </p>
                    </div>

                    {/* Additional Test Result Details */}
                    {(result.startLocation || result.endLocation) && (
                      <div className="mt-2 ml-9 text-sm text-gray-600">
                        {result.startLocation && (
                          <p>Start: {result.startLocation}</p>
                        )}
                        {result.endLocation && <p>End: {result.endLocation}</p>}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
        </div>
      )}
    </div>
  )
}

export default RbacTestPage
