import { CORE_ROLES } from './roles'
import {
  faBookAtlas,
  faCalendarDays,
  faChalkboardTeacher,
  faFolder,
  faGraduationCap,
  faSchool,
  faTableColumns,
  faUserCircle,
  faUserCheck,
  faCommentDots,
  faChartLine,
  faBullhorn,
  faChalkboardUser,
  faUserGroup,
  faUserGear,
  faCog,
  faCrown,
  faTrophy,
} from '@fortawesome/free-solid-svg-icons'

/**
 * Centralized registry of all navigation items with their role-based access requirements
 * This makes it easy to generate navigation menus based on user roles
 */
const navigationRegistry = {
  // Student Dashboard Navigation
  student: [
    {
      text: 'Dashboard',
      link: '/dashboard/home',
      faIcon: faTableColumns,
      roles: [
        CORE_ROLES.STUDENT,
        CORE_ROLES.STUDENT_AMBASSADOR,
        CORE_ROLES.AMBASSADOR,
        CORE_ROLES.CORE_MEMBER,
      ],
      description: 'Overview of your learning progress',
    },
    {
      text: 'Resources',
      link: '/dashboard/resources',
      faIcon: faBookAtlas,
      roles: [
        CORE_ROLES.STUDENT,
        CORE_ROLES.STUDENT_AMBASSADOR,
        CORE_ROLES.AMBASSADOR,
        CORE_ROLES.CORE_MEMBER,
      ],
      description: 'Access learning materials',
    },
    {
      text: 'Library',
      link: '/dashboard/library',
      faIcon: faFolder,
      roles: [
        CORE_ROLES.STUDENT,
        CORE_ROLES.STUDENT_AMBASSADOR,
        CORE_ROLES.AMBASSADOR,
        CORE_ROLES.CORE_MEMBER,
      ],
      description: 'Browse educational content',
    },
    {
      text: 'Chats',
      link: '/dashboard/chats',
      faIcon: faCommentDots,
      roles: [
        CORE_ROLES.STUDENT,
        CORE_ROLES.STUDENT_AMBASSADOR,
        CORE_ROLES.AMBASSADOR,
        CORE_ROLES.CORE_MEMBER,
      ],
      description: 'Message with mentors and peers',
    },
    {
      text: 'Profile',
      link: '/dashboard/profile',
      faIcon: faUserCircle,
      roles: [
        CORE_ROLES.STUDENT,
        CORE_ROLES.STUDENT_AMBASSADOR,
        CORE_ROLES.AMBASSADOR,
        CORE_ROLES.CORE_MEMBER,
      ],
      description: 'Manage your personal information',
    },
    {
      text: 'Premium Content',
      link: '/dashboard/subscriptions',
      faIcon: faCrown,
      roles: [
        CORE_ROLES.STUDENT,
        CORE_ROLES.STUDENT_AMBASSADOR,
        CORE_ROLES.AMBASSADOR,
        CORE_ROLES.CORE_MEMBER,
      ],
      description: 'Manage your premium subscriptions',
    },
    {
      text: 'Competitions',
      link: '/dashboard/competitions',
      faIcon: faTrophy,
      roles: [
        CORE_ROLES.STUDENT,
        CORE_ROLES.STUDENT_AMBASSADOR,
        CORE_ROLES.AMBASSADOR,
        CORE_ROLES.CORE_MEMBER,
      ],
      description: 'Register for academic competitions',
    },
  ],

  // Student Ambassador Dashboard Navigation
  ambassador: [
    {
      text: 'Dashboard',
      link: '/ambassador/dashboard/home',
      faIcon: faTableColumns,
      roles: [
        CORE_ROLES.STUDENT_AMBASSADOR,
        CORE_ROLES.AMBASSADOR,
        CORE_ROLES.CORE_MEMBER,
      ],
      description: 'Overview of your ambassador activities and student stats',
    },
    {
      text: 'Resources',
      link: '/ambassador/dashboard/resources',
      faIcon: faBookAtlas,
      roles: [
        CORE_ROLES.STUDENT_AMBASSADOR,
        CORE_ROLES.AMBASSADOR,
        CORE_ROLES.CORE_MEMBER,
      ],
      description: 'Access and manage learning materials',
      subItems: [
        {
          text: 'Browse Resources',
          link: '/ambassador/dashboard/resources',
          roles: [
            CORE_ROLES.STUDENT_AMBASSADOR,
            CORE_ROLES.AMBASSADOR,
            CORE_ROLES.CORE_MEMBER,
          ],
        },
        {
          text: 'Contribute Resources',
          link: '/ambassador/dashboard/resources/contribute',
          roles: [
            CORE_ROLES.STUDENT_AMBASSADOR,
            CORE_ROLES.AMBASSADOR,
            CORE_ROLES.CORE_MEMBER,
          ],
        },
        {
          text: 'Library',
          link: '/ambassador/dashboard/library',
          roles: [
            CORE_ROLES.STUDENT_AMBASSADOR,
            CORE_ROLES.AMBASSADOR,
            CORE_ROLES.CORE_MEMBER,
          ],
        },
      ],
    },
    {
      text: 'Events',
      link: '/ambassador/dashboard/events',
      faIcon: faCalendarDays,
      roles: [
        CORE_ROLES.STUDENT_AMBASSADOR,
        CORE_ROLES.AMBASSADOR,
        CORE_ROLES.CORE_MEMBER,
      ],
      description: 'Manage and schedule local learning events',
      subItems: [
        {
          text: 'My Events',
          link: '/ambassador/dashboard/events',
          roles: [
            CORE_ROLES.STUDENT_AMBASSADOR,
            CORE_ROLES.AMBASSADOR,
            CORE_ROLES.CORE_MEMBER,
          ],
        },
        {
          text: 'Create Event',
          link: '/ambassador/dashboard/events/create',
          roles: [
            CORE_ROLES.STUDENT_AMBASSADOR,
            CORE_ROLES.AMBASSADOR,
            CORE_ROLES.CORE_MEMBER,
          ],
        },
      ],
    },
    {
      text: 'Mentoring',
      link: '/ambassador/dashboard/mentoring',
      faIcon: faChalkboardUser,
      roles: [
        CORE_ROLES.STUDENT_AMBASSADOR,
        CORE_ROLES.AMBASSADOR,
        CORE_ROLES.CORE_MEMBER,
      ],
      description: 'Provide peer support to assigned students',
    },
    {
      text: 'Analytics',
      link: '/ambassador/dashboard/analytics',
      faIcon: faChartLine,
      roles: [
        CORE_ROLES.STUDENT_AMBASSADOR,
        CORE_ROLES.AMBASSADOR,
        CORE_ROLES.CORE_MEMBER,
      ],
      description: 'View statistics for your region and students',
    },
    {
      text: 'Promotions',
      link: '/ambassador/dashboard/promotions',
      faIcon: faBullhorn,
      roles: [
        CORE_ROLES.STUDENT_AMBASSADOR,
        CORE_ROLES.AMBASSADOR,
        CORE_ROLES.CORE_MEMBER,
      ],
      description: 'Access promotional materials and campaigns',
    },
    {
      text: 'Student Group',
      link: '/ambassador/dashboard/students',
      faIcon: faUserGroup,
      roles: [
        CORE_ROLES.STUDENT_AMBASSADOR,
        CORE_ROLES.AMBASSADOR,
        CORE_ROLES.CORE_MEMBER,
      ],
      description: 'Manage your assigned student groups',
    },
    {
      text: 'Chats',
      link: '/ambassador/dashboard/chats',
      faIcon: faCommentDots,
      roles: [
        CORE_ROLES.STUDENT_AMBASSADOR,
        CORE_ROLES.AMBASSADOR,
        CORE_ROLES.CORE_MEMBER,
      ],
      description: 'Message your students and other ambassadors',
    },
    {
      text: 'Profile',
      link: '/ambassador/dashboard/profile',
      faIcon: faUserGear,
      roles: [
        CORE_ROLES.STUDENT_AMBASSADOR,
        CORE_ROLES.AMBASSADOR,
        CORE_ROLES.CORE_MEMBER,
      ],
      description: 'Manage your personal and ambassador information',
      subItems: [
        {
          text: 'Personal Info',
          link: '/ambassador/dashboard/profile/personal',
          roles: [
            CORE_ROLES.STUDENT_AMBASSADOR,
            CORE_ROLES.AMBASSADOR,
            CORE_ROLES.CORE_MEMBER,
          ],
        },
        {
          text: 'Ambassador Settings',
          link: '/ambassador/dashboard/profile/ambassador',
          roles: [
            CORE_ROLES.STUDENT_AMBASSADOR,
            CORE_ROLES.AMBASSADOR,
            CORE_ROLES.CORE_MEMBER,
          ],
        },
        {
          text: 'Privacy & Security',
          link: '/ambassador/dashboard/profile/security',
          roles: [
            CORE_ROLES.STUDENT_AMBASSADOR,
            CORE_ROLES.AMBASSADOR,
            CORE_ROLES.CORE_MEMBER,
          ],
        },
      ],
    },
  ],

  // Admin Dashboard Navigation
  admin: [
    {
      text: 'Dashboard',
      link: '/admin/dashboard/',
      faIcon: faTableColumns,
      roles: [CORE_ROLES.AMBASSADOR, CORE_ROLES.CORE_MEMBER],
      description: 'Administrative overview',
    },
    {
      text: 'My Profile',
      link: '/admin/dashboard/profile',
      faIcon: faUserCircle,
      roles: [CORE_ROLES.AMBASSADOR, CORE_ROLES.CORE_MEMBER],
      description: 'Manage your profile',
    },
    {
      text: 'Courses',
      link: '/admin/dashboard/courses',
      faIcon: faBookAtlas,
      roles: [CORE_ROLES.AMBASSADOR, CORE_ROLES.CORE_MEMBER],
      description: 'Manage course content',
    },
    {
      text: 'Resources',
      link: '/admin/dashboard/resources',
      faIcon: faFolder,
      roles: [CORE_ROLES.AMBASSADOR, CORE_ROLES.CORE_MEMBER],
      description: 'Manage learning resources',
    },
    {
      text: 'Status Approvals',
      link: '/admin/dashboard/approvals',
      faIcon: faUserCheck,
      roles: [CORE_ROLES.AMBASSADOR, CORE_ROLES.CORE_MEMBER],
      description: 'Approve status change requests',
    },
    {
      text: 'Schools',
      link: '/admin/dashboard/schools',
      faIcon: faSchool,
      roles: [CORE_ROLES.AMBASSADOR, CORE_ROLES.CORE_MEMBER],
      description: 'Manage partner schools',
    },
    {
      text: 'Schedule',
      link: '/admin/dashboard/schedule',
      faIcon: faCalendarDays,
      roles: [CORE_ROLES.AMBASSADOR, CORE_ROLES.CORE_MEMBER],
      description: 'Manage program schedule',
    },
    {
      text: 'Academy',
      link: '/admin/dashboard/academy',
      faIcon: faSchool,
      roles: [CORE_ROLES.AMBASSADOR, CORE_ROLES.CORE_MEMBER],
      description: 'Academy settings',
    },
    {
      text: 'Students',
      link: '/admin/dashboard/students',
      faIcon: faGraduationCap,
      roles: [CORE_ROLES.AMBASSADOR, CORE_ROLES.CORE_MEMBER],
      description: 'Manage students',
    },
    {
      text: 'Ambassadors',
      link: '/admin/dashboard/instructors',
      faIcon: faChalkboardTeacher,
      roles: [CORE_ROLES.AMBASSADOR, CORE_ROLES.CORE_MEMBER],
      description: 'Manage ambassadors',
    },
    // Core member only items
    {
      text: 'System Settings',
      link: '/admin/dashboard/system',
      faIcon: faCog,
      roles: [CORE_ROLES.CORE_MEMBER],
      description: 'Advanced system configuration',
    },
  ],
}

/**
 * Filter navigation items based on user role
 * @param {string} section - The navigation section ('student', 'ambassador', or 'admin')
 * @param {string} userRole - The user's role
 * @returns {Array} - Filtered navigation items
 */
export const getNavigationItems = (section, userRole) => {
  if (!navigationRegistry[section] || !userRole) {
    return []
  }

  return navigationRegistry[section].filter((item) => {
    // Check if user role is in the allowed roles for this item
    return item.roles.includes(userRole)
  })
}

/**
 * Get navigation items based on user role
 * @param {string} userRole - Current user's role
 * @returns {Array} Navigation items for the appropriate dashboard type
 */
export const getNavigationForRole = (userRole) => {
  if (!userRole) return []

  // Determine which dashboard type to use based on role
  let dashboardType = 'student'

  if (userRole === 'CORE_MEMBER') {
    dashboardType = 'admin'
  } else if (userRole === 'AMBASSADOR' || userRole === 'STUDENT_AMBASSADOR') {
    dashboardType = 'ambassador'
  }

  return getNavigationItems(dashboardType, userRole)
}

const NavigationRegistry = {
  getNavigationItems,
  getNavigationForRole,
}

export default NavigationRegistry
