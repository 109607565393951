import { firestore } from '../firebase'
import { doc, setDoc, getDoc } from 'firebase/firestore'
import { CORE_ROLES } from '../permissions/roles'

/**
 * Utility class for testing the Role-Based Access Control system
 * This allows simulating different user roles and testing protection routes
 */
class RbacTestUtil {
  constructor() {
    this.testUserProfile = null
    this.originalStatus = null
    this.testComplete = false
    this.testResults = {
      student: { success: false, message: '' },
      studentAmbassador: { success: false, message: '' },
      ambassador: { success: false, message: '' },
      coreMember: { success: false, message: '' },
    }
  }

  /**
   * Check if we have proper Firestore permissions
   * @returns {Promise<boolean>} Whether we have proper permissions
   */
  async checkFirestorePermissions(userId) {
    if (!userId) return false

    try {
      // Try to read from userProfiles collection
      const userProfileRef = doc(firestore, 'userProfiles', userId)
      await getDoc(userProfileRef)

      // Try to write to userProfiles collection (will verify write permissions)
      const testData = {
        testField: 'test',
        timestamp: new Date().toISOString(),
      }
      await setDoc(userProfileRef, testData, { merge: true })

      console.log('Firestore permissions check passed')
      return true
    } catch (error) {
      console.error('Firestore permissions check failed:', error)
      return false
    }
  }

  /**
   * Initialize the test for the current authenticated user
   * Stores the original role to restore later
   * @returns {Promise<boolean>} Whether initialization was successful
   */
  async initializeTest(userId) {
    if (!userId) {
      console.error('No user is authenticated. Please sign in first.')
      return false
    }

    try {
      // First verify we have proper Firestore permissions
      const hasPermissions = await this.checkFirestorePermissions(userId)
      if (!hasPermissions) {
        console.error('Insufficient Firestore permissions for testing')
        return false
      }

      // Get current user profile - directly reference the document by userId
      // This assumes userProfiles collection has documents with IDs matching user UIDs
      const userProfileRef = doc(firestore, 'userProfiles', userId)
      const userProfileSnapshot = await getDoc(userProfileRef)

      if (!userProfileSnapshot.exists()) {
        console.error('User profile not found. Creating a default profile.')

        // Create a default profile for testing if none exists
        const defaultProfile = {
          uid: userId,
          status: 'STUDENT',
          createdAt: new Date().toISOString(),
        }

        await setDoc(userProfileRef, defaultProfile)
        this.testUserProfile = defaultProfile
        this.originalStatus = 'STUDENT'
      } else {
        // Store original profile data
        this.testUserProfile = userProfileSnapshot.data()
        this.originalStatus = this.testUserProfile.status || 'STUDENT'
      }

      console.log(
        `Test initialized for user: ${userId} with original role: ${this.originalStatus}`
      )
      return true
    } catch (error) {
      console.error('Error initializing test:', error)
      return false
    }
  }

  /**
   * Set the user's role for testing
   * @param {string} role The role to set (from CORE_ROLES)
   * @returns {Promise<boolean>} Whether the role change was successful
   */
  async setUserRole(userId, role) {
    if (!userId || !this.testUserProfile) {
      console.error('Test not initialized. Call initializeTest first.')
      return false
    }

    if (!Object.values(CORE_ROLES).includes(role)) {
      console.error(`Invalid role: ${role}. Use a value from CORE_ROLES.`)
      return false
    }

    // Map role to status
    const statusMap = {
      [CORE_ROLES.STUDENT]: 'STUDENT',
      [CORE_ROLES.STUDENT_AMBASSADOR]: 'STUDENT_AMBASSADOR',
      [CORE_ROLES.AMBASSADOR]: 'AMBASSADOR',
      [CORE_ROLES.CORE_MEMBER]: 'CORE',
    }

    try {
      // Update user profile with new status
      const userProfileRef = doc(firestore, 'userProfiles', userId)
      await setDoc(
        userProfileRef,
        {
          ...this.testUserProfile,
          status: statusMap[role],
        },
        { merge: true }
      )

      console.log(`User role changed to: ${role} (status: ${statusMap[role]})`)
      return true
    } catch (error) {
      console.error('Error changing user role:', error)
      return false
    }
  }

  /**
   * Restore the user's original role after testing
   * @returns {Promise<boolean>} Whether restoration was successful
   */
  async restoreOriginalRole(userId) {
    if (!userId || !this.testUserProfile || !this.originalStatus) {
      console.error('Test not properly initialized or already completed.')
      return false
    }

    try {
      // Restore original status
      const userProfileRef = doc(firestore, 'userProfiles', userId)
      await setDoc(
        userProfileRef,
        {
          ...this.testUserProfile,
          status: this.originalStatus,
        },
        { merge: true }
      )

      console.log(`User role restored to original: ${this.originalStatus}`)
      this.testComplete = true
      return true
    } catch (error) {
      console.error('Error restoring original role:', error)
      return false
    }
  }

  /**
   * Test route access for a specific route
   * @param {string} route The route to test
   * @param {string} currentRole The role being tested
   * @param {string} expectedOutcome Whether access should be granted ('allow' or 'deny')
   * @returns {Promise<{success: boolean, message: string}>} Test result
   */
  async testRouteAccess(route, currentRole, expectedOutcome) {
    try {
      console.log(
        `Testing access to ${route} with role ${currentRole}, expecting ${expectedOutcome}`
      )

      // Store current location for comparison
      const startLocation = window.location.pathname

      // Instead of directly navigating with window.location.href, which can cause
      // message port closed errors, we'll store navigation intent in sessionStorage
      // and then navigate in a more controlled way
      const navigationData = {
        targetRoute: route,
        testPhase: 'navigation',
        currentRole,
        expectedOutcome,
        startLocation,
        timestamp: new Date().toISOString(),
      }

      // Save navigation data for retrieval after navigation
      sessionStorage.setItem('rbacNavigation', JSON.stringify(navigationData))

      // Schedule the navigation to occur after this function returns
      setTimeout(() => {
        try {
          // Perform the actual navigation
          window.location.assign(route)
        } catch (e) {
          console.error('Navigation error:', e)
        }
      }, 50)

      // Return a preliminary result - the actual check will happen after navigation
      return {
        success: true,
        message: `Navigation to ${route} scheduled. Check results after page reload.`,
        pending: true,
      }
    } catch (error) {
      console.error('Error during route access test preparation:', error)
      return {
        success: false,
        message: `❌ Error preparing route access test: ${error.message}`,
        error: error.toString(),
      }
    }
  }

  /**
   * Check navigation result after page reload
   * @returns {Promise<{success: boolean, message: string}>} Test result
   */
  async checkNavigationResult() {
    try {
      // Get navigation data from session storage
      const navigationDataStr = sessionStorage.getItem('rbacNavigation')
      if (!navigationDataStr) {
        return null // No navigation test in progress
      }

      // Clear the navigation data to prevent reusing it
      sessionStorage.removeItem('rbacNavigation')

      const navigationData = JSON.parse(navigationDataStr)
      const { targetRoute, currentRole, expectedOutcome, startLocation } =
        navigationData

      // Get the current location after navigation (and any redirects)
      const endLocation = window.location.pathname

      // Check if we're at the expected location
      const isAtExpectedRoute = endLocation === targetRoute

      // Determine test result based on expected outcome and actual result
      if (expectedOutcome === 'allow' && isAtExpectedRoute) {
        return {
          success: true,
          message: `✅ Success: ${currentRole} can access ${targetRoute}`,
          startLocation,
          endLocation,
        }
      } else if (expectedOutcome === 'deny' && !isAtExpectedRoute) {
        return {
          success: true,
          message: `✅ Success: ${currentRole} correctly denied access to ${targetRoute} and redirected to ${endLocation}`,
          startLocation,
          endLocation,
        }
      } else if (expectedOutcome === 'allow' && !isAtExpectedRoute) {
        return {
          success: false,
          message: `❌ Failed: ${currentRole} should be able to access ${targetRoute} but was redirected to ${endLocation}`,
          startLocation,
          endLocation,
        }
      } else {
        return {
          success: false,
          message: `❌ Failed: ${currentRole} should not be able to access ${targetRoute} but access was granted`,
          startLocation,
          endLocation,
        }
      }
    } catch (error) {
      console.error('Error checking navigation result:', error)
      return {
        success: false,
        message: `❌ Error checking navigation result: ${error.message}`,
        error: error.toString(),
      }
    }
  }

  /**
   * Run comprehensive tests for all roles and routes
   * @returns {Promise<object>} Test results for all routes and roles
   */
  async runComprehensiveTest(userId) {
    if (!userId) {
      console.error('No user is authenticated. Please sign in first.')
      return false
    }

    try {
      // Initialize test
      const initialized = await this.initializeTest(userId)
      if (!initialized) {
        console.error('Failed to initialize test')
        return {
          summary: {
            success: false,
            message:
              'Failed to initialize test. Check Firestore permissions and user profile.',
          },
          results: {},
        }
      }

      // Define test cases (we'll run these one at a time in sequence)
      // Each navigation will require a page reload
      const allTestCases = [
        // Student routes
        {
          role: CORE_ROLES.STUDENT,
          route: '/dashboard/home',
          expectedOutcome: 'allow',
        },
        {
          role: CORE_ROLES.STUDENT,
          route: '/ambassador/dashboard/home',
          expectedOutcome: 'deny',
        },
        {
          role: CORE_ROLES.STUDENT,
          route: '/admin/dashboard',
          expectedOutcome: 'deny',
        },

        // Student Ambassador routes
        {
          role: CORE_ROLES.STUDENT_AMBASSADOR,
          route: '/dashboard/home',
          expectedOutcome: 'allow',
        },
        {
          role: CORE_ROLES.STUDENT_AMBASSADOR,
          route: '/ambassador/dashboard/home',
          expectedOutcome: 'allow',
        },
        {
          role: CORE_ROLES.STUDENT_AMBASSADOR,
          route: '/ambassador/dashboard/analytics',
          expectedOutcome: 'allow',
        },
        {
          role: CORE_ROLES.STUDENT_AMBASSADOR,
          route: '/admin/dashboard',
          expectedOutcome: 'deny',
        },

        // Ambassador routes
        {
          role: CORE_ROLES.AMBASSADOR,
          route: '/dashboard/home',
          expectedOutcome: 'allow',
        },
        {
          role: CORE_ROLES.AMBASSADOR,
          route: '/ambassador/dashboard/home',
          expectedOutcome: 'allow',
        },
        {
          role: CORE_ROLES.AMBASSADOR,
          route: '/admin/dashboard',
          expectedOutcome: 'allow',
        },

        // Core Member routes
        {
          role: CORE_ROLES.CORE_MEMBER,
          route: '/dashboard/home',
          expectedOutcome: 'allow',
        },
        {
          role: CORE_ROLES.CORE_MEMBER,
          route: '/ambassador/dashboard/home',
          expectedOutcome: 'allow',
        },
      ]

      // Get the current state of testing from sessionStorage
      let testProgress = sessionStorage.getItem('rbacComprehensiveTest')
      let testState = testProgress
        ? JSON.parse(testProgress)
        : {
            currentIndex: 0,
            results: {},
            completed: false,
          }

      // If the test is already completed, return the results
      if (testState.completed) {
        sessionStorage.removeItem('rbacComprehensiveTest')

        // Restore original role
        await this.restoreOriginalRole(userId)

        return {
          summary: {
            success: true,
            message: 'Comprehensive test completed',
            passed: Object.values(testState.results).filter((r) => r.success)
              .length,
            total: Object.values(testState.results).length,
          },
          results: testState.results,
        }
      }

      // Only test the next case in the sequence
      const currentTest = allTestCases[testState.currentIndex]

      // Set the role for the current test
      const roleSet = await this.setUserRole(userId, currentTest.role)
      if (!roleSet) {
        testState.results[`${currentTest.role}_${currentTest.route}`] = {
          success: false,
          message: `Failed to set role to ${currentTest.role} for route ${currentTest.route}`,
        }
      } else {
        // Prepare for navigation test
        testState.roleInTest = currentTest.role
        testState.routeInTest = currentTest.route
        testState.expectedOutcome = currentTest.expectedOutcome
      }

      // Move to the next test for the next reload
      testState.currentIndex++

      // If we've completed all tests, mark as completed
      if (testState.currentIndex >= allTestCases.length) {
        testState.completed = true
      }

      // Save test state for the next reload
      sessionStorage.setItem('rbacComprehensiveTest', JSON.stringify(testState))

      // If we have a role to test, navigate and test it
      if (testState.roleInTest) {
        // Schedule a navigation test for the next reload
        await this.testRouteAccess(
          testState.routeInTest,
          testState.roleInTest,
          testState.expectedOutcome
        )

        // We don't need to check result.pending here since we're
        // relying on the navigation to refresh the page anyway
      }

      // Return the current results (this may not include the current test result
      // which will be added on the next reload)
      return {
        summary: {
          success: true,
          message: 'Comprehensive test in progress',
          progress: `${testState.currentIndex}/${allTestCases.length} tests completed`,
        },
        results: testState.results,
      }
    } catch (error) {
      console.error('Error running comprehensive test:', error)
      return {
        summary: {
          success: false,
          message: `Error: ${error.message}`,
        },
        results: {},
      }
    }
  }
}

// Export a singleton instance for use throughout the application
const rbacTestUtilInstance = new RbacTestUtil()
export default rbacTestUtilInstance
