import React, { useEffect, useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowLeft,
  faDownload,
  faShare,
  faCalendarAlt,
  faUser,
  faBook,
  faVideo,
  faFile,
  faFileAlt,
  faExternalLinkAlt,
  faClock,
  faBookmark,
  faCheckCircle,
  faSpinner,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { getResourceById, deleteResource } from '../../services/resourceService'
import { toast } from 'react-toastify'
import { determineFileType, FILE_TYPES } from '../../models/Resource'
import { useAuth } from '../../utils/firebase/auth'
import {
  trackResourceView,
  addToLibrary,
  isResourceInLibrary,
  removeFromLibrary,
} from '../../services/libraryService'
import {
  recordResourceProgress,
  getResourceProgress,
} from '../../services/progressService'
import { extractYoutubeId, getYoutubeEmbedUrl } from '../../utils/helpers'

function ResourceDetail() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { authUser } = useAuth()
  const [resource, setResource] = useState(null)
  const [loading, setLoading] = useState(true)
  const [confirmingDelete, setConfirmingDelete] = useState(false)
  const [inLibrary, setInLibrary] = useState(false)
  const [addingToLibrary, setAddingToLibrary] = useState(false)
  const [progress, setProgress] = useState(null)
  const [recordingProgress, setRecordingProgress] = useState(false)
  const [viewTimerStarted, setViewTimerStarted] = useState(null)
  const [timeSpent, setTimeSpent] = useState(0)

  useEffect(() => {
    const fetchResource = async () => {
      try {
        setLoading(true)
        const resourceData = await getResourceById(id)

        if (resourceData) {
          console.log('Resource data:', resourceData)
          setResource(resourceData)

          // Track this view if user is authenticated
          if (authUser) {
            trackResourceView(authUser.uid, id)

            // Check if in library
            const libraryStatus = await isResourceInLibrary(authUser.uid, id)
            setInLibrary(libraryStatus)

            // Get user progress for this resource
            const progressData = await getResourceProgress(authUser.uid, id)
            setProgress(progressData)
          }

          // Start view timer
          setViewTimerStarted(new Date())
        }
      } catch (error) {
        console.error('Error fetching resource:', error)
        toast.error('Failed to load the resource. Please try again later.')
      } finally {
        setLoading(false)
      }
    }

    fetchResource()

    // Clean up timer when component unmounts
    return () => {
      if (viewTimerStarted && authUser) {
        const timeElapsed = Math.round(
          (new Date() - viewTimerStarted) / 1000 / 60
        )
        if (timeElapsed >= 1) {
          recordResourceProgress(authUser.uid, id, 50, timeElapsed)
        }
      }
    }
  }, [id, authUser, viewTimerStarted])

  // Update time spent counter
  useEffect(() => {
    if (!viewTimerStarted) return

    const timer = setInterval(() => {
      const elapsed = Math.round((new Date() - viewTimerStarted) / 1000)
      setTimeSpent(elapsed)
    }, 1000)

    return () => clearInterval(timer)
  }, [viewTimerStarted])

  // Format time display
  const formatTimeSpent = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
  }

  // Handle adding/removing from library
  const handleLibraryToggle = async () => {
    if (!authUser) {
      toast.info('Please sign in to save resources to your library')
      navigate('/account/login')
      return
    }

    try {
      setAddingToLibrary(true)

      if (inLibrary) {
        await removeFromLibrary(authUser.uid, id)
        setInLibrary(false)
        toast.success('Resource removed from your library')
      } else {
        await addToLibrary(authUser.uid, id)
        setInLibrary(true)
        toast.success('Resource saved to your library')
      }
    } catch (error) {
      console.error('Error toggling library status:', error)
      toast.error('Failed to update your library')
    } finally {
      setAddingToLibrary(false)
    }
  }

  // Handle marking as complete
  const handleMarkComplete = async () => {
    if (!authUser) {
      toast.info('Please sign in to track your progress')
      navigate('/account/login')
      return
    }

    try {
      setRecordingProgress(true)

      // Record 100% completion and add current view time
      const minutesSpent = Math.max(1, Math.round(timeSpent / 60))
      await recordResourceProgress(authUser.uid, id, 100, minutesSpent)

      // Update local progress state
      setProgress({
        ...progress,
        completionPercentage: 100,
        completedAt: new Date(),
        timeSpentMinutes: progress
          ? progress.timeSpentMinutes + minutesSpent
          : minutesSpent,
      })

      toast.success('Resource marked as complete!')
    } catch (error) {
      console.error('Error recording progress:', error)
      toast.error('Failed to update your progress')
    } finally {
      setRecordingProgress(false)
    }
  }

  // Get appropriate icon based on resource type
  const getResourceIcon = (type) => {
    const iconMap = {
      readings: faBook,
      videos: faVideo,
      docs: faFile,
      assignments: faFileAlt,
    }
    return iconMap[type] || faFile
  }

  // Format date from Firebase timestamp
  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A'

    const date = timestamp.seconds
      ? new Date(timestamp.seconds * 1000)
      : new Date(timestamp)

    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }

  // Format file size to readable format
  const formatFileSize = (size) => {
    if (!size) return 'Unknown'

    const units = ['B', 'KB', 'MB', 'GB']
    let formattedSize = size
    let unitIndex = 0

    while (formattedSize > 1024 && unitIndex < units.length - 1) {
      formattedSize /= 1024
      unitIndex++
    }

    return `${formattedSize.toFixed(1)} ${units[unitIndex]}`
  }

  // Preview content based on file type
  const renderPreview = (resource) => {
    if (!resource?.fileUrl) return null

    const fileType =
      resource.fileType ||
      determineFileType(resource.fileName || resource.fileUrl)

    switch (fileType) {
      case FILE_TYPES.PDF:
        return (
          <div className="border rounded-lg p-4 bg-gray-50 h-96 flex flex-col">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center">
                <FontAwesomeIcon
                  icon={faFilePdf}
                  className="text-red-600 mr-2"
                />
                <span className="text-gray-700 font-medium">PDF Document</span>
              </div>
              <a
                href={resource.fileUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline text-sm flex items-center"
              >
                <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-1" />
                Open in new tab
              </a>
            </div>
            <iframe
              src={`${resource.fileUrl}#toolbar=1&view=FitH`}
              className="w-full h-full border rounded"
              title={resource.title}
              allowFullScreen
            />
          </div>
        )
      case FILE_TYPES.YOUTUBE:
        const videoId = extractYoutubeId(resource.fileUrl)
        const embedUrl = videoId ? getYoutubeEmbedUrl(videoId) : null

        if (!embedUrl) {
          return (
            <div className="border rounded-lg p-8 bg-gray-50 text-center">
              <FontAwesomeIcon
                icon={faYoutube}
                className="text-6xl text-red-600 mb-4"
              />
              <p className="text-gray-700 mb-3">
                Invalid YouTube URL. Please check the resource link.
              </p>
              <a
                href={resource.fileUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-4 bg-blue-700 text-white px-4 py-2 rounded-md inline-flex items-center"
              >
                <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-2" />
                Visit YouTube
              </a>
            </div>
          )
        }

        return (
          <div className="border rounded-lg p-4 bg-gray-50">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center">
                <FontAwesomeIcon
                  icon={faYoutube}
                  className="text-red-600 mr-2"
                />
                <span className="text-gray-700 font-medium">YouTube Video</span>
              </div>
              <a
                href={resource.fileUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline text-sm flex items-center"
              >
                <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-1" />
                View on YouTube
              </a>
            </div>
            <div className="relative pt-[56.25%]">
              {' '}
              {/* 16:9 aspect ratio */}
              <iframe
                className="absolute top-0 left-0 w-full h-full rounded"
                src={embedUrl}
                title={resource.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        )
      case FILE_TYPES.VIDEO:
        return (
          <div className="border rounded-lg p-4 bg-gray-50">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center">
                <FontAwesomeIcon
                  icon={faVideo}
                  className="text-blue-600 mr-2"
                />
                <span className="text-gray-700 font-medium">Video</span>
              </div>
            </div>
            <video
              src={resource.fileUrl}
              controls
              className="w-full rounded"
              poster={resource.thumbnailUrl}
            >
              Your browser does not support the video tag.
            </video>
          </div>
        )
      case FILE_TYPES.IMAGE:
        return (
          <div className="border rounded-lg p-4 bg-gray-50 text-center">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center">
                <FontAwesomeIcon icon={faFile} className="text-blue-600 mr-2" />
                <span className="text-gray-700 font-medium">Image</span>
              </div>
            </div>
            <img
              src={resource.fileUrl}
              alt={resource.title}
              className="max-h-96 max-w-full mx-auto rounded"
            />
          </div>
        )
      default:
        return (
          <div className="border rounded-lg p-8 bg-gray-50 text-center">
            <FontAwesomeIcon
              icon={getResourceIcon(resource.type)}
              className="text-6xl text-blue-800 mb-4"
            />
            <p className="text-gray-700 mb-3">
              Preview not available for this file type. Please download the file
              to view it.
            </p>
            <a
              href={resource.fileUrl}
              download
              className="mt-4 bg-blue-700 text-white px-4 py-2 rounded-md inline-flex items-center"
            >
              <FontAwesomeIcon icon={faDownload} className="mr-2" />
              Download {resource.fileName || 'File'}
            </a>
          </div>
        )
    }
  }

  const handleDeleteResource = async () => {
    if (!confirmingDelete) {
      setConfirmingDelete(true)
      return
    }

    try {
      setLoading(true)
      await deleteResource(id)
      toast.success('Resource deleted successfully')
      navigate('/library')
    } catch (error) {
      console.error('Error deleting resource:', error)
      toast.error('Failed to delete resource. Please try again.')
      setConfirmingDelete(false)
    } finally {
      setLoading(false)
    }
  }

  const handleShareResource = () => {
    // Implementation for sharing would go here
    toast.info('Sharing functionality coming soon!')
  }

  const renderActionButtons = () => {
    return (
      <div className="flex items-center space-x-2">
        {/* Save to Library Button */}
        <button
          onClick={handleLibraryToggle}
          disabled={addingToLibrary}
          className={`px-3 py-2 rounded-lg flex items-center ${
            inLibrary
              ? 'bg-primary-50 text-primary border border-primary-200'
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
          }`}
          title={inLibrary ? 'Remove from Library' : 'Save to Library'}
        >
          {addingToLibrary ? (
            <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
          ) : (
            <FontAwesomeIcon icon={faBookmark} className="mr-2" />
          )}
          {inLibrary ? 'Saved' : 'Save'}
        </button>

        {/* Mark as Complete Button */}
        {authUser && (!progress || progress.completionPercentage < 100) && (
          <button
            onClick={handleMarkComplete}
            disabled={recordingProgress}
            className="px-3 py-2 bg-green-100 text-green-700 hover:bg-green-200 rounded-lg flex items-center"
            title="Mark as Complete"
          >
            {recordingProgress ? (
              <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
            ) : (
              <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
            )}
            Mark Complete
          </button>
        )}

        {/* Continue any existing buttons */}
      </div>
    )
  }

  const renderTimeSpent = () => {
    if (!viewTimerStarted) return null

    return (
      <div className="flex items-center text-gray-500 text-sm">
        <FontAwesomeIcon icon={faClock} className="mr-1" />
        Time spent: {formatTimeSpent(timeSpent)}
      </div>
    )
  }

  if (loading) {
    return (
      <div className="container mx-auto py-16 px-4">
        <div className="text-center py-12">
          <div className="inline-block animate-spin rounded-full h-10 w-10 border-b-2 border-blue-700"></div>
          <p className="mt-4 text-gray-600">Loading resource details...</p>
        </div>
      </div>
    )
  }

  if (!resource) {
    return (
      <div className="container mx-auto py-16 px-4">
        <div className="text-center bg-gray-50 rounded-lg p-8 max-w-2xl mx-auto">
          <FontAwesomeIcon
            icon={faFile}
            className="text-6xl text-gray-400 mb-4"
          />
          <h1 className="text-2xl font-bold text-gray-700 mb-4">
            Resource Not Found
          </h1>
          <p className="text-gray-600 mb-6">
            The resource you're looking for might have been removed or the link
            is incorrect.
          </p>
          <Link
            to="/library"
            className="bg-blue-700 hover:bg-blue-800 text-white px-6 py-3 rounded-md inline-flex items-center"
          >
            <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
            Back to Library
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div className="container mx-auto py-8 px-4">
      <div className="mb-6">
        <Link
          to="/library"
          className="text-blue-700 hover:text-blue-900 inline-flex items-center"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
          Back to Library
        </Link>
      </div>

      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        {/* Resource Header */}
        <div className="bg-blue-700 text-white p-6">
          <div className="flex items-center gap-4">
            <FontAwesomeIcon
              icon={getResourceIcon(resource.type)}
              className="text-4xl"
            />
            <div>
              <h1 className="text-2xl font-bold">{resource.title}</h1>
              {resource.courseId && (
                <p className="opacity-80">Course: {resource.courseId}</p>
              )}
            </div>
          </div>
          {renderActionButtons()}
        </div>

        {/* Resource Content */}
        <div className="p-6">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Resource Info Column */}
            <div className="lg:col-span-1">
              <div className="bg-gray-50 rounded-lg p-4 mb-6">
                <h2 className="text-lg font-semibold text-gray-800 mb-4">
                  Resource Information
                </h2>

                <div className="space-y-4">
                  <div>
                    <p className="text-sm text-gray-500">Resource Type</p>
                    <p className="font-medium">
                      {resource.type &&
                        resource.type.charAt(0).toUpperCase() +
                          resource.type.slice(1)}
                    </p>
                  </div>

                  {resource.subject && (
                    <div>
                      <p className="text-sm text-gray-500">Subject</p>
                      <p className="font-medium">{resource.subject}</p>
                    </div>
                  )}

                  {/* Cameroonian Educational System Information */}
                  {resource.edSystem && (
                    <div>
                      <p className="text-sm text-gray-500">
                        Educational System
                      </p>
                      <p className="font-medium">{resource.edSystem}</p>
                    </div>
                  )}

                  {resource.secondaryType && (
                    <div>
                      <p className="text-sm text-gray-500">Secondary Level</p>
                      <p className="font-medium">{resource.secondaryType}</p>
                    </div>
                  )}

                  {resource.academicLevel && (
                    <div>
                      <p className="text-sm text-gray-500">Academic Level</p>
                      <p className="font-medium">{resource.academicLevel}</p>
                    </div>
                  )}

                  {resource.examPreparation && (
                    <div>
                      <p className="text-sm text-gray-500">Exam Preparation</p>
                      <p className="font-medium">{resource.examPreparation}</p>
                    </div>
                  )}

                  {resource.language && (
                    <div>
                      <p className="text-sm text-gray-500">Language</p>
                      <p className="font-medium">{resource.language}</p>
                    </div>
                  )}

                  <div>
                    <p className="text-sm text-gray-500">File Type</p>
                    <p className="font-medium text-gray-800">
                      {(
                        resource.fileType ||
                        determineFileType(resource.fileName)
                      ).toUpperCase()}
                    </p>
                  </div>

                  {resource.fileSize && (
                    <div>
                      <p className="text-sm text-gray-500">File Size</p>
                      <p className="font-medium">
                        {formatFileSize(resource.fileSize)}
                      </p>
                    </div>
                  )}

                  {resource.fileName && (
                    <div>
                      <p className="text-sm text-gray-500">File Name</p>
                      <p className="font-medium text-sm break-words">
                        {resource.fileName}
                      </p>
                    </div>
                  )}

                  <div>
                    <p className="text-sm text-gray-500">
                      <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                      Upload Date
                    </p>
                    <p className="font-medium">
                      {formatDate(resource.createdAt || resource.date)}
                    </p>
                  </div>

                  {resource.updatedAt && (
                    <div>
                      <p className="text-sm text-gray-500">
                        <FontAwesomeIcon icon={faClock} className="mr-2" />
                        Last Updated
                      </p>
                      <p className="font-medium">
                        {formatDate(resource.updatedAt)}
                      </p>
                    </div>
                  )}

                  {resource.uploadedBy && (
                    <div>
                      <p className="text-sm text-gray-500">
                        <FontAwesomeIcon icon={faUser} className="mr-2" />
                        Uploaded By
                      </p>
                      <p className="font-medium">{resource.uploadedBy}</p>
                    </div>
                  )}
                </div>

                <div className="mt-6 space-y-3">
                  <a
                    href={resource.fileUrl}
                    download={resource.fileName}
                    className="bg-blue-700 hover:bg-blue-800 text-white w-full py-2 rounded-md flex items-center justify-center"
                  >
                    <FontAwesomeIcon icon={faDownload} className="mr-2" />
                    Download
                  </a>

                  <button
                    onClick={handleShareResource}
                    className="bg-gray-200 hover:bg-gray-300 text-gray-800 w-full py-2 rounded-md flex items-center justify-center"
                  >
                    <FontAwesomeIcon icon={faShare} className="mr-2" />
                    Share
                  </button>

                  <button
                    onClick={handleDeleteResource}
                    className={`${
                      confirmingDelete
                        ? 'bg-red-600 hover:bg-red-700'
                        : 'bg-gray-200 hover:bg-gray-300 text-gray-800'
                    } w-full py-2 rounded-md flex items-center justify-center`}
                  >
                    {confirmingDelete ? (
                      <span className="text-white">Confirm Delete</span>
                    ) : (
                      <span>Delete Resource</span>
                    )}
                  </button>
                </div>
              </div>
            </div>

            {/* Resource Preview & Description Column */}
            <div className="lg:col-span-2">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                Resource Preview
              </h2>

              {renderPreview(resource)}

              {authUser &&
                progress &&
                progress.completionPercentage === 100 && (
                  <div className="preview-container mb-4">
                    <div className="mt-4 p-3 bg-green-50 border border-green-200 rounded-lg flex items-center">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="text-green-500 mr-3 text-xl"
                      />
                      <div>
                        <p className="font-medium text-green-700">
                          You've completed this resource!
                        </p>
                        <p className="text-sm text-green-600">
                          Time spent: {progress.timeSpentMinutes} minutes
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              <div className="mt-6">
                <h2 className="text-xl font-semibold text-gray-800 mb-3">
                  Description
                </h2>
                <div className="bg-gray-50 rounded-lg p-4">
                  <p className="text-gray-700 whitespace-pre-line">
                    {resource.description ||
                      'No description provided for this resource.'}
                  </p>
                </div>
              </div>

              {resource.fileUrl && (
                <div className="mt-6">
                  <h2 className="text-xl font-semibold text-gray-800 mb-3">
                    External Access
                  </h2>
                  <div className="bg-gray-50 rounded-lg p-4">
                    <p className="text-gray-700 mb-3">
                      Access this resource directly in a new tab:
                    </p>
                    <a
                      href={resource.fileUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-white border border-gray-300 hover:bg-gray-50 text-blue-700 py-2 px-4 rounded-md inline-flex items-center"
                    >
                      <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        className="mr-2"
                      />
                      Open in New Tab
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {renderTimeSpent()}
    </div>
  )
}

export default ResourceDetail
