import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useAuth } from '../firebase/auth'
import { useRole } from './RoleContext'
import routeRegistry from './RouteRegistry'

/**
 * A universal component for role-based route protection
 * Uses the centralized route registry to determine access permissions
 *
 * @param {Object} props - Component props
 * @param {string} props.routeKey - The key of the route in the registry
 * @param {React.ReactNode} props.children - Child components to render if access is allowed
 * @param {string} [props.fallbackPath] - Path to redirect to if access is denied (overrides the default)
 */
export const ProtectedRoute = ({ routeKey, children, fallbackPath }) => {
  const { authUser, isLoading: authLoading } = useAuth()
  const { userRole, isLoading: roleLoading } = useRole()
  const navigate = useNavigate()
  const [isChecking, setIsChecking] = useState(true)

  useEffect(() => {
    const checkAccess = async () => {
      // Wait for auth and role data to load
      if (authLoading || roleLoading) return

      // Check if user is authenticated
      if (!authUser) {
        navigate('/account/login')
        return
      }

      // Get route configuration from registry
      const routeConfig = routeRegistry[routeKey]

      if (!routeConfig) {
        console.error(`Route config not found for key: ${routeKey}`)
        navigate('/dashboard/home')
        return
      }

      // Check if user has the required role
      const hasAccess = routeConfig.roles.includes(userRole)

      if (!hasAccess) {
        // Navigate to fallback or default route based on user's role
        if (fallbackPath) {
          navigate(fallbackPath)
        } else if (userRole === 'CORE_MEMBER' || userRole === 'AMBASSADOR') {
          navigate('/admin/dashboard')
        } else if (userRole === 'STUDENT_AMBASSADOR') {
          navigate('/ambassador/dashboard/home')
        } else {
          navigate('/dashboard/home')
        }
        return
      }

      // Access granted
      setIsChecking(false)
    }

    checkAccess()
  }, [
    authUser,
    userRole,
    routeKey,
    authLoading,
    roleLoading,
    navigate,
    fallbackPath,
  ])

  if (authLoading || roleLoading || isChecking) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-lg">Loading...</div>
      </div>
    )
  }

  return children
}

/**
 * A simpler component for protecting routes when you don't need
 * the full component lifecycle. This is useful for route definitions in the router.
 *
 * @param {Object} props - Component props
 * @param {string} props.routeKey - The key of the route in the registry
 * @param {React.ReactNode} props.children - Child components to render if access is allowed
 * @param {string} [props.redirectTo] - Path to redirect to if access is denied
 */
export const RoleBasedRoute = ({
  routeKey,
  children,
  redirectTo = '/dashboard/home',
}) => {
  const { userRole, isLoading } = useRole()
  const { authUser, isLoading: authLoading } = useAuth()

  if (isLoading || authLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-lg">Loading...</div>
      </div>
    )
  }

  if (!authUser) {
    return <Navigate to="/account/login" replace />
  }

  const routeConfig = routeRegistry[routeKey]

  if (!routeConfig) {
    console.error(`Route config not found for key: ${routeKey}`)
    return <Navigate to="/dashboard/home" replace />
  }

  const hasAccess = routeConfig.roles.includes(userRole)

  if (!hasAccess) {
    return <Navigate to={redirectTo} replace />
  }

  return children
}

/**
 * Generate redirect paths based on user role
 * @param {string} role - User role
 * @returns {string} - Default redirect path for the role
 */
export const getDefaultRedirectForRole = (role) => {
  switch (role) {
    case 'CORE_MEMBER':
    case 'AMBASSADOR':
      return '/admin/dashboard'
    case 'STUDENT_AMBASSADOR':
      return '/ambassador/dashboard/home'
    case 'STUDENT':
    default:
      return '/dashboard/home'
  }
}

export default ProtectedRoute
