import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useRole } from '../../../utils/permissions/RoleContext'
import {
  CORE_ROLES,
  ALL_PERMISSIONS,
  getPermissionsForRole,
} from '../../../utils/permissions/roles'

/**
 * Permissions Explorer - A tool to view and understand all permissions in the system
 */
const PermissionsExplorer = () => {
  const { userRole, userPermissions } = useRole()
  const [searchTerm, setSearchTerm] = useState('')
  const [filterByRole, setFilterByRole] = useState('ALL')
  const [filterByCategory, setFilterByCategory] = useState('ALL')

  // Permission categories
  const permissionCategories = {
    CONTENT: 'Content Management',
    USER: 'User Management',
    ANALYTICS: 'Analytics',
    EVENT: 'Events',
    MENTOR: 'Mentoring',
    SYSTEM: 'System Administration',
    ADVANCED: 'Advanced Features',
  }

  // Permission descriptions
  const permissionDescriptions = {
    // Content Management
    VIEW_CONTENT: 'View content within the platform',
    CREATE_CONTENT: 'Create new content',
    EDIT_CONTENT: 'Edit existing content',
    DELETE_CONTENT: 'Delete content',
    APPROVE_CONTENT: 'Approve content created by others',

    // User Management
    VIEW_USERS: 'View user information',
    MANAGE_USERS: 'Create, edit, or delete users',
    MANAGE_ROLES: 'Assign or change user roles',

    // Analytics
    VIEW_ANALYTICS: 'View analytics for your own content',
    VIEW_GLOBAL_ANALYTICS: 'View platform-wide analytics',

    // Events
    VIEW_EVENTS: 'View events',
    CREATE_EVENTS: 'Create new events',
    MANAGE_EVENTS: 'Edit or delete any events',

    // Mentoring
    MENTOR_STUDENTS: 'Provide mentoring to students',

    // System Administration
    MANAGE_SYSTEM: 'Configure system settings',
    MANAGE_SETTINGS: 'Manage application settings',
    MANAGE_SCHOOLS: 'Manage school information',

    // Advanced Features
    USE_AI_TOOLS: 'Access AI-powered tools and features',
  }

  // Get category for a permission
  const getPermissionCategory = (permission) => {
    if (
      permission.startsWith('VIEW_') ||
      permission.startsWith('CREATE_') ||
      permission.startsWith('EDIT_') ||
      permission.startsWith('DELETE_') ||
      permission.startsWith('APPROVE_')
    ) {
      return 'CONTENT'
    } else if (permission.includes('USER') || permission.includes('ROLE')) {
      return 'USER'
    } else if (permission.includes('ANALYTICS')) {
      return 'ANALYTICS'
    } else if (permission.includes('EVENT')) {
      return 'EVENT'
    } else if (permission.includes('MENTOR')) {
      return 'MENTOR'
    } else if (
      permission.includes('SYSTEM') ||
      permission.includes('SETTINGS') ||
      permission.includes('SCHOOL')
    ) {
      return 'SYSTEM'
    } else if (permission.includes('AI')) {
      return 'ADVANCED'
    }
    return 'UNCATEGORIZED'
  }

  // Get roles that have this permission
  const getRolesWithPermission = (permission) => {
    return Object.values(CORE_ROLES).filter((role) => {
      const rolePermissions = getPermissionsForRole(role)
      return rolePermissions.includes(permission)
    })
  }

  // Get badge color for a role
  const getRoleBadgeColor = (role) => {
    switch (role) {
      case CORE_ROLES.STUDENT:
        return 'bg-blue-100 text-blue-800'
      case CORE_ROLES.STUDENT_AMBASSADOR:
        return 'bg-green-100 text-green-800'
      case CORE_ROLES.AMBASSADOR:
        return 'bg-purple-100 text-purple-800'
      case CORE_ROLES.CORE_MEMBER:
        return 'bg-red-100 text-red-800'
      default:
        return 'bg-gray-100 text-gray-800'
    }
  }

  // Get badge color for a category
  const getCategoryBadgeColor = (category) => {
    switch (category) {
      case 'CONTENT':
        return 'bg-indigo-100 text-indigo-800'
      case 'USER':
        return 'bg-pink-100 text-pink-800'
      case 'ANALYTICS':
        return 'bg-yellow-100 text-yellow-800'
      case 'EVENT':
        return 'bg-green-100 text-green-800'
      case 'MENTOR':
        return 'bg-purple-100 text-purple-800'
      case 'SYSTEM':
        return 'bg-red-100 text-red-800'
      case 'ADVANCED':
        return 'bg-blue-100 text-blue-800'
      default:
        return 'bg-gray-100 text-gray-800'
    }
  }

  // Filter and sort permissions
  const filteredPermissions = ALL_PERMISSIONS.filter((permission) => {
    const matchesSearch =
      searchTerm === '' ||
      permission.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (permissionDescriptions[permission] &&
        permissionDescriptions[permission]
          .toLowerCase()
          .includes(searchTerm.toLowerCase()))

    const matchesRole =
      filterByRole === 'ALL' ||
      getRolesWithPermission(permission).includes(filterByRole)

    const category = getPermissionCategory(permission)
    const matchesCategory =
      filterByCategory === 'ALL' || category === filterByCategory

    return matchesSearch && matchesRole && matchesCategory
  }).sort((a, b) => {
    // Sort by category first, then alphabetically
    const categoryA = getPermissionCategory(a)
    const categoryB = getPermissionCategory(b)

    if (categoryA !== categoryB) {
      return categoryA.localeCompare(categoryB)
    }
    return a.localeCompare(b)
  })

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8">
        Permissions Explorer
      </h1>

      {/* Current User Info */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4">Current User Information</h2>
        <p>
          <strong>Role:</strong> {userRole || 'Not set'}
        </p>
        <p className="mt-4">
          <strong>Your Permissions:</strong>
        </p>
        {userPermissions && userPermissions.length > 0 ? (
          <div className="mt-2 flex flex-wrap gap-2">
            {userPermissions.map((permission) => (
              <span
                key={permission}
                className="inline-flex rounded-full bg-blue-100 px-3 py-1 text-xs font-semibold text-blue-800"
              >
                {permission}
              </span>
            ))}
          </div>
        ) : (
          <p className="text-gray-500 italic">No permissions available</p>
        )}
      </div>

      {/* Filters */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4">Filter Permissions</h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Search */}
          <div>
            <label
              htmlFor="search"
              className="block text-sm font-medium text-gray-700"
            >
              Search
            </label>
            <input
              type="text"
              id="search"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              placeholder="Search permissions or descriptions"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {/* Role Filter */}
          <div>
            <label
              htmlFor="role-filter"
              className="block text-sm font-medium text-gray-700"
            >
              Filter by Role
            </label>
            <select
              id="role-filter"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              value={filterByRole}
              onChange={(e) => setFilterByRole(e.target.value)}
            >
              <option value="ALL">All Roles</option>
              {Object.values(CORE_ROLES).map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </select>
          </div>

          {/* Category Filter */}
          <div>
            <label
              htmlFor="category-filter"
              className="block text-sm font-medium text-gray-700"
            >
              Filter by Category
            </label>
            <select
              id="category-filter"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              value={filterByCategory}
              onChange={(e) => setFilterByCategory(e.target.value)}
            >
              <option value="ALL">All Categories</option>
              {Object.entries(permissionCategories).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {/* Permissions Table */}
      <div className="bg-white rounded-lg shadow-md overflow-hidden mb-8">
        <div className="px-4 py-5 sm:px-6 bg-gray-50">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Permissions List
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Showing {filteredPermissions.length} of {ALL_PERMISSIONS.length}{' '}
            permissions
          </p>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Permission
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Category
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Description
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Roles with Permission
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  You Have Access
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {filteredPermissions.map((permission) => {
                const category = getPermissionCategory(permission)
                const rolesWithPermission = getRolesWithPermission(permission)
                const userHasPermission =
                  userPermissions && userPermissions.includes(permission)

                return (
                  <tr
                    key={permission}
                    className={userHasPermission ? 'bg-green-50' : ''}
                  >
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {permission}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <span
                        className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${getCategoryBadgeColor(
                          category
                        )}`}
                      >
                        {permissionCategories[category] || 'Uncategorized'}
                      </span>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      {permissionDescriptions[permission] ||
                        'No description available'}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      <div className="flex flex-wrap gap-1">
                        {rolesWithPermission.map((role) => (
                          <span
                            key={role}
                            className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${getRoleBadgeColor(
                              role
                            )}`}
                          >
                            {role}
                          </span>
                        ))}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm">
                      {userHasPermission ? (
                        <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                          Yes
                        </span>
                      ) : (
                        <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                          No
                        </span>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Navigation Links */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-xl font-semibold mb-4">Other Developer Tools</h2>
        <Link
          to="/dev-tools"
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
        >
          Back to Developer Tools
        </Link>
      </div>
    </div>
  )
}

export default PermissionsExplorer
