import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUserCircle,
  faUserGraduate,
  faUserTie,
  faUserShield,
  faCheck,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { useRole } from '../../../utils/permissions/RoleContext'
import { PERMISSIONS } from '../../../utils/permissions/roles'

/**
 * Component to display user role and permissions information
 */
const RoleInfoSection = ({ userStatus }) => {
  const { hasPermission: checkPermission } = useRole()

  // Get role information based on user status
  const getRoleInfo = () => {
    // Map status to role display information
    const roleInfo = {
      STUDENT: {
        title: 'Student',
        description: 'Access learning resources and track your progress',
        icon: faUserCircle,
        color: 'text-blue-600',
        bgColor: 'bg-blue-100',
      },
      STUDENT_AMBASSADOR: {
        title: 'Student Ambassador',
        description: 'Represent KET Academy and help other students',
        icon: faUserGraduate,
        color: 'text-green-600',
        bgColor: 'bg-green-100',
      },
      AMBASSADOR: {
        title: 'Ambassador',
        description:
          'Create content and mentor students in your area of expertise',
        icon: faUserTie,
        color: 'text-purple-600',
        bgColor: 'bg-purple-100',
      },
      TEACHER: {
        title: 'Teacher',
        description:
          'Create content and mentor students in your area of expertise',
        icon: faUserTie,
        color: 'text-purple-600',
        bgColor: 'bg-purple-100',
      },
      COUNSELOR: {
        title: 'Counselor',
        description: 'Provide guidance and support to students',
        icon: faUserTie,
        color: 'text-purple-600',
        bgColor: 'bg-purple-100',
      },
      CORE: {
        title: 'Core Member',
        description: 'Administer the platform and manage users',
        icon: faUserShield,
        color: 'text-red-600',
        bgColor: 'bg-red-100',
      },
      ADMINISTRATOR: {
        title: 'Core Member',
        description: 'Administer the platform and manage users',
        icon: faUserShield,
        color: 'text-red-600',
        bgColor: 'bg-red-100',
      },
    }

    return roleInfo[userStatus] || roleInfo['STUDENT']
  }

  // Get list of key permissions for display
  const getKeyPermissions = () => {
    return [
      {
        id: PERMISSIONS.VIEW_PREMIUM_RESOURCES,
        label: 'Access Premium Resources',
      },
      {
        id: PERMISSIONS.CREATE_RESOURCES,
        label: 'Create Resources',
      },
      {
        id: PERMISSIONS.MODERATE_DISCUSSIONS,
        label: 'Moderate Discussions',
      },
      {
        id: PERMISSIONS.VIEW_REGIONAL_ANALYTICS,
        label: 'View Regional Analytics',
      },
      {
        id: PERMISSIONS.MANAGE_USER_ROLES,
        label: 'Manage User Roles',
      },
      {
        id: PERMISSIONS.APPROVE_STATUS_CHANGES,
        label: 'Approve Status Changes',
      },
    ]
  }

  const roleInfo = getRoleInfo()
  const keyPermissions = getKeyPermissions()

  return (
    <div className="border border-gray-200 rounded-lg p-6 mb-8">
      <h3 className="text-xl font-semibold mb-4">Your Role and Permissions</h3>

      <div
        className={`flex items-center p-4 ${roleInfo.bgColor} rounded-lg mb-6`}
      >
        <div className="mr-4 text-2xl">
          <FontAwesomeIcon icon={roleInfo.icon} className={roleInfo.color} />
        </div>
        <div>
          <h4 className="font-semibold text-lg">{roleInfo.title}</h4>
          <p className="text-gray-700">{roleInfo.description}</p>
        </div>
      </div>

      <h4 className="font-semibold text-lg mb-3">Your Permissions</h4>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-4">
        {keyPermissions.map((permission) => (
          <div
            key={permission.id}
            className="flex items-center border border-gray-100 rounded p-3"
          >
            {checkPermission(permission.id) ? (
              <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-3" />
            ) : (
              <FontAwesomeIcon icon={faTimes} className="text-gray-300 mr-3" />
            )}
            <span
              className={
                checkPermission(permission.id)
                  ? 'text-gray-800'
                  : 'text-gray-400'
              }
            >
              {permission.label}
            </span>
          </div>
        ))}
      </div>

      <p className="text-sm text-gray-500">
        Permissions determine what actions you can perform on the platform. To
        gain additional permissions, you may request a role upgrade.
      </p>
    </div>
  )
}

export default RoleInfoSection
