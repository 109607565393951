import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUsers,
  faChartLine,
  faCalendarAlt,
  faBullhorn,
  faGraduationCap,
  faStar,
} from '@fortawesome/free-solid-svg-icons'

/**
 * Home Component for Student Ambassador Dashboard
 * Displays summary of ambassador activities and impact metrics
 */
const AmbassadorHome = () => {
  // Sample stats data - in a real app, this would come from your backend
  const stats = [
    {
      id: 'students-reached',
      title: 'Students Reached',
      value: '0',
      icon: faUsers,
      bgColor: 'bg-blue-50',
      textColor: 'text-blue-600',
    },
    {
      id: 'events-organized',
      title: 'Events Organized',
      value: '0',
      icon: faCalendarAlt,
      bgColor: 'bg-green-50',
      textColor: 'text-green-600',
    },
    {
      id: 'impact-score',
      title: 'Impact Score',
      value: '0',
      icon: faStar,
      bgColor: 'bg-purple-50',
      textColor: 'text-purple-600',
    },
    {
      id: 'active-campaigns',
      title: 'Active Campaigns',
      value: '0',
      icon: faBullhorn,
      bgColor: 'bg-orange-50',
      textColor: 'text-orange-600',
    },
  ]

  return (
    <div className="ambassador-dashboard-home p-6 max-w-7xl mx-auto">
      {/* Breadcrumb */}
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900 mb-2">
          Student Ambassador Dashboard
        </h1>
        <p className="text-gray-600">
          Welcome to your ambassador control center. Track your impact and
          manage your activities.
        </p>
      </div>

      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {stats.map((stat) => (
          <div
            key={stat.id}
            className={`${stat.bgColor} ${stat.textColor} p-6 rounded-lg shadow-sm`}
          >
            <div className="flex justify-between items-center">
              <div>
                <h3 className="font-bold text-2xl">{stat.value}</h3>
                <p className="text-sm mt-1">{stat.title}</p>
              </div>
              <div className="rounded-full p-3 bg-white bg-opacity-60">
                <FontAwesomeIcon icon={stat.icon} className="text-xl" />
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Quick Actions */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
          <div className="flex items-center mb-4">
            <div className="rounded-full p-3 bg-blue-50 text-blue-600 mr-4">
              <FontAwesomeIcon icon={faCalendarAlt} />
            </div>
            <h3 className="text-lg font-semibold">Event Management</h3>
          </div>
          <p className="text-gray-600 mb-4">
            Create and manage events, workshops, and information sessions.
          </p>
          <button className="text-blue-600 hover:text-blue-700 font-medium">
            Manage Events →
          </button>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
          <div className="flex items-center mb-4">
            <div className="rounded-full p-3 bg-green-50 text-green-600 mr-4">
              <FontAwesomeIcon icon={faGraduationCap} />
            </div>
            <h3 className="text-lg font-semibold">Student Groups</h3>
          </div>
          <p className="text-gray-600 mb-4">
            Manage your student groups and track their progress.
          </p>
          <button className="text-green-600 hover:text-green-700 font-medium">
            View Groups →
          </button>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
          <div className="flex items-center mb-4">
            <div className="rounded-full p-3 bg-purple-50 text-purple-600 mr-4">
              <FontAwesomeIcon icon={faBullhorn} />
            </div>
            <h3 className="text-lg font-semibold">Promotions</h3>
          </div>
          <p className="text-gray-600 mb-4">
            Access promotional materials and manage your campaigns.
          </p>
          <button className="text-purple-600 hover:text-purple-700 font-medium">
            View Promotions →
          </button>
        </div>
      </div>

      {/* Impact Analytics */}
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100 mb-8">
        <div className="flex items-center mb-6">
          <div className="rounded-full p-3 bg-orange-50 text-orange-600 mr-4">
            <FontAwesomeIcon icon={faChartLine} />
          </div>
          <h3 className="text-lg font-semibold">Your Impact Analytics</h3>
        </div>
        <div className="text-center py-12 text-gray-500">
          <p>Impact analytics dashboard coming soon</p>
        </div>
      </div>

      {/* Upcoming Events */}
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <div className="rounded-full p-3 bg-blue-50 text-blue-600 mr-4">
              <FontAwesomeIcon icon={faCalendarAlt} />
            </div>
            <h3 className="text-lg font-semibold">Upcoming Events</h3>
          </div>
          <button className="px-4 py-2 text-sm bg-primary text-white rounded hover:bg-primary-dark">
            Create Event
          </button>
        </div>
        <div className="text-center py-12 text-gray-500">
          <p className="mb-2">No upcoming events</p>
          <p className="text-sm">Create your first event to get started</p>
        </div>
      </div>
    </div>
  )
}

export default AmbassadorHome
