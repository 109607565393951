/**
 * KET Academy Role-Based Access Control System
 * This file maps user statuses to roles and defines permissions for each role
 */

// Core role definitions as per roadmap
export const CORE_ROLES = {
  STUDENT: 'STUDENT', // Regular student
  STUDENT_AMBASSADOR: 'STUDENT_AMBASSADOR', // Student with ambassador privileges
  AMBASSADOR: 'AMBASSADOR', // Teacher/educator/professional with subject matter expertise
  CORE_MEMBER: 'CORE_MEMBER', // Platform administrators (super admins)
}

// Map detailed user statuses to core roles
export const mapStatusToRole = (status) => {
  const statusRoleMap = {
    STUDENT: CORE_ROLES.STUDENT,
    STUDENT_AMBASSADOR: CORE_ROLES.STUDENT_AMBASSADOR,
    AMBASSADOR: CORE_ROLES.AMBASSADOR,
    TEACHER: CORE_ROLES.AMBASSADOR, // Teachers are considered ambassadors
    COUNSELOR: CORE_ROLES.AMBASSADOR, // Counselors are considered ambassadors
    ADMINISTRATOR: CORE_ROLES.CORE_MEMBER, // Administrators are core members
    CORE: CORE_ROLES.CORE_MEMBER, // Core members are super admins
  }

  return statusRoleMap[status] || CORE_ROLES.STUDENT // Default to student if no match
}

// Permission definitions
export const PERMISSIONS = {
  // Content access
  VIEW_PUBLIC_RESOURCES: 'view_public_resources',
  VIEW_PREMIUM_RESOURCES: 'view_premium_resources',
  DOWNLOAD_RESOURCES: 'download_resources',

  // Content management
  CREATE_RESOURCES: 'create_resources',
  EDIT_RESOURCES: 'edit_resources',
  DELETE_RESOURCES: 'delete_resources',
  APPROVE_CONTENT: 'approve_content',

  // Course management
  CREATE_COURSES: 'create_courses',
  EDIT_COURSES: 'edit_courses',
  DELETE_COURSES: 'delete_courses',
  ENROLL_COURSES: 'enroll_courses',

  // User management
  VIEW_USER_PROFILES: 'view_user_profiles',
  EDIT_USER_PROFILES: 'edit_user_profiles',
  DELETE_USER_ACCOUNTS: 'delete_user_accounts',
  MANAGE_USER_ROLES: 'manage_user_roles',
  MANAGE_USERS: 'manage_users',
  MANAGE_ROLES: 'manage_roles',

  // Approval system
  APPROVE_STATUS_CHANGES: 'approve_status_changes',
  APPROVE_SCHOOL_REGISTRATIONS: 'approve_school_registrations',

  // Analytics
  VIEW_PERSONAL_ANALYTICS: 'view_personal_analytics',
  VIEW_REGIONAL_ANALYTICS: 'view_regional_analytics',
  VIEW_PLATFORM_ANALYTICS: 'view_platform_analytics',
  VIEW_GLOBAL_ANALYTICS: 'view_global_analytics',
  VIEW_REPORTS: 'view_reports',

  // Communication
  USE_CHAT_SYSTEM: 'use_chat_system',
  MODERATE_DISCUSSIONS: 'moderate_discussions',

  // Events
  CREATE_EVENTS: 'create_events',
  MANAGE_EVENTS: 'manage_events',

  // Mentoring
  MENTOR_STUDENTS: 'mentor_students',

  // System Administration
  MANAGE_SYSTEM: 'manage_system',
  MANAGE_SCHOOLS: 'manage_schools',

  // Advanced features
  USE_AI_TOOLS: 'use_ai_tools',
}

// Create an array of all available permissions for use in the PermissionsExplorer
export const ALL_PERMISSIONS = Object.values(PERMISSIONS)

// Role-based permission assignments
export const rolePermissions = {
  [CORE_ROLES.STUDENT]: [
    PERMISSIONS.VIEW_PUBLIC_RESOURCES,
    PERMISSIONS.DOWNLOAD_RESOURCES,
    PERMISSIONS.ENROLL_COURSES,
    PERMISSIONS.VIEW_PERSONAL_ANALYTICS,
    PERMISSIONS.USE_CHAT_SYSTEM,
    PERMISSIONS.USE_AI_TOOLS,
  ],

  [CORE_ROLES.STUDENT_AMBASSADOR]: [
    PERMISSIONS.VIEW_PUBLIC_RESOURCES,
    PERMISSIONS.VIEW_PREMIUM_RESOURCES,
    PERMISSIONS.DOWNLOAD_RESOURCES,
    PERMISSIONS.ENROLL_COURSES,
    PERMISSIONS.VIEW_PERSONAL_ANALYTICS,
    PERMISSIONS.VIEW_REGIONAL_ANALYTICS,
    PERMISSIONS.USE_CHAT_SYSTEM,
    PERMISSIONS.MODERATE_DISCUSSIONS,
    PERMISSIONS.CREATE_EVENTS,
    PERMISSIONS.MENTOR_STUDENTS,
    PERMISSIONS.VIEW_REPORTS,
    PERMISSIONS.USE_AI_TOOLS,
  ],

  [CORE_ROLES.AMBASSADOR]: [
    PERMISSIONS.VIEW_PUBLIC_RESOURCES,
    PERMISSIONS.VIEW_PREMIUM_RESOURCES,
    PERMISSIONS.DOWNLOAD_RESOURCES,
    PERMISSIONS.CREATE_RESOURCES,
    PERMISSIONS.EDIT_RESOURCES,
    PERMISSIONS.DELETE_RESOURCES,
    PERMISSIONS.APPROVE_CONTENT,
    PERMISSIONS.CREATE_COURSES,
    PERMISSIONS.EDIT_COURSES,
    PERMISSIONS.DELETE_COURSES,
    PERMISSIONS.ENROLL_COURSES,
    PERMISSIONS.VIEW_USER_PROFILES,
    PERMISSIONS.VIEW_PERSONAL_ANALYTICS,
    PERMISSIONS.VIEW_REGIONAL_ANALYTICS,
    PERMISSIONS.VIEW_GLOBAL_ANALYTICS,
    PERMISSIONS.USE_CHAT_SYSTEM,
    PERMISSIONS.MODERATE_DISCUSSIONS,
    PERMISSIONS.CREATE_EVENTS,
    PERMISSIONS.MANAGE_EVENTS,
    PERMISSIONS.MENTOR_STUDENTS,
    PERMISSIONS.VIEW_REPORTS,
    PERMISSIONS.MANAGE_SCHOOLS,
    PERMISSIONS.USE_AI_TOOLS,
  ],

  [CORE_ROLES.CORE_MEMBER]: [
    PERMISSIONS.VIEW_PUBLIC_RESOURCES,
    PERMISSIONS.VIEW_PREMIUM_RESOURCES,
    PERMISSIONS.DOWNLOAD_RESOURCES,
    PERMISSIONS.CREATE_RESOURCES,
    PERMISSIONS.EDIT_RESOURCES,
    PERMISSIONS.DELETE_RESOURCES,
    PERMISSIONS.APPROVE_CONTENT,
    PERMISSIONS.CREATE_COURSES,
    PERMISSIONS.EDIT_COURSES,
    PERMISSIONS.DELETE_COURSES,
    PERMISSIONS.ENROLL_COURSES,
    PERMISSIONS.VIEW_USER_PROFILES,
    PERMISSIONS.EDIT_USER_PROFILES,
    PERMISSIONS.DELETE_USER_ACCOUNTS,
    PERMISSIONS.MANAGE_USER_ROLES,
    PERMISSIONS.MANAGE_USERS,
    PERMISSIONS.MANAGE_ROLES,
    PERMISSIONS.APPROVE_STATUS_CHANGES,
    PERMISSIONS.APPROVE_SCHOOL_REGISTRATIONS,
    PERMISSIONS.VIEW_PERSONAL_ANALYTICS,
    PERMISSIONS.VIEW_REGIONAL_ANALYTICS,
    PERMISSIONS.VIEW_PLATFORM_ANALYTICS,
    PERMISSIONS.VIEW_GLOBAL_ANALYTICS,
    PERMISSIONS.USE_CHAT_SYSTEM,
    PERMISSIONS.MODERATE_DISCUSSIONS,
    PERMISSIONS.CREATE_EVENTS,
    PERMISSIONS.MANAGE_EVENTS,
    PERMISSIONS.MENTOR_STUDENTS,
    PERMISSIONS.MANAGE_SYSTEM,
    PERMISSIONS.MANAGE_SCHOOLS,
    PERMISSIONS.VIEW_REPORTS,
    PERMISSIONS.USE_AI_TOOLS,
  ],
}

/**
 * Check if a user has a specific permission
 * @param {string} userStatus - The user's status (e.g., 'STUDENT', 'AMBASSADOR')
 * @param {string} permission - The permission to check
 * @returns {boolean} - Whether the user has the permission
 */
export const hasPermission = (userStatus, permission) => {
  const role = mapStatusToRole(userStatus)
  return rolePermissions[role]?.includes(permission) || false
}

/**
 * Get all permissions for a specific role
 * @param {string} role - The role to get permissions for
 * @returns {Array} - Array of permissions for the role
 */
export const getPermissionsForRole = (role) => {
  return rolePermissions[role] || []
}

/**
 * Check if a user status is allowed to transition to another status
 * @param {string} currentStatus - Current user status
 * @param {string} targetStatus - Target user status
 * @returns {boolean} - Whether the transition is allowed
 */
export const canTransitionToStatus = (currentStatus, targetStatus) => {
  const currentRole = mapStatusToRole(currentStatus)
  const targetRole = mapStatusToRole(targetStatus)

  // Define valid transitions
  const validTransitions = {
    [CORE_ROLES.STUDENT]: [
      CORE_ROLES.STUDENT_AMBASSADOR,
      CORE_ROLES.AMBASSADOR,
    ],
    [CORE_ROLES.STUDENT_AMBASSADOR]: [
      CORE_ROLES.AMBASSADOR,
      CORE_ROLES.CORE_MEMBER,
    ],
    [CORE_ROLES.AMBASSADOR]: [CORE_ROLES.CORE_MEMBER],
    [CORE_ROLES.CORE_MEMBER]: [], // Core members can't transition upward
  }

  return validTransitions[currentRole]?.includes(targetRole) || false
}
