import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrophy,
  faCalendarAlt,
  faMapMarkerAlt,
  faCheckCircle,
  faSpinner,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'
import MobileMoneyPayment from '../PaymentSystem/MobileMoneyPayment'
import { useAuth } from '../../utils/firebase/auth'

/**
 * Competition Registration Component
 * Allows users to register for academic competitions with integrated payment
 */
const CompetitionRegistration = ({ competitionId }) => {
  const { authUser, userProfile } = useAuth()
  const [competition, setCompetition] = useState(null)
  const [formData, setFormData] = useState({
    studentName: '',
    school: '',
    grade: '',
    phoneNumber: '',
    email: '',
    guardian: '',
    guardianContact: '',
  })
  const [step, setStep] = useState(1) // 1: Details, 2: Form, 3: Payment, 4: Confirmation
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const [isRegistered, setIsRegistered] = useState(false)
  const [hasCompletedPayment, setHasCompletedPayment] = useState(false)
  const [registrationId, setRegistrationId] = useState('')

  // Fetch competition details
  useEffect(() => {
    // Mock API call - would be replaced with real data fetch
    // TODO: Replace with actual API call to fetch competition data
    const fetchCompetitionData = () => {
      setTimeout(() => {
        // Sample competition data
        const mockCompetition = {
          id: 'comp-2023-math-olympiad',
          title: 'National Mathematics Olympiad 2023',
          description:
            'A prestigious competition for high school students to showcase their mathematical skills. Winners receive scholarships and national recognition.',
          category: 'Mathematics',
          eligibility:
            'Form 4-Upper Sixth (Anglophone) / 2nde-Terminale (Francophone)',
          registrationFee: 3000, // XAF
          registrationDeadline: '2023-12-15',
          competitionDate: '2024-01-25',
          venues: [
            'University of Yaoundé I - Yaoundé',
            'University of Buea - Buea',
            'University of Bamenda - Bamenda',
            'University of Douala - Douala',
          ],
          prizes: [
            { position: '1st Prize', award: '500,000 XAF + Scholarship' },
            { position: '2nd Prize', award: '300,000 XAF + Certificate' },
            { position: '3rd Prize', award: '150,000 XAF + Certificate' },
            { position: 'Top 10', award: 'Certificate of Excellence' },
          ],
          registeredCount: 287,
          maxParticipants: 500,
          organizer: 'KET Academy & Ministry of Secondary Education',
          syllabus: [
            'Algebra',
            'Calculus',
            'Geometry',
            'Number Theory',
            'Probability & Statistics',
          ],
          isActive: true,
          isVirtual: false,
        }

        setCompetition(mockCompetition)
        setIsLoading(false)

        // Pre-fill form data if user is logged in
        if (userProfile) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            studentName: userProfile.displayName || '',
            email: userProfile.email || '',
            school: userProfile.school || '',
            grade: userProfile.grade || '',
          }))
        }

        // Check if user is already registered
        if (authUser) {
          // Mock API call to check registration status
          // TODO: Replace with actual API call to check registration status
          setTimeout(() => {
            // For demonstration, randomly set as registered or not
            const isAlreadyRegistered = false // Math.random() > 0.7;
            setIsRegistered(isAlreadyRegistered)
            if (isAlreadyRegistered) {
              setRegistrationId('REG-' + Math.floor(Math.random() * 100000))
              setHasCompletedPayment(Math.random() > 0.3) // 70% chance of completed payment
            }
          }, 500)
        }
      }, 1000)
    }

    fetchCompetitionData()
  }, [authUser, userProfile, competitionId])

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  // Validate form data
  const validateForm = () => {
    if (!formData.studentName || formData.studentName.trim() === '') {
      setError('Please enter student name')
      return false
    }
    if (!formData.school || formData.school.trim() === '') {
      setError('Please enter school name')
      return false
    }
    if (!formData.grade || formData.grade.trim() === '') {
      setError('Please select grade/class')
      return false
    }
    if (!formData.phoneNumber || formData.phoneNumber.trim() === '') {
      setError('Please enter phone number')
      return false
    }
    if (!formData.email || formData.email.trim() === '') {
      setError('Please enter email')
      return false
    }
    if (!formData.guardian || formData.guardian.trim() === '') {
      setError('Please enter parent/guardian name')
      return false
    }
    if (!formData.guardianContact || formData.guardianContact.trim() === '') {
      setError('Please enter parent/guardian contact')
      return false
    }

    setError('')
    return true
  }

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault()
    if (validateForm()) {
      // Generate registration ID
      const newRegistrationId = 'REG-' + Math.floor(Math.random() * 100000)
      setRegistrationId(newRegistrationId)

      // Move to payment step
      setStep(3)
    }
  }

  // Handle payment success
  const handlePaymentSuccess = (paymentDetails) => {
    // In a real app, this would call an API to record the payment
    // TODO: Implement actual payment recording API call
    setTimeout(() => {
      setIsRegistered(true)
      setHasCompletedPayment(true)
      setStep(4) // Move to confirmation
    }, 1000)
  }

  // Handle payment error
  const handlePaymentError = (error) => {
    console.error('Payment error:', error)
    setError('Payment could not be processed. Please try again.')
  }

  // Format date string
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return new Date(dateString).toLocaleDateString('en-GB', options)
  }

  // Check if registration deadline has passed
  const isDeadlinePassed = () => {
    if (!competition) return false
    const deadline = new Date(competition.registrationDeadline)
    const now = new Date()
    return now > deadline
  }

  // Check if competition is full
  const isCompetitionFull = () => {
    if (!competition) return false
    return competition.registeredCount >= competition.maxParticipants
  }

  // Calculate days remaining for registration
  const getDaysRemaining = () => {
    if (!competition) return 0
    const deadline = new Date(competition.registrationDeadline)
    const now = new Date()
    const diffTime = Math.abs(deadline - now)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays
  }

  // TODO: Add error boundary to handle unexpected errors gracefully
  // TODO: Add form validation for email and phone number formats
  // TODO: Implement proper loading states with skeleton UI

  // Render loading state
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          className="text-blue-500 text-2xl"
        />
      </div>
    )
  }

  // Render error if competition not found
  if (!competition) {
    return (
      <div className="text-center py-10">
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="text-yellow-500 text-4xl mb-4"
        />
        <h2 className="text-xl font-bold mb-2">Competition Not Found</h2>
        <p className="text-gray-600">
          The competition you're looking for doesn't exist or has been removed.
        </p>
      </div>
    )
  }

  // Render competition details and registration form
  return (
    <div className="max-w-4xl mx-auto">
      {/* Competition Header */}
      <div className="bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg p-4 sm:p-6 text-white mb-4 sm:mb-6">
        <div className="flex flex-col sm:flex-row items-center sm:items-start">
          <div className="mb-3 sm:mb-0 sm:mr-4">
            <div className="bg-white p-3 rounded-full text-blue-600">
              <FontAwesomeIcon
                icon={faTrophy}
                className="text-2xl"
                aria-hidden="true"
              />
            </div>
          </div>

          <div className="text-center sm:text-left">
            <h1 className="text-xl sm:text-2xl font-bold mb-2 px-2 sm:px-0">
              {competition.title}
            </h1>
            <p className="mb-4 text-sm sm:text-base px-2 sm:px-0">
              {competition.description}
            </p>

            <div className="grid grid-cols-1 gap-2 mb-4">
              <div className="flex items-center justify-center sm:justify-start">
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  className="mr-2"
                  aria-hidden="true"
                />
                <span className="text-sm sm:text-base">
                  Competition Date: {formatDate(competition.competitionDate)}
                </span>
              </div>
              <div className="flex items-center justify-center sm:justify-start">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="mr-2"
                  aria-hidden="true"
                />
                <span className="text-sm sm:text-base">
                  Multiple Venues Across Cameroon
                </span>
              </div>
            </div>

            <div className="flex flex-wrap items-center justify-center sm:justify-start gap-2">
              <div className="bg-blue-500 bg-opacity-20 px-3 py-1 rounded-full">
                <span className="text-xs sm:text-sm font-medium">
                  {competition.registeredCount} Registered
                </span>
              </div>

              <div className="bg-blue-500 bg-opacity-20 px-3 py-1 rounded-full">
                <span className="text-xs sm:text-sm font-medium">
                  Registration Fee:{' '}
                  {competition.registrationFee.toLocaleString()} XAF
                </span>
              </div>

              <div className="bg-blue-500 bg-opacity-20 px-3 py-1 rounded-full">
                <span className="text-xs sm:text-sm font-medium">
                  Category: {competition.category}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Registration Status for returning users */}
      {isRegistered && (
        <div
          className={`mb-6 p-4 rounded-lg border ${
            hasCompletedPayment
              ? 'bg-green-50 border-green-200'
              : 'bg-yellow-50 border-yellow-200'
          }`}
          role="alert"
          aria-live="polite"
        >
          <div className="flex items-start">
            <FontAwesomeIcon
              icon={hasCompletedPayment ? faCheckCircle : faExclamationTriangle}
              className={`mt-1 mr-3 ${
                hasCompletedPayment ? 'text-green-500' : 'text-yellow-500'
              }`}
              aria-hidden="true"
            />

            <div>
              <h3 className="font-semibold text-lg mb-1">
                {hasCompletedPayment
                  ? 'Registration Complete!'
                  : 'Registration Pending Payment'}
              </h3>
              <p className="text-sm mb-2">
                Registration ID:{' '}
                <span className="font-medium">{registrationId}</span>
              </p>

              {hasCompletedPayment ? (
                <p className="text-sm">
                  You're all set for the {competition.title}. Check your email
                  for details about the competition venue and schedule.
                </p>
              ) : (
                <div>
                  <p className="text-sm mb-3">
                    Your registration is pending payment. Please complete your
                    payment to secure your spot.
                  </p>
                  <button
                    onClick={() => setStep(3)}
                    className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md text-sm"
                    aria-label="Complete payment"
                  >
                    Complete Payment
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Registration Steps */}
      {!isRegistered && (
        <div className="mb-4 sm:mb-6 overflow-x-auto">
          <div className="flex items-center justify-between min-w-max pb-2">
            <div
              className={`flex flex-col items-center ${
                step >= 1 ? 'text-blue-600' : 'text-gray-400'
              }`}
            >
              <div
                className={`w-7 h-7 sm:w-8 sm:h-8 rounded-full flex items-center justify-center mb-1 ${
                  step >= 1 ? 'bg-blue-600 text-white' : 'bg-gray-200'
                }`}
              >
                1
              </div>
              <span className="text-xxs sm:text-xs font-medium">
                Competition Details
              </span>
            </div>

            <div
              className={`flex-1 h-1 mx-1 sm:mx-2 ${
                step >= 2 ? 'bg-blue-600' : 'bg-gray-200'
              }`}
            ></div>

            <div
              className={`flex flex-col items-center ${
                step >= 2 ? 'text-blue-600' : 'text-gray-400'
              }`}
            >
              <div
                className={`w-7 h-7 sm:w-8 sm:h-8 rounded-full flex items-center justify-center mb-1 ${
                  step >= 2 ? 'bg-blue-600 text-white' : 'bg-gray-200'
                }`}
              >
                2
              </div>
              <span className="text-xxs sm:text-xs font-medium">
                Registration Form
              </span>
            </div>

            <div
              className={`flex-1 h-1 mx-1 sm:mx-2 ${
                step >= 3 ? 'bg-blue-600' : 'bg-gray-200'
              }`}
            ></div>

            <div
              className={`flex flex-col items-center ${
                step >= 3 ? 'text-blue-600' : 'text-gray-400'
              }`}
            >
              <div
                className={`w-7 h-7 sm:w-8 sm:h-8 rounded-full flex items-center justify-center mb-1 ${
                  step >= 3 ? 'bg-blue-600 text-white' : 'bg-gray-200'
                }`}
              >
                3
              </div>
              <span className="text-xxs sm:text-xs font-medium">Payment</span>
            </div>

            <div
              className={`flex-1 h-1 mx-1 sm:mx-2 ${
                step >= 4 ? 'bg-blue-600' : 'bg-gray-200'
              }`}
            ></div>

            <div
              className={`flex flex-col items-center ${
                step >= 4 ? 'text-blue-600' : 'text-gray-400'
              }`}
            >
              <div
                className={`w-7 h-7 sm:w-8 sm:h-8 rounded-full flex items-center justify-center mb-1 ${
                  step >= 4 ? 'bg-blue-600 text-white' : 'bg-gray-200'
                }`}
              >
                4
              </div>
              <span className="text-xxs sm:text-xs font-medium">
                Confirmation
              </span>
            </div>
          </div>
        </div>
      )}

      {/* Registration Content based on step */}
      {!isRegistered && (
        <div className="bg-white rounded-lg shadow-md">
          {step === 1 && (
            <div className="p-4 sm:p-6">
              <h2 className="text-xl font-bold mb-4">Competition Details</h2>

              {/* Eligibility */}
              <div className="mb-4 sm:mb-6">
                <h3 className="font-semibold text-base sm:text-lg mb-2">
                  Eligibility
                </h3>
                <p className="mb-2 text-sm sm:text-base">
                  {competition.eligibility}
                </p>
                <p className="text-xs sm:text-sm text-gray-600">
                  <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
                  All participants must be currently enrolled in a school in
                  Cameroon
                </p>
              </div>

              {/* Important Dates */}
              <div className="mb-4 sm:mb-6">
                <h3 className="font-semibold text-base sm:text-lg mb-2">
                  Important Dates
                </h3>
                <div className="grid grid-cols-1 gap-3">
                  <div className="border border-gray-200 rounded p-3">
                    <p className="font-medium text-sm sm:text-base">
                      Registration Deadline
                    </p>
                    <p className="text-base sm:text-lg">
                      {formatDate(competition.registrationDeadline)}
                    </p>
                    {!isDeadlinePassed() && (
                      <p className="text-xs sm:text-sm text-green-600">
                        {getDaysRemaining()} days remaining
                      </p>
                    )}
                  </div>
                  <div className="border border-gray-200 rounded p-3">
                    <p className="font-medium text-sm sm:text-base">
                      Competition Date
                    </p>
                    <p className="text-base sm:text-lg">
                      {formatDate(competition.competitionDate)}
                    </p>
                  </div>
                </div>
              </div>

              {/* Venues */}
              <div className="mb-4 sm:mb-6">
                <h3 className="font-semibold text-base sm:text-lg mb-2">
                  Venues
                </h3>
                <div className="grid grid-cols-1 gap-2">
                  {competition.venues.map((venue, index) => (
                    <div key={index} className="flex items-center">
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        className="text-red-500 mr-2 flex-shrink-0"
                        aria-hidden="true"
                      />
                      <span className="text-sm sm:text-base">{venue}</span>
                    </div>
                  ))}
                </div>
                <p className="text-xs sm:text-sm text-gray-600 mt-2">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="mr-1"
                    aria-hidden="true"
                  />
                  You will be assigned to the venue closest to your location
                </p>
              </div>

              {/* Prizes */}
              <div className="mb-4 sm:mb-6">
                <h3 className="font-semibold text-base sm:text-lg mb-2">
                  Prizes
                </h3>
                <div className="grid grid-cols-1 gap-2">
                  {competition.prizes.map((prize, index) => (
                    <div key={index} className="flex items-center p-2">
                      <div className="w-7 h-7 sm:w-8 sm:h-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center mr-3 flex-shrink-0">
                        {index < 3 ? (
                          <FontAwesomeIcon icon={faTrophy} />
                        ) : (
                          <span className="font-bold">{index + 1}</span>
                        )}
                      </div>
                      <div>
                        <p className="font-medium text-sm sm:text-base">
                          {prize.position}
                        </p>
                        <p className="text-xs sm:text-sm text-gray-600">
                          {prize.award}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Syllabus */}
              <div className="mb-4 sm:mb-6">
                <h3 className="font-semibold text-base sm:text-lg mb-2">
                  Syllabus
                </h3>
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                  {competition.syllabus.map((topic, index) => (
                    <div key={index} className="flex items-center p-1">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="text-green-500 mr-2 flex-shrink-0"
                      />
                      <span className="text-sm sm:text-base">{topic}</span>
                    </div>
                  ))}
                </div>
              </div>

              {/* Registration Status */}
              <div className="mb-4 sm:mb-6 bg-blue-50 p-3 sm:p-4 rounded-lg">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="font-medium text-sm sm:text-base">
                      Registration Status
                    </p>
                    <p className="text-xs sm:text-sm text-gray-600">
                      {competition.registeredCount} of{' '}
                      {competition.maxParticipants} spots filled
                    </p>
                  </div>
                  <div className="w-16 sm:w-24 h-2 bg-gray-200 rounded-full overflow-hidden">
                    <div
                      className="h-full bg-green-500"
                      style={{
                        width: `${
                          (competition.registeredCount /
                            competition.maxParticipants) *
                          100
                        }%`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>

              {/* Next Button */}
              <div>
                <button
                  onClick={() => setStep(2)}
                  disabled={isDeadlinePassed() || isCompetitionFull()}
                  className={`w-full sm:w-auto px-4 sm:px-6 py-2 rounded-md text-sm sm:text-base ${
                    isDeadlinePassed() || isCompetitionFull()
                      ? 'bg-gray-300 cursor-not-allowed'
                      : 'bg-blue-600 hover:bg-blue-700 text-white'
                  }`}
                >
                  {isDeadlinePassed()
                    ? 'Registration Closed'
                    : isCompetitionFull()
                    ? 'Competition Full'
                    : 'Continue Registration'}
                </button>
              </div>

              {/* Warning if deadline passed or competition full */}
              {(isDeadlinePassed() || isCompetitionFull()) && (
                <div
                  className="mt-4 bg-yellow-50 border border-yellow-200 p-3 rounded-lg text-sm"
                  role="alert"
                >
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="text-yellow-500 mr-2"
                    aria-hidden="true"
                  />
                  {isDeadlinePassed()
                    ? 'The registration deadline has passed for this competition.'
                    : 'This competition has reached maximum capacity.'}
                </div>
              )}
            </div>
          )}

          {step === 2 && (
            <div className="p-4 sm:p-6">
              <h2 className="text-xl font-bold mb-4">Registration Form</h2>

              {error && (
                <div
                  className="mb-4 bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg text-sm"
                  role="alert"
                >
                  {error}
                </div>
              )}

              <form onSubmit={handleSubmit}>
                <div className="space-y-4">
                  {/* Student Information */}
                  <div>
                    <h3 className="text-base sm:text-lg font-semibold mb-3">
                      Student Information
                    </h3>
                    <div className="grid grid-cols-1 gap-4">
                      <div>
                        <label
                          htmlFor="studentName"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Full Name <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          id="studentName"
                          name="studentName"
                          value={formData.studentName}
                          onChange={handleInputChange}
                          className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 text-sm sm:text-base"
                          placeholder="Enter your full name"
                          required
                        />
                      </div>

                      <div>
                        <label
                          htmlFor="school"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          School <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          id="school"
                          name="school"
                          value={formData.school}
                          onChange={handleInputChange}
                          className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 text-sm sm:text-base"
                          placeholder="Enter your school name"
                          required
                        />
                      </div>

                      <div>
                        <label
                          htmlFor="grade"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Grade/Class <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          id="grade"
                          name="grade"
                          value={formData.grade}
                          onChange={handleInputChange}
                          className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 text-sm sm:text-base"
                          placeholder="e.g., Form 5, 1ère, etc."
                          required
                        />
                      </div>
                    </div>
                  </div>

                  {/* Contact Information */}
                  <div>
                    <h3 className="text-base sm:text-lg font-semibold mb-3">
                      Contact Information
                    </h3>
                    <div className="grid grid-cols-1 gap-4">
                      <div>
                        <label
                          htmlFor="phoneNumber"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Phone Number <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="tel"
                          id="phoneNumber"
                          name="phoneNumber"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                          className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 text-sm sm:text-base"
                          placeholder="e.g., 677123456"
                          required
                        />
                      </div>

                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Email Address <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 text-sm sm:text-base"
                          placeholder="Enter your email address"
                          required
                        />
                        <p className="mt-1 text-xs text-gray-500">
                          Registration confirmation will be sent to this email
                        </p>
                      </div>

                      <div>
                        <label
                          htmlFor="guardian"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Parent/Guardian Name{' '}
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          id="guardian"
                          name="guardian"
                          value={formData.guardian}
                          onChange={handleInputChange}
                          className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 text-sm sm:text-base"
                          placeholder="Enter parent/guardian name"
                          required
                        />
                      </div>

                      <div>
                        <label
                          htmlFor="guardianContact"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Parent/Guardian Contact{' '}
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="tel"
                          id="guardianContact"
                          name="guardianContact"
                          value={formData.guardianContact}
                          onChange={handleInputChange}
                          className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 text-sm sm:text-base"
                          placeholder="e.g., 677123456"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  {/* Form Actions */}
                  <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-3 pt-4">
                    <button
                      type="button"
                      onClick={() => setStep(1)}
                      className="py-2 px-4 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 text-sm sm:text-base"
                    >
                      Back
                    </button>
                    <button
                      type="submit"
                      className="py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-md text-sm sm:text-base"
                      aria-label="Continue to payment"
                    >
                      Continue to Payment
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}

          {step === 3 && (
            <div className="p-4 sm:p-6">
              <h2 className="text-xl font-bold mb-4">Payment</h2>

              <div className="mb-4 sm:mb-6 bg-blue-50 p-4 rounded-lg text-sm sm:text-base">
                <div className="flex items-start">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="text-blue-500 mr-3 mt-1 flex-shrink-0"
                    aria-hidden="true"
                  />
                  <div>
                    <p className="font-medium mb-2">Payment Information</p>
                    <p>
                      You are registering for{' '}
                      <span className="font-medium">{competition.title}</span>
                    </p>
                    <p className="mt-2">
                      Registration Fee:{' '}
                      <span className="font-bold">
                        {competition.registrationFee.toLocaleString()} XAF
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              {/* Payment Form Component */}
              <MobileMoneyPayment
                amount={competition.registrationFee}
                itemDescription={`Registration for ${competition.title}`}
                onSuccess={handlePaymentSuccess}
                onError={handlePaymentError}
                paymentType="competition"
              />

              <div className="mt-4 text-center">
                <button
                  onClick={() => setStep(2)}
                  className="text-blue-600 hover:text-blue-800 text-sm sm:text-base"
                >
                  Back to Registration Form
                </button>
              </div>
            </div>
          )}

          {step === 4 && (
            <div className="p-4 sm:p-6 text-center">
              <div className="w-16 h-16 mx-auto bg-green-100 rounded-full flex items-center justify-center mb-4">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-green-500 text-2xl"
                />
              </div>

              <h2 className="text-xl font-bold mb-2">Registration Complete!</h2>

              <p className="mb-4 text-sm sm:text-base">
                You have successfully registered for the {competition.title}.
              </p>

              <div className="bg-gray-50 rounded-lg p-4 mb-6 inline-block mx-auto">
                <p className="font-medium text-sm sm:text-base">
                  Registration ID
                </p>
                <p className="text-lg">{registrationId}</p>
              </div>

              <div className="text-left">
                <h3 className="font-medium mb-2 text-sm sm:text-base">
                  Next Steps:
                </h3>
                <ul className="text-xs sm:text-sm space-y-2">
                  <li className="flex items-start">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-green-500 mt-1 mr-2 flex-shrink-0"
                    />
                    <span>Check your email for confirmation and details</span>
                  </li>
                  <li className="flex items-start">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-green-500 mt-1 mr-2 flex-shrink-0"
                    />
                    <span>
                      You will receive your assigned venue 1 week before the
                      competition
                    </span>
                  </li>
                  <li className="flex items-start">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-green-500 mt-1 mr-2 flex-shrink-0"
                    />
                    <span>
                      Prepare for the competition using the syllabus provided
                    </span>
                  </li>
                  <li className="flex items-start">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-green-500 mt-1 mr-2 flex-shrink-0"
                    />
                    <span>
                      Bring your school ID on competition day for verification
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default CompetitionRegistration
