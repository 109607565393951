import React, { useState, useEffect } from 'react'
import MobileMoneyPayment from '../../../components/PaymentSystem/MobileMoneyPayment'
import { useAuth } from '../../../utils/firebase/auth'
import { useRole } from '../../../utils/permissions/RoleContext'

/**
 * Test page for Mobile Money Payment system
 * Allows testing different payment scenarios
 */
const PaymentTest = () => {
  const { authUser } = useAuth()
  const { userRole } = useRole()
  const [paymentAmount, setPaymentAmount] = useState(1000)
  const [paymentDescription, setPaymentDescription] = useState('Test Payment')
  const [paymentToken, setPaymentToken] = useState('')
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [paymentError, setPaymentError] = useState('')
  const [selectedScenario, setSelectedScenario] = useState('subscription')
  const [paymentProvider, setPaymentProvider] = useState('mtn')
  const [showDevNotes, setShowDevNotes] = useState(true)

  // Generate a unique payment token for testing
  useEffect(() => {
    const timestamp = new Date().getTime()
    const randomStr = Math.random().toString(36).substring(2, 8)
    setPaymentToken(`test-payment-${timestamp}-${randomStr}`)
  }, [selectedScenario])

  // Payment test scenarios
  const scenarios = {
    subscription: {
      title: 'Premium Subscription Payment',
      amounts: [
        { value: 1000, label: '1,000 XAF - Weekly Premium' },
        { value: 3500, label: '3,500 XAF - Monthly Premium' },
        { value: 20000, label: '20,000 XAF - 6-Month Premium' },
        { value: 35000, label: '35,000 XAF - Annual Premium' },
      ],
      description: 'Test subscription payment flow for premium content access',
    },
    competition: {
      title: 'Competition Registration',
      amounts: [
        { value: 2000, label: '2,000 XAF - Local Competition' },
        { value: 5000, label: '5,000 XAF - Regional Competition' },
        { value: 10000, label: '10,000 XAF - National Competition' },
      ],
      description: 'Test competition registration payment flow',
    },
    custom: {
      title: 'Custom Payment',
      amounts: [
        { value: 500, label: '500 XAF' },
        { value: 1000, label: '1,000 XAF' },
        { value: 5000, label: '5,000 XAF' },
        { value: 10000, label: '10,000 XAF' },
        { value: 50000, label: '50,000 XAF' },
      ],
      description: 'Test custom payment amount with any description',
    },
  }

  // Handle scenario change
  const handleScenarioChange = (e) => {
    const newScenario = e.target.value
    setSelectedScenario(newScenario)
    const firstAmount = scenarios[newScenario].amounts[0].value
    setPaymentAmount(firstAmount)
    setPaymentDescription(
      `${scenarios[newScenario].title} - ${firstAmount} XAF`
    )
  }

  // Handle amount change
  const handleAmountChange = (e) => {
    const newAmount = parseInt(e.target.value, 10)
    setPaymentAmount(newAmount)

    // Update description based on selected scenario and amount
    if (selectedScenario !== 'custom') {
      const selectedOption = scenarios[selectedScenario].amounts.find(
        (option) => option.value === newAmount
      )
      if (selectedOption) {
        setPaymentDescription(
          `${scenarios[selectedScenario].title} - ${selectedOption.label}`
        )
      }
    }
  }

  // Handle description change (only for custom payments)
  const handleDescriptionChange = (e) => {
    setPaymentDescription(e.target.value)
  }

  // Handle payment provider change
  const handleProviderChange = (e) => {
    setPaymentProvider(e.target.value)
  }

  // Handle payment success
  const handlePaymentSuccess = (data) => {
    setPaymentSuccess(true)
    setPaymentError('')
    console.log('Payment successful:', data)
  }

  // Handle payment error
  const handlePaymentError = (error) => {
    setPaymentSuccess(false)
    setPaymentError(error.message || 'Payment failed. Please try again.')
    console.error('Payment error:', error)
  }

  return (
    <div className="container mx-auto py-10 px-4">
      <h1 className="text-3xl font-bold mb-6">Mobile Money Payment Testing</h1>

      {showDevNotes && (
        <div className="mb-6 bg-blue-50 border border-blue-200 rounded-lg p-4">
          <div className="flex justify-between items-center mb-2">
            <h3 className="font-medium text-blue-800">Developer Notes</h3>
            <button
              onClick={() => setShowDevNotes(false)}
              className="text-blue-500 text-sm hover:text-blue-700"
            >
              Hide
            </button>
          </div>
          <ul className="text-sm text-blue-700 space-y-1">
            <li>• This is a test environment for payment integration</li>
            <li>• All payments are simulated and no actual charges are made</li>
            <li>• Use "1234" as the OTP code for successful payments</li>
            <li>• Any other OTP will simulate a failure scenario</li>
            <li>
              • Current user role:{' '}
              <span className="font-medium">{userRole || 'Not logged in'}</span>
            </li>
            <li>
              • Test payment token:{' '}
              <span className="font-mono text-xs">{paymentToken}</span>
            </li>
          </ul>
        </div>
      )}

      {!authUser ? (
        <div className="bg-yellow-50 border border-yellow-200 p-4 rounded-lg mb-6">
          <p className="text-yellow-800">
            Please log in to test the payment system. The experience may vary
            based on your account type.
          </p>
        </div>
      ) : (
        <div className="mb-8 bg-white rounded-lg shadow-md p-6">
          <h3 className="text-lg font-semibold mb-4">
            Payment Test Configuration
          </h3>

          <div className="grid md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="scenario"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Test Scenario
              </label>
              <select
                id="scenario"
                value={selectedScenario}
                onChange={handleScenarioChange}
                className="block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              >
                {Object.keys(scenarios).map((key) => (
                  <option key={key} value={key}>
                    {scenarios[key].title}
                  </option>
                ))}
              </select>
              <p className="mt-2 text-sm text-gray-500">
                {scenarios[selectedScenario].description}
              </p>
            </div>

            <div>
              <label
                htmlFor="provider"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Payment Provider
              </label>
              <select
                id="provider"
                value={paymentProvider}
                onChange={handleProviderChange}
                className="block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="mtn">MTN Mobile Money</option>
                <option value="orange">Orange Money</option>
              </select>
            </div>

            <div>
              <label
                htmlFor="amount"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Payment Amount (XAF)
              </label>
              <select
                id="amount"
                value={paymentAmount}
                onChange={handleAmountChange}
                className="block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              >
                {scenarios[selectedScenario].amounts.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            {selectedScenario === 'custom' && (
              <div>
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Payment Description
                </label>
                <input
                  type="text"
                  id="description"
                  value={paymentDescription}
                  onChange={handleDescriptionChange}
                  className="block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter payment description"
                />
              </div>
            )}
          </div>

          {/* Payment Status Display */}
          {paymentSuccess && (
            <div className="mt-4 mb-6 bg-green-50 border border-green-200 p-4 rounded">
              <p className="text-green-800 font-medium">
                Payment Successful! Transaction Reference: TEST-
                {Math.random().toString(36).substring(2, 10).toUpperCase()}
              </p>
            </div>
          )}

          {paymentError && (
            <div className="mt-4 mb-6 bg-red-50 border border-red-200 p-4 rounded">
              <p className="text-red-800 font-medium">
                Payment Error: {paymentError}
              </p>
            </div>
          )}
        </div>
      )}

      {/* Payment Component */}
      <MobileMoneyPayment
        amount={paymentAmount}
        description={paymentDescription}
        onSuccess={handlePaymentSuccess}
        onError={handlePaymentError}
        testMode={true}
        provider={paymentProvider}
        paymentToken={paymentToken}
      />
    </div>
  )
}

export default PaymentTest
