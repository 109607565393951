import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth'
import { createContext, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { auth, firestore } from './index'
import { Alert } from '../../components'
import { useNavigate } from 'react-router-dom'
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore'

/*
 * Handle paswordless sign in
 */
// Create a custom hook for handling navigation in non-component functions
let navigationFunction = null
export const setNavigationFunction = (navigate) => {
  navigationFunction = navigate
}

export async function passwordlessSignin() {
  if (!isSignInWithEmailLink(auth, window.location.href)) return false

  let email = localStorage.getItem('KET-email-for-sign-in')
  let signupData = localStorage.getItem('KET-signup-data')

  if (!email) {
    const { value: inputEmail } = await Alert.fire({
      title: 'Enter your email address',
      input: 'email',
      inputLabel: 'Your email address',
      inputPlaceholder: 'Enter your email address',
      text: 'A secure sign-in link will be sent to your email address. Please check your inbox (and spam folder) after submitting.',
      validationMessage: 'Please enter a valid email',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    })
    email = inputEmail
  }

  try {
    const { user } = await signInWithEmailLink(
      auth,
      email,
      window.location.href
    )

    if (signupData) {
      const profileData = JSON.parse(signupData)
      const userProfileData = {
        uid: user.uid,
        fullName: profileData.name || '',
        email: user.email,
        bio: profileData.bio || '',
        region: profileData.region || '',
        city: profileData.city || '',
        edSystem: profileData.edSystem || '',
        level: profileData.level || '',
        school: profileData.school || '',
        subjects: profileData.subjects || [],
        profileCompleted: false,
        createdAt: new Date(),
        lastUpdated: new Date(),
      }

      await addDoc(collection(firestore, 'userProfiles'), userProfileData)
      if (navigationFunction) {
        navigationFunction('/dashboard/profile?newUser=true')
      }
    } else {
      if (navigationFunction) {
        navigationFunction('/dashboard/home')
      }
    }

    toast.success('Signed in successfully!')
    return true
  } catch (error) {
    console.error('Sign-in error:', error)
    toast.error(error.message || 'Error signing in')
    return false
  } finally {
    localStorage.removeItem('KET-email-for-sign-in')
    localStorage.removeItem('KET-signup-data')
  }
}

/*
 ** setup Providers and hooks for user
 */
const AuthContext = createContext({
  authUser: null,
  userProfile: null,
  isLoading: true,
})

export const useAuth = () => useContext(AuthContext)

export const AuthUserProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null)
  const [userProfile, setUserProfile] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // Check if profile exists and is complete
        const userProfileRef = collection(firestore, 'userProfiles')
        const q = query(userProfileRef, where('uid', '==', user.uid))
        const querySnapshot = await getDocs(q)

        if (querySnapshot.empty) {
          // No profile exists, set default profile with STUDENT status
          setUserProfile({
            uid: user.uid,
            email: user.email,
            status: 'STUDENT',
            displayName: user.displayName || user.email,
            profileCompleted: false,
          })
        } else {
          // Profile exists, set it in state
          const profileData = querySnapshot.docs[0].data()
          setUserProfile(profileData)
        }
        setAuthUser(user)
      } else {
        setAuthUser(null)
        setUserProfile(null)
      }
      setIsLoading(false)
    })

    return () => unsubscribe()
  }, [navigate])

  return (
    <AuthContext.Provider value={{ authUser, userProfile, isLoading }}>
      {children}
    </AuthContext.Provider>
  )
}
