/**
 * Resource Model
 *
 * This file defines the structure of a resource object in the KET Academy platform.
 * It serves as documentation for the expected shape of the data.
 *
 * TODO: Consider converting this file to TypeScript for better type safety and IDE support.
 */

/**
 * @typedef {Object} Resource
 * @property {string} id - Unique identifier for the resource
 * @property {string} title - Title of the resource
 * @property {string} description - Description of the resource
 * @property {string} type - Type of resource ('readings', 'videos', 'docs', 'assignments', 'exam_prep')
 * @property {string} courseId - ID of the course this resource belongs to
 * @property {string} subject - Subject/topic this resource covers
 * @property {Object} createdAt - Firebase timestamp of when the resource was created
 * @property {Object} updatedAt - Firebase timestamp of when the resource was last updated
 * @property {string} fileUrl - URL to access the file in Firebase Storage
 * @property {string} filePath - Path to the file in Firebase Storage
 * @property {string} fileType - File extension/type (pdf, docx, mp4, etc.)
 * @property {number} fileSize - Size of the file in bytes
 * @property {string} fileName - Original name of the uploaded file
 * @property {string[]} [sharedWith] - Array of user IDs this resource is shared with
 * @property {boolean} [isPublic] - Whether this resource is publicly accessible
 * @property {string} [uploadedBy] - ID of the user who uploaded the resource
 * @property {Object} [metadata] - Additional metadata specific to the file type
 *
 * @property {string} edSystem - Educational system ('Anglophone', 'Francophone', or 'Both')
 * @property {string} academicLevel - Academic level (e.g., 'Form 5', 'Terminale')
 * @property {string} secondaryType - Secondary education level ('Lower Secondary' or 'Upper Secondary')
 * @property {string[]} [examPreparation] - Array of exams this resource helps prepare for (e.g., 'GCE O-Level', 'Baccalauréat')
 * @property {string} [language] - Language of the resource ('English', 'French', or 'Bilingual')
 * @property {string} [specializationStream] - Stream/specialization this resource applies to (e.g., 'S1', 'A', 'D')
 * @property {number} [difficulty] - Difficulty level (1-5) where 1 is easiest and 5 is most difficult
 * @property {string[]} [tags] - Array of tags for improved searchability
 * @property {boolean} [offlineAccess] - Whether this resource is available for offline access
 * @property {number} [estimatedTimeMinutes] - Estimated time to complete in minutes
 */

/**
 * @typedef {Object} ResourceFilters
 * @property {string} [type] - Filter by resource type
 * @property {string} [courseId] - Filter by course ID
 * @property {string} [subject] - Filter by subject
 * @property {string} [fileType] - Filter by file type
 * @property {string} [dateFrom] - Filter resources created after this date
 * @property {string} [dateTo] - Filter resources created before this date
 * @property {string} [uploadedBy] - Filter by uploader ID
 * @property {boolean} [isPublic] - Filter by public/private status
 * @property {string} [edSystem] - Filter by educational system
 * @property {string} [academicLevel] - Filter by academic level
 * @property {string} [secondaryType] - Filter by secondary type
 * @property {string} [examPreparation] - Filter by exam preparation
 * @property {string} [language] - Filter by language
 * @property {string} [specializationStream] - Filter by specialization stream
 * @property {number} [difficulty] - Filter by difficulty level
 * @property {string} [tag] - Filter by tag
 */

// Dummy exports for JSDoc types to ensure they are recognized in other files
/**
 * @type {Resource}
 */
export const ResourceType = null

/**
 * @type {ResourceFilters}
 */
export const ResourceFiltersType = null

// Export constants for resource types for consistent usage throughout the app
export const RESOURCE_TYPES = {
  READINGS: 'readings',
  VIDEOS: 'videos',
  DOCUMENTS: 'docs',
  ASSIGNMENTS: 'assignments',
  EXAM_PREP: 'exam_prep',
}

// Export constants for resource file types for consistent usage
export const FILE_TYPES = {
  PDF: 'pdf',
  WORD: 'docx',
  POWERPOINT: 'pptx',
  VIDEO: 'mp4',
  YOUTUBE: 'youtube', // YouTube video type
  AUDIO: 'mp3',
  IMAGE: 'image', // General category for images
  OTHER: 'other',
}

// Educational systems
export const EDUCATIONAL_SYSTEMS = {
  ANGLOPHONE: 'Anglophone',
  FRANCOPHONE: 'Francophone',
  BOTH: 'Both',
}

// Examination types
export const EXAM_TYPES = {
  GCE_OLEVEL: 'GCE O-Level',
  GCE_ALEVEL: 'GCE A-Level',
  BEPC: 'BEPC',
  PROBATOIRE: 'Probatoire',
  BACCALAUREAT: 'Baccalauréat',
}

// Languages
export const LANGUAGES = {
  ENGLISH: 'English',
  FRENCH: 'French',
  BILINGUAL: 'Bilingual',
}

// Secondary types
export const SECONDARY_TYPES = {
  LOWER: 'Lower Secondary',
  UPPER: 'Upper Secondary',
}

/**
 * Helper function to get the appropriate icon type based on resource type
 * @param {string} resourceType - The type of resource
 * @returns {string} - The corresponding icon type
 */
export const getResourceIcon = (resourceType) => {
  // Handle null/undefined resourceType safely
  if (!resourceType) return RESOURCE_TYPES.DOCUMENTS

  // Convert to uppercase and handle potential issues
  try {
    return (
      RESOURCE_TYPES[resourceType.toUpperCase()] || RESOURCE_TYPES.DOCUMENTS
    )
  } catch (error) {
    // Fallback to default if there's any issue
    return RESOURCE_TYPES.DOCUMENTS
  }
}

/**
 * Helper function to determine file type from file extension or URL
 * @param {string} fileName - Name of the file with extension or URL
 * @returns {string} - Standardized file type
 */
export const determineFileType = (fileName) => {
  if (!fileName) return FILE_TYPES.OTHER

  // Check if it's a YouTube URL
  if (fileName.includes('youtube.com') || fileName.includes('youtu.be')) {
    return FILE_TYPES.YOUTUBE
  }

  // TODO: Add better error handling for cases where fileName doesn't have an extension
  let extension = ''
  try {
    extension = fileName.split('.').pop().toLowerCase()
  } catch (error) {
    return FILE_TYPES.OTHER
  }

  if (['pdf'].includes(extension)) {
    return FILE_TYPES.PDF
  } else if (['doc', 'docx'].includes(extension)) {
    return FILE_TYPES.WORD
  } else if (['ppt', 'pptx'].includes(extension)) {
    return FILE_TYPES.POWERPOINT
  } else if (['mp4', 'mov', 'avi', 'webm'].includes(extension)) {
    return FILE_TYPES.VIDEO
  } else if (['mp3', 'wav', 'ogg'].includes(extension)) {
    return FILE_TYPES.AUDIO
  } else if (['jpg', 'jpeg', 'png', 'gif', 'svg'].includes(extension)) {
    return FILE_TYPES.IMAGE
  } else {
    return FILE_TYPES.OTHER
  }
}

// Create a module object and then export it as default
const ResourceModule = {
  // Type definitions (exported as null for JSDoc usage)
  ResourceType,
  ResourceFiltersType,

  // Constants
  RESOURCE_TYPES,
  FILE_TYPES,
  EDUCATIONAL_SYSTEMS,
  EXAM_TYPES,
  LANGUAGES,
  SECONDARY_TYPES,

  // Helper functions
  getResourceIcon,
  determineFileType,
}

export default ResourceModule
