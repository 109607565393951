import React from 'react'
import { useFeatureFlags } from '../../../utils/permissions/FeatureFlags'
import { useRole } from '../../../utils/permissions/RoleContext'
import featureRegistry from '../../../utils/permissions/FeatureFlags'

/**
 * Component to test and view the available feature flags for the current user
 */
const FeatureFlagTest = () => {
  const { hasFeature } = useFeatureFlags()
  const { userRole, userProfile, checkPermission } = useRole()

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8">
        Feature Flag Testing Page
      </h1>

      {/* Current User Info */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4">Current User Information</h2>
        <p>
          <strong>Role:</strong> {userRole || 'Not set'}
        </p>
        <p>
          <strong>Status:</strong> {userProfile?.status || 'Not set'}
        </p>
      </div>

      {/* Feature Flags */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4">Feature Flags</h2>
        <p className="mb-4">
          These flags control access to various features based on your
          permissions.
        </p>

        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mb-6">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Feature Flag
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Description
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Required Permissions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {Object.entries(featureRegistry).map(([key, config]) => (
                <tr key={key} className={hasFeature(key) ? 'bg-green-50' : ''}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {key}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm">
                    {hasFeature(key) ? (
                      <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                        Enabled
                      </span>
                    ) : (
                      <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                        Disabled
                      </span>
                    )}
                  </td>
                  <td className="whitespace-normal px-3 py-4 text-sm text-gray-500 max-w-xs">
                    {config.description}
                  </td>
                  <td className="whitespace-normal px-3 py-4 text-sm text-gray-500">
                    <div className="flex flex-wrap gap-1">
                      {Array.isArray(config.requiredPermissions) ? (
                        config.requiredPermissions.map((permission) => (
                          <span
                            key={permission}
                            className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${
                              checkPermission(permission)
                                ? 'bg-blue-100 text-blue-800'
                                : 'bg-gray-100 text-gray-800'
                            }`}
                          >
                            {permission}
                          </span>
                        ))
                      ) : (
                        <span
                          className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${
                            checkPermission(config.requiredPermissions)
                              ? 'bg-blue-100 text-blue-800'
                              : 'bg-gray-100 text-gray-800'
                          }`}
                        >
                          {config.requiredPermissions}
                        </span>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Feature Usage Examples */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4">Feature Usage Examples</h2>

        {/* Content Creation */}
        {hasFeature('CONTENT_CREATION') && (
          <div className="p-4 mb-4 border border-green-200 rounded bg-green-50">
            <h3 className="font-medium text-green-800">
              Content Creation Tools
            </h3>
            <p className="text-green-700">
              You can create new educational content for the platform.
            </p>
            <button className="mt-2 px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700">
              Create New Content
            </button>
          </div>
        )}

        {/* Analytics */}
        {hasFeature('REGIONAL_ANALYTICS') && (
          <div className="p-4 mb-4 border border-blue-200 rounded bg-blue-50">
            <h3 className="font-medium text-blue-800">Regional Analytics</h3>
            <p className="text-blue-700">
              You can view statistics for your assigned region.
            </p>
            <button className="mt-2 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">
              View Regional Reports
            </button>
          </div>
        )}

        {/* Admin Features */}
        {hasFeature('USER_MANAGEMENT') && (
          <div className="p-4 mb-4 border border-purple-200 rounded bg-purple-50">
            <h3 className="font-medium text-purple-800">User Management</h3>
            <p className="text-purple-700">
              You can manage users on the platform.
            </p>
            <button className="mt-2 px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700">
              Manage Users
            </button>
          </div>
        )}

        {/* Advanced Features */}
        {hasFeature('AI_TOOLS') && (
          <div className="p-4 mb-4 border border-indigo-200 rounded bg-indigo-50">
            <h3 className="font-medium text-indigo-800">AI-Powered Tools</h3>
            <p className="text-indigo-700">
              You have access to AI-powered educational tools.
            </p>
            <button className="mt-2 px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700">
              Open AI Tools
            </button>
          </div>
        )}

        {/* Feature not available example */}
        {!hasFeature('ADVANCED_REPORTING') && (
          <div className="p-4 border border-gray-200 rounded bg-gray-50">
            <h3 className="font-medium text-gray-800">Advanced Reporting</h3>
            <p className="text-gray-700">
              Advanced reporting tools are currently unavailable for your role
              or are disabled globally.
            </p>
            <button
              disabled
              className="mt-2 px-4 py-2 bg-gray-400 text-white rounded cursor-not-allowed"
            >
              Advanced Reports Unavailable
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default FeatureFlagTest
