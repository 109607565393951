import React, { useState } from 'react'
import CompetitionRegistration from '../../../components/Competitions/CompetitionRegistration'
import { useAuth } from '../../../utils/firebase/auth'
import { useRole } from '../../../utils/permissions/RoleContext'

/**
 * Test page for Competition Registration system
 * Allows testing different competition registration scenarios
 */
const CompetitionTest = () => {
  const { authUser } = useAuth()
  const { userRole } = useRole()
  const [selectedCompetition, setSelectedCompetition] =
    useState('math-olympiad')

  // Test competition scenarios
  const testCompetitions = [
    {
      id: 'math-olympiad',
      name: 'National Mathematics Olympiad 2023',
      description: 'For high school students in mathematics',
    },
    {
      id: 'science-fair',
      name: 'Science & Innovation Fair 2023',
      description: 'Project-based science competition',
    },
    {
      id: 'literature-contest',
      name: 'Anglophone Literature Competition',
      description: 'Essay and creative writing contest',
    },
    {
      id: 'french-olympiad',
      name: 'Francophone Language Olympiad',
      description: 'French language proficiency competition',
    },
  ]

  // Handle competition selection
  const handleCompetitionChange = (e) => {
    setSelectedCompetition(e.target.value)
  }

  // Get the current competition info
  const getCurrentCompetition = () => {
    return (
      testCompetitions.find((c) => c.id === selectedCompetition) ||
      testCompetitions[0]
    )
  }

  return (
    <div className="container mx-auto py-10 px-4">
      <h1 className="text-3xl font-bold mb-6">
        Competition Registration Testing
      </h1>

      {!authUser ? (
        <div className="bg-yellow-50 border border-yellow-200 p-4 rounded-lg mb-6">
          <p className="text-yellow-800">
            Please log in to test the competition registration system. The
            experience may vary based on your account type.
          </p>
        </div>
      ) : (
        <div className="mb-8 bg-white rounded-lg shadow-md p-6">
          <h3 className="text-lg font-semibold mb-4">
            Competition Test Configuration
          </h3>

          <div className="mb-4">
            <label
              htmlFor="competition"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Select Competition
            </label>
            <select
              id="competition"
              value={selectedCompetition}
              onChange={handleCompetitionChange}
              className="block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            >
              {testCompetitions.map((competition) => (
                <option key={competition.id} value={competition.id}>
                  {competition.name}
                </option>
              ))}
            </select>
            <p className="mt-2 text-sm text-gray-500">
              {getCurrentCompetition().description}
            </p>
          </div>

          <div className="bg-blue-50 p-4 rounded-lg border border-blue-200">
            <h4 className="font-medium text-blue-800">Testing Notes</h4>
            <ul className="mt-2 text-sm text-blue-600 space-y-1">
              <li>
                • Current User Role:{' '}
                <span className="font-medium">
                  {userRole || 'Not logged in'}
                </span>
              </li>
              <li>• Test OTP code: "1234" for successful payments</li>
              <li>
                • All test registrations are simulated - no actual registrations
                will be made
              </li>
              <li>• Testing mode enabled - bypassing some validation rules</li>
            </ul>
          </div>
        </div>
      )}

      {/* Competition Registration Component */}
      <CompetitionRegistration competitionId={selectedCompetition} />
    </div>
  )
}

export default CompetitionTest
