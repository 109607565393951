import React from 'react'
import { Route } from 'react-router-dom'
import routeRegistry from './RouteRegistry'
import { RoleBasedRoute } from './RouteProtection'
import UnifiedDashboard from '../../components/UnifiedDashboard'
import SubscriptionManager from '../../components/PaymentSystem/SubscriptionManager'
import CompetitionRegistration from '../../components/Competitions/CompetitionRegistration'

// Import components
import { AdminDashboardHome, ApprovalsDashboard } from '../../pages/admin'
import { DashboardHome, Profile, Resources } from '../../pages/students'
import DashboardLibrary from '../../components/StudentDashboard/Library'
import Chats from '../../components/StudentDashboard/Chats'
import StudentAmbassadorHome from '../../components/StudentAmbassadorDashboard/Home'
import EventsDashboard from '../../components/StudentAmbassadorDashboard/Events'
import MentoringDashboard from '../../components/StudentAmbassadorDashboard/Mentoring'
import SchoolsManagement from '../../components/AdminDasboard/Schools'
import CourseManagement from '../../components/AdminDasboard/Courses'
import ResourceManagement from '../../components/AdminDasboard/Resources'
import ScheduleManagement from '../../components/AdminDasboard/Schedule'
import AcademySettings from '../../components/AdminDasboard/Academy'
import SystemSettings from '../../components/AdminDasboard/System'

// Import test pages
import PaymentTest from '../../pages/site/PaymentTest'
import CompetitionTest from '../../pages/site/CompetitionTest'

// Component mapping for routes
const componentMap = {
  // Student Dashboard Components
  'dashboard/home': DashboardHome,
  'dashboard/profile': Profile,
  'dashboard/resources': Resources,
  'dashboard/library': DashboardLibrary,
  'dashboard/chats': Chats,
  'dashboard/subscriptions': SubscriptionManager,
  'dashboard/competitions': () => (
    <CompetitionRegistration competitionId="comp-2023-math-olympiad" />
  ),

  // Student Ambassador Dashboard Components
  'ambassador/dashboard/home': StudentAmbassadorHome,
  'ambassador/dashboard/profile': Profile,
  'ambassador/dashboard/resources': Resources,
  'ambassador/dashboard/library': DashboardLibrary,
  'ambassador/dashboard/chats': Chats,
  'ambassador/dashboard/events': EventsDashboard,
  'ambassador/dashboard/events/create': () => (
    <div className="p-6 max-w-7xl mx-auto">
      <h1 className="text-2xl font-bold text-gray-900 mb-4">Create Event</h1>
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
        <p className="text-gray-500">Event creation form coming soon</p>
      </div>
    </div>
  ),
  'ambassador/dashboard/mentoring': MentoringDashboard,
  'ambassador/dashboard/analytics': () => (
    <div className="p-6 max-w-7xl mx-auto">
      <h1 className="text-2xl font-bold text-gray-900 mb-4">
        Analytics Dashboard
      </h1>
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
        <p className="text-gray-500">Analytics dashboard coming soon</p>
      </div>
    </div>
  ),
  'ambassador/dashboard/promotions': () => (
    <div className="p-6 max-w-7xl mx-auto">
      <h1 className="text-2xl font-bold text-gray-900 mb-4">
        Promotions Dashboard
      </h1>
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
        <p className="text-gray-500">Promotions dashboard coming soon</p>
      </div>
    </div>
  ),
  'ambassador/dashboard/students': () => (
    <div className="p-6 max-w-7xl mx-auto">
      <h1 className="text-2xl font-bold text-gray-900 mb-4">
        Student Group Management
      </h1>
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
        <p className="text-gray-500">Student group management coming soon</p>
      </div>
    </div>
  ),

  // Admin Dashboard Components
  'admin/dashboard': AdminDashboardHome,
  'admin/dashboard/approvals': ApprovalsDashboard,
  'admin/dashboard/profile': Profile,
  'admin/dashboard/schools': SchoolsManagement,
  'admin/dashboard/courses': CourseManagement,
  'admin/dashboard/resources': ResourceManagement,
  'admin/dashboard/schedule': ScheduleManagement,
  'admin/dashboard/academy': AcademySettings,
  'admin/dashboard/students': () => (
    <div className="p-6 max-w-7xl mx-auto">
      <h1 className="text-2xl font-bold text-gray-900 mb-4">
        Student Management
      </h1>
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
        <p className="text-gray-500">Student management coming soon</p>
      </div>
    </div>
  ),
  'admin/dashboard/instructors': () => (
    <div className="p-6 max-w-7xl mx-auto">
      <h1 className="text-2xl font-bold text-gray-900 mb-4">
        Ambassador Management
      </h1>
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
        <p className="text-gray-500">Ambassador management coming soon</p>
      </div>
    </div>
  ),
  'admin/dashboard/system': SystemSettings,

  // Testing pages
  'testing/payment': PaymentTest,
  'testing/competition': CompetitionTest,
}

/**
 * Generate dashboard route elements for each dashboard type
 * @returns {Array} Array of Route elements for all dashboards
 */
export const generateDashboardRoutes = () => {
  const routes = []

  // Student Dashboard Routes
  routes.push(
    <Route
      key="student-dashboard"
      path="/dashboard"
      element={
        <RoleBasedRoute routeKey="dashboard">
          <UnifiedDashboard dashboardType="student" />
        </RoleBasedRoute>
      }
    >
      {generateStudentRoutes()}
    </Route>
  )

  // Student Ambassador Dashboard Routes
  routes.push(
    <Route
      key="ambassador-dashboard"
      path="/ambassador/dashboard"
      element={
        <RoleBasedRoute routeKey="ambassador/dashboard">
          <UnifiedDashboard dashboardType="ambassador" />
        </RoleBasedRoute>
      }
    >
      {generateAmbassadorRoutes()}
    </Route>
  )

  // Admin Dashboard Routes
  routes.push(
    <Route
      key="admin-dashboard"
      path="/admin/dashboard"
      element={
        <RoleBasedRoute routeKey="admin/dashboard">
          <UnifiedDashboard dashboardType="admin" />
        </RoleBasedRoute>
      }
    >
      {generateAdminRoutes()}
    </Route>
  )

  return routes
}

/**
 * Generate routes for the student dashboard
 * @returns {Array} Array of Route elements
 */
const generateStudentRoutes = () => {
  const routes = []

  // Add index route
  routes.push(<Route key="student-index" index element={<DashboardHome />} />)

  // Generate routes from registry
  Object.entries(routeRegistry).forEach(([key, config]) => {
    if (key.startsWith('dashboard/') && key !== 'dashboard') {
      const path = key.replace('dashboard/', '')
      const Component =
        componentMap[key] || (() => <div>Not implemented yet</div>)

      routes.push(
        <Route
          key={key}
          path={path}
          element={
            <RoleBasedRoute routeKey={key}>
              <Component />
            </RoleBasedRoute>
          }
        />
      )
    }
  })

  return routes
}

/**
 * Generate routes for the student ambassador dashboard
 * @returns {Array} Array of Route elements
 */
const generateAmbassadorRoutes = () => {
  const routes = []

  // Add index route
  routes.push(
    <Route key="ambassador-index" index element={<StudentAmbassadorHome />} />
  )

  // Generate routes from registry
  Object.entries(routeRegistry).forEach(([key, config]) => {
    if (
      key.startsWith('ambassador/dashboard/') &&
      key !== 'ambassador/dashboard'
    ) {
      const path = key.replace('ambassador/dashboard/', '')
      const Component =
        componentMap[key] || (() => <div>Not implemented yet</div>)

      routes.push(
        <Route
          key={key}
          path={path}
          element={
            <RoleBasedRoute routeKey={key}>
              <Component />
            </RoleBasedRoute>
          }
        />
      )
    }
  })

  return routes
}

/**
 * Generate routes for the admin dashboard
 * @returns {Array} Array of Route elements
 */
const generateAdminRoutes = () => {
  const routes = []

  // Add index route
  routes.push(
    <Route key="admin-index" index element={<AdminDashboardHome />} />
  )

  // Generate routes from registry
  Object.entries(routeRegistry).forEach(([key, config]) => {
    if (key.startsWith('admin/dashboard/') && key !== 'admin/dashboard') {
      const path = key.replace('admin/dashboard/', '')
      const Component =
        componentMap[key] || (() => <div>Not implemented yet</div>)

      routes.push(
        <Route
          key={key}
          path={path}
          element={
            <RoleBasedRoute routeKey={key}>
              <Component />
            </RoleBasedRoute>
          }
        />
      )
    }
  })

  return routes
}
