// Importing necessary React components and assets
import React, { useMemo, useState } from 'react'
import image from '../../../assets/images/donation/Sponsor-bro.png'
import Banner from '../../../components/Banner'
import Footer from '../../../components/Footer'

// Component for rendering individual donation buttons
const DonationButton = ({ amount, isActive, onClick }) => (
  <button
    type="button"
    onClick={() => onClick(amount)}
    className={`py-3 px-4 rounded-lg ${
      isActive
        ? 'bg-[#00b964] text-white font-bold'
        : 'bg-gray-100 hover:bg-gray-200 text-gray-800'
    } transition-all duration-200`}
    aria-label={`Donate ${amount} dollars`}
    role="radio"
    aria-checked={isActive}
  >
    ${amount}
  </button>
)

// Component for rendering input fields with dynamic IDs, labels, and placeholders
const InputField = ({ id, label, type, placeholder, required = true }) => (
  <div
    className={`w-full ${id !== 'phone' ? 'md:w-1/2' : ''} px-3 mb-6 md:mb-0`}
  >
    <label
      className="block tracking-wide text-gray-700 text-sm font-semibold mb-2"
      htmlFor={id}
    >
      {label}{' '}
      {!required && (
        <span className="text-gray-500 font-normal">(Optional)</span>
      )}
    </label>
    <input
      className="appearance-none block w-full bg-white border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:ring-2 focus:ring-[#00b964] focus:border-transparent"
      id={id}
      type={type}
      placeholder={placeholder}
      aria-label={label}
      required={required}
    />
  </div>
)

// Main component for the Donations page
const Donations = () => {
  // States
  const [donationAmount, setDonationAmount] = useState('55')
  const [donationFrequency, setDonationFrequency] = useState('one-time')
  const donationOptions = useMemo(() => ['25', '55', '75', '150'], [])
  const [step, setStep] = useState(1)

  // Handlers
  const handleSetAmount = (amount) => {
    if (donationAmount !== amount) {
      setDonationAmount(amount)
    }
  }

  const handleFrequencyChange = (frequency) => {
    setDonationFrequency(frequency)
  }

  const handleNextStep = () => {
    setStep(2)
    // Scroll to form
    document
      .getElementById('donation-form')
      .scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <Banner
        title="Support Education in Cameroon"
        content="Your gift empowers students across both Anglophone and Francophone educational systems"
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="flex flex-col md:flex-row items-center py-12 gap-8">
          {/* Left side with content */}
          <div className="md:w-1/2">
            <h2 className="text-3xl font-bold text-gray-800 mb-4">
              Bridge the Educational Gap in Cameroon
            </h2>
            <p className="text-gray-600 text-xl mb-6">
              KET Academy addresses critical challenges in Cameroon's dual
              education system. We provide resources for both Anglophone (GCE)
              and Francophone (BEPC/BAC) students, particularly those with
              limited access to quality learning materials.
            </p>

            {/* Impact Card */}
            <div className="bg-white border border-gray-200 shadow-sm rounded-xl p-5 mb-8">
              <h3 className="text-lg font-bold text-gray-800 mb-3">
                Your donation directly supports:
              </h3>
              <ul className="space-y-3">
                {donationOptions.map((amount) => (
                  <li key={amount} className="flex items-start">
                    <div
                      className={`p-1.5 ${
                        donationAmount === amount
                          ? 'bg-[#00b964]'
                          : 'bg-gray-200'
                      } rounded-full mr-3 mt-0.5 flex-shrink-0 transition-colors duration-200`}
                    >
                      <svg
                        className="w-3 h-3 text-white"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <span
                      className={`text-sm ${
                        donationAmount === amount ? 'font-medium' : ''
                      }`}
                    >
                      {amount === '25' &&
                        "Educational resources tailored to Cameroon's Form 1-5 and 6ème-3ème students"}
                      {amount === '55' &&
                        "GCE O-Level and BEPC exam preparation materials for Cameroon's bilingual education system"}
                      {amount === '75' &&
                        'Mentorship for Upper Secondary (GCE A-Level & Baccalauréat) students facing critical examinations'}
                      {amount === '150' &&
                        'Comprehensive support spanning both Anglophone and Francophone systems for a full academic year'}
                    </span>
                  </li>
                ))}
              </ul>
            </div>

            {step === 1 && (
              <div className="space-y-6">
                {/* Donation Frequency Selector */}
                <div className="flex rounded-lg overflow-hidden border border-gray-300 w-full md:w-3/4">
                  <button
                    onClick={() => handleFrequencyChange('one-time')}
                    className={`flex-1 py-3 px-4 text-center font-medium transition-colors ${
                      donationFrequency === 'one-time'
                        ? 'bg-[#00b964] text-white'
                        : 'bg-white text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    One-time
                  </button>
                  <button
                    onClick={() => handleFrequencyChange('monthly')}
                    className={`flex-1 py-3 px-4 text-center font-medium transition-colors ${
                      donationFrequency === 'monthly'
                        ? 'bg-[#00b964] text-white'
                        : 'bg-white text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    Monthly
                  </button>
                </div>

                {/* Amount Selection */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Select Amount
                  </label>
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-3 mb-4">
                    {donationOptions.map((amount) => (
                      <DonationButton
                        key={amount}
                        amount={amount}
                        isActive={donationAmount === amount}
                        onClick={handleSetAmount}
                      />
                    ))}
                  </div>
                  <div className="relative">
                    <input
                      type="number"
                      placeholder="Custom amount"
                      className="w-full border border-gray-300 rounded-lg py-3 pl-8 pr-4 focus:outline-none focus:ring-2 focus:ring-[#00b964] focus:border-transparent"
                      onChange={(e) => handleSetAmount(e.target.value)}
                      aria-label="Enter custom donation amount"
                    />
                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                      $
                    </span>
                  </div>
                </div>

                <button
                  onClick={handleNextStep}
                  className="w-full py-4 px-6 bg-[#00b964] hover:bg-[#00a057] text-white font-bold rounded-lg transition duration-300 shadow-sm"
                >
                  Continue to Checkout
                </button>

                <div className="flex items-center justify-center space-x-2 text-sm text-gray-500">
                  <svg
                    className="w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span>Secure donation</span>
                </div>
              </div>
            )}
          </div>

          {/* Right side with image */}
          <div className="md:w-1/2 flex justify-center">
            <img
              src={image}
              alt="Supporting education through donation"
              className="max-w-full h-auto rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500"
            />
          </div>
        </div>

        {/* Our Story Section */}
        {step === 1 && (
          <div
            id="learn-more"
            className="bg-white border border-gray-200 p-8 rounded-lg shadow-sm mb-12 max-w-3xl w-full mx-auto"
          >
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Making Education Accessible in Cameroon
            </h2>
            <p className="text-gray-600 mb-6 leading-relaxed">
              Founded by a passionate student, KET Academy addresses unique
              challenges in Cameroon's education system. Many students lack
              access to quality learning resources, particularly for GCE
              O/A-Levels and Baccalauréat examinations. High student-to-teacher
              ratios and prohibitive costs for textbooks create barriers that
              we're committed to breaking down through accessible digital
              education.
            </p>
            <div className="flex flex-col md:flex-row justify-center space-y-6 md:space-y-0 md:space-x-8">
              <div className="text-center transform hover:scale-105 transition-all duration-300">
                <span className="block text-4xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent animate-pulse">
                  200+
                </span>
                <span className="text-gray-500 font-medium">
                  Students Supported
                </span>
              </div>
              <div className="text-center transform hover:scale-105 transition-all duration-300">
                <span className="block text-4xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent animate-pulse">
                  15+
                </span>
                <span className="text-gray-500 font-medium">
                  Expert Subject Mentors
                </span>
              </div>
              <div className="text-center transform hover:scale-105 transition-all duration-300">
                <span className="block text-4xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent animate-pulse">
                  10
                </span>
                <span className="text-gray-500 font-medium">
                  Regions Reached
                </span>
              </div>
            </div>
          </div>
        )}

        {/* Donation Form - Step 2 */}
        {step === 2 && (
          <div id="donation-form" className="w-full max-w-3xl mx-auto mb-12">
            <div className="bg-white border border-gray-200 p-8 rounded-lg shadow-sm mb-8">
              {/* Donation Summary */}
              <div className="mb-8 border-b border-gray-200 pb-6">
                <h3 className="text-xl font-semibold mb-4">Donation Summary</h3>
                <div className="flex justify-between items-center mb-2">
                  <span className="text-gray-600">Amount:</span>
                  <span className="font-bold text-lg">${donationAmount}</span>
                </div>
                <div className="flex justify-between items-center mb-4">
                  <span className="text-gray-600">Frequency:</span>
                  <span className="capitalize">{donationFrequency}</span>
                </div>
                <button
                  onClick={() => setStep(1)}
                  className="text-[#00b964] hover:text-[#00a057] font-medium flex items-center"
                >
                  <svg
                    className="w-4 h-4 mr-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 19l-7-7 7-7"
                    ></path>
                  </svg>
                  Edit donation
                </button>
              </div>

              {/* Donor Information Form */}
              <form>
                <h3 className="text-xl font-semibold mb-6">Your Information</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-6">
                  <InputField
                    id="first-name"
                    label="First Name"
                    type="text"
                    placeholder="Jane"
                  />
                  <InputField
                    id="last-name"
                    label="Last Name"
                    type="text"
                    placeholder="Doe"
                  />
                  <InputField
                    id="email"
                    label="Email"
                    type="email"
                    placeholder="you@example.com"
                  />
                  <InputField
                    id="phone"
                    label="Phone Number"
                    type="tel"
                    placeholder="(555) 555-5555"
                    required={false}
                  />
                </div>

                {/* Payment Methods */}
                <div className="mt-8">
                  <h3 className="text-lg font-semibold mb-4">
                    Select Payment Method
                  </h3>
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
                    <PaymentButton
                      icon="fab fa-paypal"
                      label="PayPal"
                      primary
                    />
                    <PaymentButton
                      icon="far fa-credit-card"
                      label="Credit Card"
                      primary
                    />
                    <PaymentButton icon="fab fa-apple-pay" label="Apple Pay" />
                    <PaymentButton
                      icon="fab fa-google-pay"
                      label="Google Pay"
                    />
                  </div>

                  <div className="mt-6 bg-gray-50 p-4 rounded-lg flex items-start">
                    <svg
                      className="w-5 h-5 text-[#00b964] mt-0.5 mr-2 flex-shrink-0"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                      ></path>
                    </svg>
                    <p className="text-sm text-gray-600">
                      Your payment information is secured with industry-standard
                      encryption. KET Academy partners with trusted payment
                      processors to ensure your transaction is safe and secure.
                    </p>
                  </div>

                  <div className="mt-4 text-sm text-gray-600">
                    <p>
                      Your donation is tax-deductible where applicable. You'll
                      receive a receipt via email.
                    </p>
                  </div>
                </div>

                <button
                  type="submit"
                  className="mt-8 w-full bg-[#00b964] hover:bg-[#00a057] text-white font-bold py-4 px-6 rounded-lg transition duration-300 shadow-sm"
                >
                  Complete Donation
                </button>

                <div className="mt-4 flex items-center justify-center space-x-2 text-sm text-gray-500">
                  <svg
                    className="w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span>Secure donation</span>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Alternative Donation Methods - Only show on step 2 */}
        {step === 2 && (
          <div className="w-full max-w-3xl mx-auto mb-12">
            <h3 className="text-xl font-semibold mb-4 text-center">
              Other Ways to Support
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* GoFundMe Section */}
              <div className="bg-white border border-gray-200 p-6 rounded-lg shadow-sm">
                <h4 className="text-lg font-semibold mb-3">
                  GoFundMe Campaign
                </h4>
                <p className="text-gray-600 mb-4">
                  Help us reach our goal of expanding our educational programs
                  across Cameroon.
                </p>
                <a
                  href="https://gofundme.com/ket-academy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-[#00b964] hover:bg-[#00a057] text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                >
                  Donate on GoFundMe
                </a>
              </div>

              {/* Corporate Donations */}
              <div className="bg-white border border-gray-200 p-6 rounded-lg shadow-sm">
                <h4 className="text-lg font-semibold mb-3">
                  Corporate Donations
                </h4>
                <p className="text-gray-600 mb-4">
                  Interested in making a corporate contribution or becoming a
                  sponsor?
                </p>
                <a
                  href="mailto:partnerships@ketacademy.org"
                  className="inline-block bg-gray-100 hover:bg-gray-200 text-gray-800 font-bold py-2 px-4 rounded-lg transition duration-300 border border-gray-300"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Final Call to Action - Only show on step 1 */}
      {step === 1 && (
        <div className="bg-gray-50 text-gray-800 py-16 my-12 border-t border-b border-gray-200">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-3xl font-bold mb-4">
              Help Build Cameroon's Educational Future
            </h2>
            <p className="text-xl mb-8 max-w-3xl mx-auto">
              Your support equips students across all 10 regions of Cameroon
              with the resources needed to excel in GCE, BEPC, and BAC
              examinations. Together, we're building a generation of
              well-educated youth ready to transform Cameroon's future.
            </p>
            <button
              onClick={handleNextStep}
              className="px-8 py-4 bg-[#00b964] hover:bg-[#00a057] text-white font-bold rounded-lg transition duration-300 shadow-lg"
            >
              Donate Now
            </button>
          </div>
        </div>
      )}

      <Footer />
    </>
  )
}

// PaymentButton component with improved styling
const PaymentButton = ({
  icon,
  label,
  primary,
  warning,
  isLoading,
  onClick,
}) => (
  <button
    type="button"
    onClick={onClick}
    disabled={isLoading}
    className={`
      w-full py-3 px-4 rounded-lg font-medium flex items-center justify-center space-x-2 transition duration-300
      ${primary ? 'bg-[#00b964] hover:bg-[#00a057] text-white' : ''}
      ${warning ? 'bg-yellow-500 hover:bg-yellow-600 text-white' : ''}
      ${
        !primary && !warning
          ? 'bg-gray-100 hover:bg-gray-200 text-gray-800 border border-gray-300'
          : ''
      }
      ${isLoading ? 'opacity-75 cursor-not-allowed' : ''}
    `}
  >
    {isLoading ? (
      <svg
        className="animate-spin h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    ) : (
      <>
        <i className={`${icon} text-lg`}></i>
        <span>{label}</span>
      </>
    )}
  </button>
)

export default Donations
