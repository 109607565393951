import React, { useState, useEffect, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faBook,
  faVideo,
  faFile,
  faFileAlt,
  faDownload,
  faExternalLinkAlt,
  faCalendarAlt,
  faUpload,
  faShare,
  faFilter,
  faTimes,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import {
  getAllResources,
  uploadResource,
} from '../../../services/resourceService'
import { toast } from 'react-toastify'
import { useRole } from '../../../utils/permissions/RoleContext'
import { PERMISSIONS } from '../../../utils/permissions/roles'

/**
 * Resources Component for Student Dashboard
 * Displays learning resources available to the student
 */
const Resources = () => {
  const [activeCategory, setActiveCategory] = useState('all')
  const [searchQuery, setSearchQuery] = useState('')
  const [resources, setResources] = useState([])
  const [allResources, setAllResources] = useState([])
  const [loading, setLoading] = useState(true)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [subjects] = useState([])
  const [selectedSubject, setSelectedSubject] = useState('')
  const [uploadFormData, setUploadFormData] = useState({
    title: '',
    description: '',
    subject: '',
    type: 'readings',
    courseId: '',
    file: null,
    youtubeUrl: '',
    uploadType: 'file',
    edSystem: '',
    secondaryType: '',
    academicLevel: '',
    examPreparation: '',
    language: '',
    specializationStream: '',
    difficulty: '',
    offlineAccess: false,
    tags: [],
  })
  const [advancedFilters, setAdvancedFilters] = useState({
    showFilters: false,
    dateFrom: '',
    dateTo: '',
    fileType: '',
    edSystem: '',
    secondaryType: '',
    academicLevel: '',
    examPreparation: '',
    language: '',
  })

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1)
  const [resourcesPerPage] = useState(10)
  const [totalResources, setTotalResources] = useState(0)
  const [, setLastDoc] = useState(null)
  const [loadedPages, setLoadedPages] = useState({})

  // Get user role and permissions
  const { hasPermission, userProfile } = useRole()
  const canUploadResources = hasPermission(PERMISSIONS.CREATE_RESOURCES)

  // Sample resource categories
  const categories = [
    { id: 'all', name: 'All Resources' },
    { id: 'readings', name: 'Readings' },
    { id: 'videos', name: 'Videos' },
    { id: 'docs', name: 'Documents' },
    { id: 'assignments', name: 'Assignments' },
  ]

  // Icon mapping based on resource type
  const iconTypeMap = {
    readings: faBook,
    videos: faVideo,
    docs: faFile,
    assignments: faFileAlt,
  }

  // Fetch resources from Firebase - memoized with useCallback
  const fetchResources = useCallback(async () => {
    try {
      setLoading(true)
      // Set up filter object for the query
      const filters = {}
      if (activeCategory !== 'all') {
        filters.type = activeCategory
      }
      if (selectedSubject) {
        filters.subject = selectedSubject
      }

      // Apply advanced filters if available
      if (advancedFilters.fileType) {
        filters.fileType = advancedFilters.fileType
      }

      // Apply Cameroonian educational system filters
      if (advancedFilters.edSystem) {
        filters.edSystem = advancedFilters.edSystem
      }
      if (advancedFilters.secondaryType) {
        filters.secondaryType = advancedFilters.secondaryType
      }
      if (advancedFilters.academicLevel) {
        filters.academicLevel = advancedFilters.academicLevel
      }
      if (advancedFilters.examPreparation) {
        filters.examPreparation = advancedFilters.examPreparation
      }

      // Get resources with pagination - use null for initial fetching
      const result = await getAllResources(filters, resourcesPerPage, null)

      // Set the resources and update lastDoc for pagination
      setAllResources(result.resources)
      setTotalResources(result.totalCount)

      // Only update lastDoc if we're not searching
      if (!searchQuery) {
        setLastDoc(result.lastDoc)
      }

      // Reset pagination when filters change
      setCurrentPage(1)
      setLoadedPages({
        1: {
          resources: result.resources,
          lastDoc: result.lastDoc,
        },
      })

      // Set the current resources to display
      setResources(result.resources)
    } catch (error) {
      console.error('Error fetching resources:', error)
      toast.error('Failed to load resources. Please try again later.')
    } finally {
      setLoading(false)
    }
  }, [
    activeCategory,
    selectedSubject,
    advancedFilters,
    resourcesPerPage,
    searchQuery, // Add searchQuery as dependency
  ]) // Remove lastDoc from dependencies

  useEffect(() => {
    fetchResources()
  }, [fetchResources])

  // Handle page change
  const loadPageData = useCallback(async () => {
    // Don't do anything if we're still loading
    if (loading) return

    // Skip if using search (we already have all results client-side)
    if (searchQuery) {
      const indexOfLastResource = currentPage * resourcesPerPage
      const indexOfFirstResource = indexOfLastResource - resourcesPerPage
      setResources(
        allResources.slice(indexOfFirstResource, indexOfLastResource)
      )
      return
    }

    // Check if we already have this page cached
    if (loadedPages[currentPage]) {
      setResources(loadedPages[currentPage].resources)
      return
    }

    // We need to fetch this page from the server
    try {
      setLoading(true)

      // Set up filter object for the query
      const filters = {}
      if (activeCategory !== 'all') {
        filters.type = activeCategory
      }
      if (selectedSubject) {
        filters.subject = selectedSubject
      }
      // Apply advanced filters
      if (advancedFilters.fileType) {
        filters.fileType = advancedFilters.fileType
      }
      // Apply Cameroonian educational system filters
      if (advancedFilters.edSystem) {
        filters.edSystem = advancedFilters.edSystem
      }
      if (advancedFilters.secondaryType) {
        filters.secondaryType = advancedFilters.secondaryType
      }
      if (advancedFilters.academicLevel) {
        filters.academicLevel = advancedFilters.academicLevel
      }
      if (advancedFilters.examPreparation) {
        filters.examPreparation = advancedFilters.examPreparation
      }
      if (advancedFilters.language) {
        filters.language = advancedFilters.language
      }

      // Get the last document from the previous page to use as cursor
      const previousPage = currentPage - 1
      const previousLastDoc = loadedPages[previousPage]?.lastDoc

      // Only fetch if we have a previous page doc or we're on page 1
      if (previousLastDoc || currentPage === 1) {
        // Fetch this page of resources
        const result = await getAllResources(
          filters,
          resourcesPerPage,
          previousPage > 0 ? previousLastDoc : null
        )

        // Guard against component unmounting
        if (result.resources) {
          // Update the resources and cache the page
          setResources(result.resources)

          // Only update loadedPages if we actually got results
          if (result.resources.length > 0) {
            setLoadedPages((prevLoadedPages) => ({
              ...prevLoadedPages,
              [currentPage]: {
                resources: result.resources,
                lastDoc: result.lastDoc,
              },
            }))
          }
        }
      }
    } catch (error) {
      console.error('Error fetching page data:', error)
      toast.error('Failed to load page data')
    } finally {
      setLoading(false)
    }
  }, [
    currentPage,
    searchQuery,
    allResources,
    loadedPages,
    resourcesPerPage,
    activeCategory,
    selectedSubject,
    advancedFilters,
    loading, // Add loading dependency to prevent duplicate calls
  ])

  useEffect(() => {
    loadPageData()
  }, [loadPageData])

  // Pagination controls
  const totalPages = Math.ceil(totalResources / resourcesPerPage)

  const paginate = useCallback(
    (pageNumber) => {
      if (pageNumber > 0 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber)
      }
    },
    [totalPages]
  )

  // Clear all filters
  const clearAllFilters = useCallback(() => {
    setActiveCategory('all')
    setSelectedSubject('')
    setSearchQuery('')
    setAdvancedFilters({
      showFilters: false,
      dateFrom: '',
      dateTo: '',
      fileType: '',
      edSystem: '',
      secondaryType: '',
      academicLevel: '',
      examPreparation: '',
      language: '',
    })
    setCurrentPage(1)
  }, [])

  const handleCategoryChange = (categoryId) => {
    setActiveCategory(categoryId)
  }

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
  }

  const handleSearchSubmit = (e) => {
    e.preventDefault()
    // Search is triggered by the useEffect when searchQuery changes
  }

  const handleSubjectChange = (e) => {
    setSelectedSubject(e.target.value)
  }

  const handleUploadChange = (e) => {
    const { name, value, type, files } = e.target

    if (type === 'file') {
      setUploadFormData({
        ...uploadFormData,
        file: files[0],
      })
    } else {
      setUploadFormData({
        ...uploadFormData,
        [name]: value,
      })
    }

    // Automatically set resource type to 'videos' when YouTube URL is selected
    if (name === 'uploadType' && value === 'youtube') {
      setUploadFormData({
        ...uploadFormData,
        [name]: value,
        type: 'videos',
      })
    }
  }

  const handleUploadSubmit = async (e) => {
    e.preventDefault()

    // Validate form based on upload type
    if (uploadFormData.uploadType === 'file') {
      if (!uploadFormData.title || !uploadFormData.file) {
        toast.error('Please provide a title and select a file')
        return
      }
    } else if (uploadFormData.uploadType === 'youtube') {
      if (!uploadFormData.title || !uploadFormData.youtubeUrl) {
        toast.error('Please provide a title and YouTube URL')
        return
      }

      // Basic validation for YouTube URL
      if (
        !uploadFormData.youtubeUrl.includes('youtube.com') &&
        !uploadFormData.youtubeUrl.includes('youtu.be')
      ) {
        toast.error('Please enter a valid YouTube URL')
        return
      }
    }

    try {
      setLoading(true)

      // Create metadata object from form data
      const metadata = {
        title: uploadFormData.title,
        description: uploadFormData.description,
        type: uploadFormData.type,
        subject: uploadFormData.subject,
        courseId: uploadFormData.courseId,
        date: new Date().toISOString().split('T')[0],
        // Add Cameroonian educational system fields
        edSystem: uploadFormData.edSystem,
        secondaryType: uploadFormData.secondaryType,
        academicLevel: uploadFormData.academicLevel,
        examPreparation: uploadFormData.examPreparation,
        language: uploadFormData.language,
        specializationStream: uploadFormData.specializationStream,
        difficulty: uploadFormData.difficulty,
        offlineAccess: uploadFormData.offlineAccess,
        tags: uploadFormData.tags,
      }

      // Upload the resource with user profile
      if (uploadFormData.uploadType === 'file') {
        await uploadResource(uploadFormData.file, metadata, userProfile)
      } else {
        await uploadResource(uploadFormData.youtubeUrl, metadata, userProfile)
      }

      toast.success('Resource uploaded successfully!')

      // Reset form and close modal
      setUploadFormData({
        title: '',
        description: '',
        subject: '',
        type: 'readings',
        courseId: '',
        file: null,
        youtubeUrl: '',
        uploadType: 'file',
        edSystem: '',
        secondaryType: '',
        academicLevel: '',
        examPreparation: '',
        language: '',
        specializationStream: '',
        difficulty: '',
        offlineAccess: false,
        tags: [],
      })
      setShowUploadModal(false)

      // Reset pagination and fetch resources
      setCurrentPage(1)
      setLastDoc(null)
      setLoadedPages({})
      fetchResources()
    } catch (error) {
      console.error('Error uploading resource:', error)
      toast.error(
        `Failed to upload resource: ${
          error.message || 'Please try again later'
        }`
      )
    } finally {
      setLoading(false)
    }
  }

  const toggleAdvancedFilters = () => {
    setAdvancedFilters({
      ...advancedFilters,
      showFilters: !advancedFilters.showFilters,
    })
  }

  const handleAdvancedFilterChange = (e) => {
    const { name, value } = e.target
    setAdvancedFilters({
      ...advancedFilters,
      [name]: value,
    })
  }

  const clearAdvancedFilters = () => {
    setAdvancedFilters({
      showFilters: false,
      dateFrom: '',
      dateTo: '',
      fileType: '',
      edSystem: '',
      secondaryType: '',
      academicLevel: '',
      examPreparation: '',
      language: '',
    })
  }

  const handleShareResource = (resourceId) => {
    // This would ideally open a sharing modal
    toast.info('Sharing feature coming soon!')
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h2 className="text-2xl font-bold text-gray-800">
          Educational Resources
        </h2>

        {/* Only show upload button if user has permission */}
        {canUploadResources && (
          <button
            onClick={() => setShowUploadModal(true)}
            className="bg-blue-700 text-white px-4 py-2 rounded-md hover:bg-blue-800 flex items-center"
          >
            <FontAwesomeIcon icon={faUpload} className="mr-2" />
            Upload Resource
          </button>
        )}
      </div>

      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h1 className="text-2xl font-bold text-blue-900 mb-2">
              Learning Resources
            </h1>
            <p className="text-gray-600">
              Access educational materials and resources for your courses.
            </p>
          </div>

          <button
            onClick={clearAllFilters}
            className="text-blue-600 hover:text-blue-800 px-3 py-1 border border-blue-300 rounded hover:bg-blue-50 flex items-center text-sm"
          >
            <FontAwesomeIcon icon={faTimes} className="mr-1" />
            Clear Filters
          </button>
        </div>

        {/* Resource Categories */}
        <div className="flex flex-wrap gap-4 mb-6">
          {categories.map((category) => (
            <button
              key={category.id}
              className={`px-5 py-3 border rounded-md font-medium transition-all duration-200 ${
                activeCategory === category.id
                  ? 'bg-blue-700 text-white border-blue-800'
                  : 'bg-gray-50 text-gray-700 border-gray-200 hover:bg-blue-100 hover:border-blue-300'
              }`}
              onClick={() => handleCategoryChange(category.id)}
            >
              {category.name}
            </button>
          ))}
          <button
            className="px-5 py-3 border rounded-md font-medium transition-all duration-200 flex items-center bg-gray-50 text-gray-700 border-gray-200 hover:bg-gray-100"
            onClick={toggleAdvancedFilters}
          >
            <FontAwesomeIcon icon={faFilter} className="mr-2" />
            {advancedFilters.showFilters ? 'Hide Filters' : 'Advanced Filters'}
          </button>
        </div>

        {/* Subject Filter */}
        <div className="mb-6">
          <select
            value={selectedSubject}
            onChange={handleSubjectChange}
            className="w-full md:w-1/3 p-3 border border-gray-300 rounded-md"
          >
            <option value="">All Subjects</option>
            {subjects.map((subject) => (
              <option key={subject} value={subject}>
                {subject}
              </option>
            ))}
          </select>
        </div>

        {/* Advanced Filters */}
        {advancedFilters.showFilters && (
          <div className="mb-6 p-4 border border-gray-200 rounded-lg bg-gray-50">
            <div className="flex justify-between items-center mb-4">
              <h3 className="font-semibold text-gray-700">Advanced Filters</h3>
              <button
                onClick={clearAdvancedFilters}
                className="text-sm text-blue-600 hover:text-blue-800"
              >
                Reset Filters
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  From Date
                </label>
                <input
                  type="date"
                  name="dateFrom"
                  value={advancedFilters.dateFrom}
                  onChange={handleAdvancedFilterChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  To Date
                </label>
                <input
                  type="date"
                  name="dateTo"
                  value={advancedFilters.dateTo}
                  onChange={handleAdvancedFilterChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  File Type
                </label>
                <select
                  name="fileType"
                  value={advancedFilters.fileType}
                  onChange={handleAdvancedFilterChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                >
                  <option value="">Any Format</option>
                  <option value="pdf">PDF</option>
                  <option value="doc">Word Document</option>
                  <option value="ppt">PowerPoint</option>
                  <option value="mp4">Video</option>
                </select>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Educational System
                </label>
                <select
                  name="edSystem"
                  value={advancedFilters.edSystem}
                  onChange={handleAdvancedFilterChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                >
                  <option value="">All Systems</option>
                  <option value="Anglophone">Anglophone (GCE)</option>
                  <option value="Francophone">Francophone (BEPC/BAC)</option>
                  <option value="Both">Both Systems</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Secondary Level
                </label>
                <select
                  name="secondaryType"
                  value={advancedFilters.secondaryType}
                  onChange={handleAdvancedFilterChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                >
                  <option value="">All Levels</option>
                  <option value="Lower Secondary">Lower Secondary</option>
                  <option value="Upper Secondary">Upper Secondary</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Academic Level
                </label>
                <select
                  name="academicLevel"
                  value={advancedFilters.academicLevel}
                  onChange={handleAdvancedFilterChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                >
                  <option value="">All Academic Levels</option>
                  {advancedFilters.edSystem === 'Anglophone' &&
                    advancedFilters.secondaryType === 'Lower Secondary' && (
                      <>
                        <option value="Form 1">Form 1</option>
                        <option value="Form 2">Form 2</option>
                        <option value="Form 3">Form 3</option>
                        <option value="Form 4">Form 4</option>
                        <option value="Form 5">Form 5</option>
                      </>
                    )}
                  {advancedFilters.edSystem === 'Anglophone' &&
                    advancedFilters.secondaryType === 'Upper Secondary' && (
                      <>
                        <option value="Lower Sixth">Lower Sixth</option>
                        <option value="Upper Sixth">Upper Sixth</option>
                      </>
                    )}
                  {advancedFilters.edSystem === 'Francophone' &&
                    advancedFilters.secondaryType === 'Lower Secondary' && (
                      <>
                        <option value="6ème">6ème</option>
                        <option value="5ème">5ème</option>
                        <option value="4ème">4ème</option>
                        <option value="3ème">3ème</option>
                      </>
                    )}
                  {advancedFilters.edSystem === 'Francophone' &&
                    advancedFilters.secondaryType === 'Upper Secondary' && (
                      <>
                        <option value="Seconde">Seconde</option>
                        <option value="Première">Première</option>
                        <option value="Terminale">Terminale</option>
                      </>
                    )}
                </select>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Exam Preparation
                </label>
                <select
                  name="examPreparation"
                  value={advancedFilters.examPreparation}
                  onChange={handleAdvancedFilterChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                >
                  <option value="">All Exam Types</option>
                  {(advancedFilters.edSystem === 'Anglophone' ||
                    advancedFilters.edSystem === '') && (
                    <>
                      <option value="GCE O-Level">GCE O-Level</option>
                      <option value="GCE A-Level">GCE A-Level</option>
                    </>
                  )}
                  {(advancedFilters.edSystem === 'Francophone' ||
                    advancedFilters.edSystem === '') && (
                    <>
                      <option value="BEPC">BEPC</option>
                      <option value="Probatoire">Probatoire</option>
                      <option value="Baccalauréat">Baccalauréat</option>
                    </>
                  )}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Language
                </label>
                <select
                  name="language"
                  value={advancedFilters.language}
                  onChange={handleAdvancedFilterChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                >
                  <option value="">All Languages</option>
                  <option value="English">English</option>
                  <option value="French">French</option>
                  <option value="Bilingual">Bilingual</option>
                </select>
              </div>
            </div>
          </div>
        )}

        {/* Search Bar */}
        <div className="mb-6">
          <form onSubmit={handleSearchSubmit}>
            <div className="flex border border-gray-300 rounded-md overflow-hidden">
              <input
                type="text"
                placeholder="Search resources..."
                value={searchQuery}
                onChange={handleSearchChange}
                className="flex-1 p-3 border-none outline-none"
              />
              <button
                type="submit"
                className="bg-yellow-500 text-white px-5 py-3 hover:bg-yellow-600 transition-colors duration-200"
              >
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
          </form>
        </div>

        {/* Loading State */}
        {loading && (
          <div className="text-center py-12">
            <div className="inline-block animate-spin rounded-full h-8 w-8 border-b-2 border-blue-700"></div>
            <p className="mt-4 text-gray-600">Loading resources...</p>
          </div>
        )}

        {/* Resources List */}
        {!loading && resources.length > 0 ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {resources.map((resource) => (
                <div
                  key={resource.id}
                  className="border border-gray-200 rounded-lg overflow-hidden transition-all duration-200 hover:-translate-y-1 hover:shadow-md"
                >
                  <div className="h-40 bg-gray-50 flex items-center justify-center text-5xl text-gray-300">
                    <FontAwesomeIcon
                      icon={iconTypeMap[resource.type] || faFile}
                    />
                  </div>
                  <div className="p-4">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2">
                      {resource.title}
                    </h3>
                    <p className="text-sm text-gray-600 mb-4">
                      {resource.description}
                    </p>
                    <div className="flex justify-between text-xs text-gray-500 mb-2">
                      <span>Course: {resource.courseId || 'N/A'}</span>
                      <span className="flex items-center">
                        <FontAwesomeIcon
                          icon={faCalendarAlt}
                          className="mr-1"
                        />{' '}
                        {resource.createdAt
                          ? new Date(
                              resource.createdAt.seconds * 1000
                            ).toLocaleDateString()
                          : resource.date || 'N/A'}
                      </span>
                    </div>
                    <div className="flex flex-wrap gap-1 mb-4">
                      {resource.subject && (
                        <span className="inline-block bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded-full">
                          {resource.subject}
                        </span>
                      )}
                      {resource.edSystem && (
                        <span className="inline-block bg-green-100 text-green-800 text-xs px-2 py-1 rounded-full">
                          {resource.edSystem}
                        </span>
                      )}
                      {resource.academicLevel && (
                        <span className="inline-block bg-purple-100 text-purple-800 text-xs px-2 py-1 rounded-full">
                          {resource.academicLevel}
                        </span>
                      )}
                      {resource.examPreparation && (
                        <span className="inline-block bg-yellow-100 text-yellow-800 text-xs px-2 py-1 rounded-full">
                          {resource.examPreparation}
                        </span>
                      )}
                    </div>
                    <div className="flex justify-between">
                      <Link
                        to={`/resource/${resource.id}`}
                        className="bg-blue-700 hover:bg-blue-800 text-white text-sm px-4 py-2 rounded-md transition-colors duration-200 flex items-center"
                      >
                        <FontAwesomeIcon
                          icon={faExternalLinkAlt}
                          className="mr-2"
                        />{' '}
                        View
                      </Link>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleShareResource(resource.id)}
                          className="bg-gray-100 hover:bg-gray-200 text-gray-700 p-2 rounded-md transition-colors duration-200"
                          title="Share"
                        >
                          <FontAwesomeIcon icon={faShare} />
                        </button>
                        <a
                          href={resource.fileUrl}
                          download
                          className="bg-gray-100 hover:bg-gray-200 text-gray-700 p-2 rounded-md transition-colors duration-200"
                          title="Download"
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Pagination controls */}
            {totalPages > 1 && (
              <div className="flex justify-center items-center my-8">
                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`mx-1 px-3 py-2 rounded-md ${
                    currentPage === 1
                      ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                      : 'bg-blue-100 text-blue-700 hover:bg-blue-200'
                  }`}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>

                <div className="mx-4 text-gray-700">
                  Page {currentPage} of {totalPages}
                </div>

                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className={`mx-1 px-3 py-2 rounded-md ${
                    currentPage === totalPages
                      ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                      : 'bg-blue-100 text-blue-700 hover:bg-blue-200'
                  }`}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </div>
            )}

            <div className="text-center text-gray-600 text-sm">
              Showing {resources.length} of {allResources.length} resources
            </div>
          </>
        ) : !loading ? (
          <div className="text-center bg-gray-50 rounded-lg py-12 px-4 text-gray-500">
            <p className="mb-6">No resources found matching your criteria.</p>
          </div>
        ) : null}
      </div>

      {/* Upload Modal */}
      {canUploadResources && showUploadModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-bold text-gray-800">
                  Upload New Resource
                </h2>
                <button
                  onClick={() => setShowUploadModal(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>

              <form onSubmit={handleUploadSubmit}>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Title *
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={uploadFormData.title}
                    onChange={handleUploadChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                    required
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Description
                  </label>
                  <textarea
                    name="description"
                    value={uploadFormData.description}
                    onChange={handleUploadChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                    rows={3}
                  />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Subject
                    </label>
                    <input
                      type="text"
                      name="subject"
                      value={uploadFormData.subject}
                      onChange={handleUploadChange}
                      className="w-full p-2 border border-gray-300 rounded-md"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Resource Type
                    </label>
                    <select
                      name="type"
                      value={uploadFormData.type}
                      onChange={handleUploadChange}
                      className="w-full p-2 border border-gray-300 rounded-md"
                    >
                      {categories
                        .filter((category) => category.id !== 'all')
                        .map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Educational System
                    </label>
                    <select
                      name="edSystem"
                      value={uploadFormData.edSystem || ''}
                      onChange={handleUploadChange}
                      className="w-full p-2 border border-gray-300 rounded-md"
                    >
                      <option value="">Select Educational System</option>
                      <option value="Anglophone">Anglophone (GCE)</option>
                      <option value="Francophone">
                        Francophone (BEPC/BAC)
                      </option>
                      <option value="Both">Both Systems</option>
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Secondary Level
                    </label>
                    <select
                      name="secondaryType"
                      value={uploadFormData.secondaryType || ''}
                      onChange={handleUploadChange}
                      className="w-full p-2 border border-gray-300 rounded-md"
                    >
                      <option value="">Select Level</option>
                      <option value="Lower Secondary">Lower Secondary</option>
                      <option value="Upper Secondary">Upper Secondary</option>
                      <option value="Both">Both Levels</option>
                    </select>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Academic Level
                    </label>
                    <select
                      name="academicLevel"
                      value={uploadFormData.academicLevel || ''}
                      onChange={handleUploadChange}
                      className="w-full p-2 border border-gray-300 rounded-md"
                    >
                      <option value="">Select Academic Level</option>
                      {uploadFormData.edSystem === 'Anglophone' &&
                        uploadFormData.secondaryType === 'Lower Secondary' && (
                          <>
                            <option value="Form 1">Form 1</option>
                            <option value="Form 2">Form 2</option>
                            <option value="Form 3">Form 3</option>
                            <option value="Form 4">Form 4</option>
                            <option value="Form 5">Form 5</option>
                          </>
                        )}
                      {uploadFormData.edSystem === 'Anglophone' &&
                        uploadFormData.secondaryType === 'Upper Secondary' && (
                          <>
                            <option value="Lower Sixth">Lower Sixth</option>
                            <option value="Upper Sixth">Upper Sixth</option>
                          </>
                        )}
                      {uploadFormData.edSystem === 'Francophone' &&
                        uploadFormData.secondaryType === 'Lower Secondary' && (
                          <>
                            <option value="6ème">6ème</option>
                            <option value="5ème">5ème</option>
                            <option value="4ème">4ème</option>
                            <option value="3ème">3ème</option>
                          </>
                        )}
                      {uploadFormData.edSystem === 'Francophone' &&
                        uploadFormData.secondaryType === 'Upper Secondary' && (
                          <>
                            <option value="Seconde">Seconde</option>
                            <option value="Première">Première</option>
                            <option value="Terminale">Terminale</option>
                          </>
                        )}
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Exam Preparation
                    </label>
                    <select
                      name="examPreparation"
                      value={uploadFormData.examPreparation || ''}
                      onChange={handleUploadChange}
                      className="w-full p-2 border border-gray-300 rounded-md"
                    >
                      <option value="">Select Exam Type</option>
                      {uploadFormData.edSystem === 'Anglophone' && (
                        <>
                          <option value="GCE O-Level">GCE O-Level</option>
                          <option value="GCE A-Level">GCE A-Level</option>
                        </>
                      )}
                      {uploadFormData.edSystem === 'Francophone' && (
                        <>
                          <option value="BEPC">BEPC</option>
                          <option value="Probatoire">Probatoire</option>
                          <option value="Baccalauréat">Baccalauréat</option>
                        </>
                      )}
                    </select>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Language
                    </label>
                    <select
                      name="language"
                      value={uploadFormData.language || ''}
                      onChange={handleUploadChange}
                      className="w-full p-2 border border-gray-300 rounded-md"
                    >
                      <option value="">Select Language</option>
                      <option value="English">English</option>
                      <option value="French">French</option>
                      <option value="Bilingual">Bilingual</option>
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Course ID
                    </label>
                    <input
                      type="text"
                      name="courseId"
                      value={uploadFormData.courseId}
                      onChange={handleUploadChange}
                      className="w-full p-2 border border-gray-300 rounded-md"
                      placeholder="e.g. CS101"
                    />
                  </div>
                </div>

                {/* Upload Type Selection */}
                <div className="mb-4">
                  <label className="block text-gray-700 font-medium mb-2">
                    Upload Type
                  </label>
                  <div className="flex items-center space-x-4">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name="uploadType"
                        value="file"
                        checked={uploadFormData.uploadType === 'file'}
                        onChange={handleUploadChange}
                        className="form-radio h-5 w-5 text-blue-600"
                      />
                      <span className="ml-2 text-gray-700">File Upload</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name="uploadType"
                        value="youtube"
                        checked={uploadFormData.uploadType === 'youtube'}
                        onChange={handleUploadChange}
                        className="form-radio h-5 w-5 text-blue-600"
                      />
                      <span className="ml-2 text-gray-700">YouTube Video</span>
                    </label>
                  </div>
                </div>

                {/* Conditional rendering based on upload type */}
                {uploadFormData.uploadType === 'file' ? (
                  <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-2">
                      File Upload
                    </label>
                    <input
                      type="file"
                      onChange={handleUploadChange}
                      className="border p-2 w-full rounded"
                    />
                  </div>
                ) : (
                  <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-2">
                      YouTube URL
                    </label>
                    <input
                      type="url"
                      name="youtubeUrl"
                      value={uploadFormData.youtubeUrl}
                      onChange={handleUploadChange}
                      placeholder="https://www.youtube.com/watch?v=..."
                      className="border p-2 w-full rounded"
                    />
                    <p className="text-sm text-gray-500 mt-1">
                      Paste a valid YouTube video URL
                    </p>
                  </div>
                )}

                <div className="flex justify-end gap-3">
                  <button
                    type="button"
                    onClick={() => setShowUploadModal(false)}
                    className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-700 text-white rounded-md hover:bg-blue-800 flex items-center"
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <span className="inline-block animate-spin h-4 w-4 border-b-2 border-white mr-2"></span>
                        Uploading...
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faUpload} className="mr-2" />
                        Upload Resource
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Resources
