import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import navigationRegistry from '../../../utils/permissions/NavigationRegistry'
import { useRole } from '../../../utils/permissions/RoleContext'
import { CORE_ROLES } from '../../../utils/permissions/roles'

/**
 * Component to display and test navigation registry items for all dashboards
 */
const NavRegistryTest = () => {
  const { userRole, userProfile } = useRole()
  const [selectedDashboard, setSelectedDashboard] = useState('student')

  // Navigation sections by dashboard
  const dashboards = {
    student: {
      title: 'Student Dashboard',
      items: navigationRegistry.studentDashboardItems,
      key: 'student',
    },
    ambassador: {
      title: 'Student Ambassador Dashboard',
      items: navigationRegistry.ambassadorDashboardItems,
      key: 'ambassador',
    },
    admin: {
      title: 'Admin Dashboard',
      items: navigationRegistry.adminDashboardItems,
      key: 'admin',
    },
  }

  // Check if user has access to a nav item
  const hasNavAccess = (item) => {
    return item.roles.includes(userRole)
  }

  // Get role badge color
  const getRoleBadgeColor = (role) => {
    switch (role) {
      case CORE_ROLES.STUDENT:
        return 'bg-blue-100 text-blue-800'
      case CORE_ROLES.STUDENT_AMBASSADOR:
        return 'bg-green-100 text-green-800'
      case CORE_ROLES.AMBASSADOR:
        return 'bg-purple-100 text-purple-800'
      case CORE_ROLES.CORE_MEMBER:
        return 'bg-red-100 text-red-800'
      default:
        return 'bg-gray-100 text-gray-800'
    }
  }

  const selectedNavItems = dashboards[selectedDashboard].items

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8">
        Navigation Registry Test Page
      </h1>

      {/* Current User Info */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4">Current User Information</h2>
        <p>
          <strong>Role:</strong> {userRole || 'Not set'}
        </p>
        <p>
          <strong>Status:</strong> {userProfile?.status || 'Not set'}
        </p>
      </div>

      {/* Dashboard Selector */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4">Select Dashboard</h2>
        <div className="flex flex-wrap gap-4">
          {Object.values(dashboards).map((dashboard) => (
            <button
              key={dashboard.key}
              onClick={() => setSelectedDashboard(dashboard.key)}
              className={`px-4 py-2 rounded-md ${
                selectedDashboard === dashboard.key
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
              }`}
            >
              {dashboard.title}
            </button>
          ))}
        </div>
      </div>

      {/* Navigation Visualization */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4">
          {dashboards[selectedDashboard].title} Navigation
        </h2>

        {/* Live Navigation Preview */}
        <div className="mb-8">
          <h3 className="text-lg font-medium mb-4">Live Navigation Preview</h3>
          <div className="bg-gray-100 p-4 rounded-lg">
            <div className="flex flex-col space-y-2">
              {selectedNavItems
                .filter((item) => hasNavAccess(item))
                .map((item) => (
                  <Link
                    key={item.id}
                    to={item.path}
                    className="flex items-center p-2 hover:bg-gray-200 rounded transition-colors"
                  >
                    <div className="w-6 h-6 mr-3 flex items-center justify-center">
                      {item.icon ? (
                        <span>{item.icon}</span>
                      ) : (
                        <span className="text-gray-400">◯</span>
                      )}
                    </div>
                    <span>{item.label}</span>
                  </Link>
                ))}
            </div>
          </div>
        </div>

        {/* Navigation Items Table */}
        <h3 className="text-lg font-medium mb-4">Navigation Item Details</h3>
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  ID
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Label
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Path
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Required Roles
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Access
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Order
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {selectedNavItems.map((item) => (
                <tr
                  key={item.id}
                  className={hasNavAccess(item) ? 'bg-green-50' : 'bg-red-50'}
                >
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {item.id}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                    {item.label}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {item.path}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    <div className="flex flex-wrap gap-1">
                      {item.roles.map((role) => (
                        <span
                          key={role}
                          className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${getRoleBadgeColor(
                            role
                          )}`}
                        >
                          {role}
                        </span>
                      ))}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm">
                    {hasNavAccess(item) ? (
                      <Link
                        to={item.path}
                        className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800 hover:bg-green-200"
                      >
                        Accessible (Click to Visit)
                      </Link>
                    ) : (
                      <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                        No Access
                      </span>
                    )}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {item.order}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Navigation Links */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-xl font-semibold mb-4">Other Testing Pages</h2>
        <div className="flex flex-wrap gap-4">
          <Link
            to="/rbac-test"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
          >
            RBAC Test Page
          </Link>
          <Link
            to="/feature-flags"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
          >
            Feature Flags Test
          </Link>
          <Link
            to="/route-registry"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
          >
            Route Registry Test
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NavRegistryTest
