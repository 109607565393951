/**
 * Extract YouTube video ID from various YouTube URL formats
 * @param {string} url - YouTube URL
 * @returns {string|null} - YouTube video ID or null if not valid
 */
export const extractYoutubeId = (url) => {
  if (!url) return null

  // Regular expression to match YouTube video IDs from various URL formats
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)

  return match && match[2].length === 11 ? match[2] : null
}

/**
 * Generate a YouTube embed URL from a video ID
 * @param {string} videoId - YouTube video ID
 * @returns {string} - YouTube embed URL
 */
export const getYoutubeEmbedUrl = (videoId) => {
  return `https://www.youtube.com/embed/${videoId}`
}
